import { render, staticRenderFns } from "./simple_table.vue?vue&type=template&id=78cfd624&scoped=true&"
import script from "./simple_table.vue?vue&type=script&lang=js&"
export * from "./simple_table.vue?vue&type=script&lang=js&"
import style0 from "./simple_table.css?vue&type=style&index=0&id=78cfd624&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78cfd624",
  null
  
)

export default component.exports