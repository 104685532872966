import Box from 'elements/box/box.vue';
import Table from 'elements/table/table.vue';
import Modal from 'elements/modal/modal.vue';
import VueDropzone from 'vue2-dropzone';
import Moment from 'moment';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { Document } from 'libs/api';
import { resolveDocumentTypeValueToLabel, showNotification } from 'libs/utils/helpers';
import { DOCUMENT_TYPES } from 'libs/utils/constants';

export default {
  props: {
    productId: {
      type: String,
      required: true
    },
    productClass: {
      type: String,
      default: 'flatbond'
    },
    csrf: {
      type: String,
      required: true
    }
  },
  mounted: function() {
    this._fetchDocuments();
  },
  data: function() {
    return {
      loading: true,
      showAreYouSure: false,
      documentApi: new Document(this, this.productClass),
      documents: [],
      dropzoneOptions: this._generateDropzoneOptions(),
      onCompletedUpload: this._dropzoneComplete.bind(this),
      documentType: '',
      documentTypes: DOCUMENT_TYPES,
      documentIdToDelete: null,
      documentNameToDelete: ''
    };
  },
  computed: {
    documentsDataForTable: function() {
      return {
        emptyMessage: 'No documents uploaded',
        columns: {
          type: {
            name: 'Type'
          },
          file: {
            name: 'File',
            isInternalGenericDocumentDownload: true
          },
          createdOn: {
            name: 'Uploaded on'
          },
          delete: {
            name: 'Action',
            isAction: true
          }
        },
        rows: this.documents.map(document => {
          return {
            type: {
              value: resolveDocumentTypeValueToLabel(document.type)
            },
            file: {
              document
            },
            createdOn: {
              value: Moment(document.created_on).format('dddd, MMMM Do YYYY, h:mm:ss a')
            },
            delete: {
              value: 'Delete from the system',
              colourClass: 'btn-danger',
              action: this._showAreYouSure.bind(null, document.id, document.file_name)
            }
          };
        })
      };
    }
  },
  methods: {
    hideAreYouSure: function() {
      this.showAreYouSure = false;
      this.documentIdToDelete = null;
      this.documentNameToDelete = '';
    },
    deleteDocumentFromTheSystem() {
      this.loading = true;

      return this.documentApi
        .delete(this.productId, this.documentIdToDelete)
        .then(() => {
          showNotification(this, 'Document deleted successfully', 'success');

          return this._fetchDocuments();
        })
        .finally(() => {
          this.loading = false;
          this.hideAreYouSure();
        });
    },
    _fetchDocuments: function() {
      return this.documentApi
        .list(this.productId)
        .then(response => {
          this.documents = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _generateDropzoneOptions: function() {
      return {
        url: `/api/private/v1/document/${this.productClass}/`,
        maxFiles: 1,
        headers: { 'X-CSRFToken': this.csrf },
        params: () => {
          const params = {
            type: this.documentType
          };

          params[this.productClass] = this.productId;
          return params;
        }
      };
    },
    _dropzoneComplete: function(file) {
      this.$refs['document-upload'].removeFile(file);

      if (file.status === 'error') {
        showNotification(this, 'Document can not be uploaded', 'error');
        return;
      }

      this.documentType = '';
      showNotification(this, 'Document uploaded successfully', 'success');

      return this._fetchDocuments();
    },
    _showAreYouSure(documentId, documentName) {
      this.showAreYouSure = true;
      this.documentIdToDelete = documentId;
      this.documentNameToDelete = documentName;
    }
  },
  components: {
    'box': Box,
    'v-table': Table,
    'v-dropzone': VueDropzone,
    'modal': Modal
  }
};
