import throttle from 'lodash/throttle';

export default {
  props: {
    defaultText: {
      type: String,
      required: true
    },
    options: {
      required: true
    },
    canChooseDefault: {
      type: Boolean,
      default: true
    },
    disabledOptions: {},
    value: {
      type: [String, Number, Object, Date],
      default: null
    }
  },
  created() {
    window.addEventListener('click', this.closeOnOutsideClick);
  },
  mounted() {
    if (this.value) {
      this.syncOptionWithValue(this.value);
    } else {
      this.optionSelected(this.defaultOption);
    }
    this.$nextTick(() =>
      this.$refs.dropdownList.addEventListener('scroll', this.throttledDetectEndOfOptions)
    );
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeOnOutsideClick);
    this.$refs.dropdownList.removeEventListener('scroll', this.throttledDetectEndOfOptions);
  },
  computed: {
    dropdownIsOpen() {
      return this.selectedOption !== this.defaultOption || this.optionsShown === true;
    }
  },
  data() {
    return {
      defaultOption: { label: 'Any', value: '' },
      selectedOption: {},
      optionsShown: false,
      lastScrollPosition: 0
    };
  },
  watch: {
    value(val) {
      this.syncOptionWithValue(val);
    }
  },
  methods: {
    showOptions() {
      this.optionsShown = !this.optionsShown;
    },
    closeOnOutsideClick(event) {
      const dropdownElement = this.$refs.dropdown;
      const target = event.target;
      if (dropdownElement !== target && !dropdownElement.contains(target)) {
        this.optionsShown = false;
      }
    },
    optionSelected(option) {
      this.selectedOption = option;
      this.$emit('input', this.selectedOption.value);
      this.optionsShown = false;
    },
    syncOptionWithValue(val) {
      const selectedOption =
        this.options.find(option => option.value === val) || this.defaultOption;
      this.optionSelected(selectedOption);
    },
    throttledDetectEndOfOptions: throttle(function() {
      this.detectEndOfListOptions();
    }, 500),
    detectEndOfListOptions() {
      let scrollPosition = this.$refs.dropdownList.scrollTop + this.$refs.dropdownList.offsetHeight;
      let heightOfLastItem = this.$refs.dropdownList.lastChild.offsetHeight;

      if (
        scrollPosition > this.$refs.dropdownList.scrollHeight - heightOfLastItem &&
        scrollPosition >= this.lastScrollPosition
      ) {
        this.$emit('end-of-options');
      }

      this.lastScrollPosition = scrollPosition;
    }
  }
};
