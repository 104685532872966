export default {
  props: {
    options: {
      type: Array,
      default: null,
      required: true
    },
    selectedOptionIndex: {
      type: Number,
      default: -1
    }
  },
  data: function() {
    return {
      selectedOption: this.selectedOptionIndex
    };
  },
  watch: {
    selectedOptionIndex: function(selectedOptionIndexFromProps) {
      this.selectedOption = selectedOptionIndexFromProps;
    }
  },
  methods: {
    buttonSelected: function(index) {
      this.selectedOption = index;
      this.$emit('input', this.options[this.selectedOption].value);
    }
  }
};
