import { FlatbondApiBase } from './base';

export class FlatbondDraftApi extends FlatbondApiBase {
  create(draft) {
    return super.post('draft/', draft, 'Unable to create a draft flatbond.');
  }

  update(draft) {
    return super.put(`draft/${draft.id}/`, draft, 'Unable to update a draft flatbond.');
  }

  list() {
    return super.get(`draft/`, 'Unable to list any draft flatbonds');
  }

  retrieve(draftId) {
    return super.get(`draft/${draftId}/`, `Could not retrieve draft ${draftId}`);
  }

  async getDocumentsToSign(draftId) {
    return this.get(`draft/${draftId}/documents-to-sign/`);
  }

  async convertToFlatbond(draftId) {
    return this.post(`draft/${draftId}/convert-to-flatbond/`);
  }
}
