import FlatfairTable from 'elements/flatfair_table/flatfair_table.vue';
import { TableDefinition } from 'libs/tableDefinition';
import {
  formatCurrencyFromCents,
  calculateAffordability,
  resolveSuggestedDecisionToObject
} from 'libs/utils/helpers';
import { FlatfairConstant } from 'libs/flatfairConstant';
import { REFERENCE_REQUEST_STATUS } from 'libs/utils/constants';

export default {
  props: {
    applicants: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.populateTenantReferences(this.applicants);
    this.preselectFirstApplicant();
  },
  data() {
    return {
      applicantData: {},
      referenceStatusConstant: new FlatfairConstant(REFERENCE_REQUEST_STATUS)
    };
  },
  computed: {
    firstApplicant() {
      return this.applicants[0];
    }
  },
  methods: {
    referenceSelected(applicant) {
      this.$emit('referenceSelected', applicant);
    },
    populateTenantReferences(applicants) {
      const table = new TableDefinition()
        .addSimpleRow('Email', applicant => applicant.user_email)
        .addSimpleRow('Homelet ID', applicant =>
          applicant.reference.third_party_reference_id
            ? `# ${applicant.reference.third_party_reference_id}`
            : '# -'
        )
        .addLabel(
          'Status',
          applicant => {
            return this.referenceStatusConstant.getLabel(applicant.reference.status);
          },
          applicant => {
            return {
              colourClass: this.referenceStatusConstant.getColour(applicant.reference.status)
            };
          }
        )
        .addLabel(
          'Suggestion',
          applicant => {
            return resolveSuggestedDecisionToObject(applicant.reference).label;
          },
          applicant => {
            return {
              colourClass: resolveSuggestedDecisionToObject(applicant.reference).colourClass
            };
          }
        )
        .addSimpleRow('Affordability', applicant => {
          if (applicant.reference.response) {
            return formatCurrencyFromCents(
              calculateAffordability(
                applicant.reference.response.salary,
                applicant.reference.affordability_multiplier
              )
            );
          }
          return '£ -';
        })
        .addAction(
          '',
          () => {
            return 'Details';
          },
          applicant => {
            return {
              action: () => this.referenceSelected(applicant)
            };
          }
        );

      this.applicantData = table.build(applicants);
    },
    preselectFirstApplicant() {
      if (this.applicants.length === 1) {
        this.referenceSelected(this.firstApplicant);
      }
    }
  },
  components: {
    'ff-table': FlatfairTable
  }
};
