import { FlatbondApiBase } from './base';

export class LandlordFlatbondApi extends FlatbondApiBase {
  list(queryParams = '') {
    return super.get(`landlord/${queryParams}`);
  }

  get(flatbondId) {
    return super.get(`landlord/${flatbondId}`);
  }
}
