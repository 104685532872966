<template>
  <fp-label>
    <fp-label-text>{{ label }}</fp-label-text>
    <fp-input-group>
      <fp-input
        :value="value"
        @input="onInput"
        :disabled="disabled"
        :error="error"
        type="date"
        data-testid="fairplay-input"
      />
      <fp-input-prepend>
        <fp-icon :code="prependIcon" :icon-style="IconStyle.solid" />
      </fp-input-prepend>
      <fp-floating-control v-if="!!value" @click.prevent="onClear">
        <fp-icon :code="controlIcon" :icon-style="IconStyle.solid" />
      </fp-floating-control>
    </fp-input-group>
    <slot />
  </fp-label>
</template>

<script>
import {
  Icon as FpIcon,
  IconCode,
  IconStyle,
  Input as FpInput,
  InputFloatingControl as FpInputFloatingControl,
  InputGroup as FpInputGroup,
  InputPrepend as FpInputPrepend,
  Label as FpLabel,
  LabelText as FpLabelText
} from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    'fp-label': FpLabel,
    'fp-label-text': FpLabelText,
    'fp-input': FpInput,
    'fp-input-group': FpInputGroup,
    'fp-input-prepend': FpInputPrepend,
    'fp-floating-control': FpInputFloatingControl,
    'fp-icon': FpIcon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prependIcon: IconCode.calendar,
      controlIcon: IconCode.timesCircle,
      IconStyle
    };
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    onClear() {
      this.$emit('input', null);
    }
  }
};
</script>
