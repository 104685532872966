// check for null and length zero are needed to not overlap with other validators

import {
  RENT_PERIODS,
  UK_DEPOSIT_CAP_BREAK_POINT,
  UK_DEPOSIT_CAP_LOW_WEEKS_LIMIT,
  UK_DEPOSIT_CAP_HIGH_WEEKS_LIMIT,
  UK_POSTCODE_REGEX
} from '@/libs/utils/constants';
import { calculateRentByWeekInPence } from '@/libs/utils/helpers';
import { helpers } from 'vuelidate/lib/validators';

export const twoDecimalPlaces = value => {
  return value === null || value.length === 0 || /^[0-9]+\.?[0-9]{0,2}$/.test(value);
};

export const nonZeroStart = value => {
  return value === null || value.length === 0 || /^[1-9]+\.?[0-9]*$/.test(value);
};

export const validPostcode = value => {
  return value === null || value.length === 0 || UK_POSTCODE_REGEX.test(value);
};

export const doesNotContain = param => value => {
  return value === null || value.length === 0 || value.indexOf(param) === -1;
};

export const withinDepositCap = (value, validationModel) => {
  // In the UK after June 1 2019 deposits are capped at:
  // - 5 weeks' rent for annual rent up to 50000 pounds
  // - 6 weeks' rent for annual rent over 50000 pounds
  if (!validationModel.monthly_rental_amount) {
    return true;
  }
  const annualRent = parseInt(validationModel.monthly_rental_amount) * 12;
  const weeklyRentPence = calculateRentByWeekInPence(
    RENT_PERIODS.MONTH,
    validationModel.monthly_rental_amount
  );
  const weeklyRentAmount = Math.round(weeklyRentPence / 100);
  const weeksLimit =
    annualRent > UK_DEPOSIT_CAP_BREAK_POINT
      ? UK_DEPOSIT_CAP_HIGH_WEEKS_LIMIT
      : UK_DEPOSIT_CAP_LOW_WEEKS_LIMIT;
  return parseInt(value) <= weeklyRentAmount * weeksLimit;
};

export const checked = message => helpers.withParams({ message }, value => value === true);
