import _get from 'lodash/get';
import Box from 'elements/box/box.vue';
import SimpleTable from 'elements/simple_table/simple_table.vue';
import PaginationMixin from 'libs/mixins/simple_pagination_mixin.js';
import UserTenancies from 'components/user_tenancies/user_tenancies.vue';
import FormattedDate from 'elements/formatted_date/formatted_date.vue';
import FormattedCurrency from 'elements/formatted_currency/formatted_currency.vue';
import { InternalTenantApi } from 'libs/apis/tenant/internal';
import { lookupNationality } from 'libs/utils/helpers';
import FfUpdateTenantModal from '@/components/update_tenant_modal/update_tenant_modal.vue';
import { FpAlert, FpButton } from '@flatfair/vue-fairplay/molecules';
import { FpBody } from '@flatfair/vue-fairplay/atoms';
import { FLATBOND_STATUSES } from '@/libs/constants/generatedConstants';
import { InternalFlatbondApi } from '@/libs/apis';

export default {
  mixins: [PaginationMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    csrf: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      api: {
        tenant: new InternalTenantApi(this),
        flatbond: new InternalFlatbondApi(this)
      },
      alertStyle: {
        width: '100%',
        marginBottom: '1rem'
      }
    };
  },
  computed: {
    isResignNeeded() {
      return this.currentResults.some(tenant => tenant.flatbond.requires_drs_resign);
    }
  },
  methods: {
    fetch({ page, pageSize }) {
      const search =
        `?flatbond__id=${encodeURIComponent(this.id)}` +
        `&page=${encodeURIComponent(page)}` +
        `&page_size=${encodeURIComponent(pageSize)}`;
      return this.api.tenant.list(search).then(({ data }) => data);
    },
    calculateAmountToRefund(amountPaid, currentCost) {
      if (amountPaid > currentCost) {
        return amountPaid - currentCost;
      }

      return 0;
    },
    nationalityLabel(tenant) {
      const code = _get(tenant, 'user.nationality');
      if (!code) return '';

      const nationality = lookupNationality(code);
      return _get(nationality, 'label', code);
    },
    isTenantEditable(tenant) {
      const { status, has_pre_validation_enabled } = tenant.flatbond;
      return has_pre_validation_enabled && status === FLATBOND_STATUSES.CREATED;
    },
    async reloadData() {
      await this.selectPage(this.currentPage);
    },
    async onResign() {
      await this.api.flatbond.resetSignedDocuments(this.currentResults[0].flatbond.id);
      await this.selectPage(this.currentPage);
    }
  },
  components: {
    'box': Box,
    'ff-simple-table': SimpleTable,
    'user-tenancies': UserTenancies,
    'formatted-date': FormattedDate,
    'formatted-currency': FormattedCurrency,
    FfUpdateTenantModal,
    FpAlert,
    FpBody,
    FpButton
  }
};
