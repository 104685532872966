<template>
  <FpContainer data-testid="helper-dialog">
    <FpGrid>
      <FpGridColumn v-if="$slots.header">
        <FpHeader3>
          <slot name="header" />
        </FpHeader3>
      </FpGridColumn>
      <FpGridColumn v-if="$slots.body">
        <FpBody>
          <slot name="body" />
        </FpBody>
      </FpGridColumn>
      <FpGridColumn v-if="$slots.content">
        <slot name="content" />
      </FpGridColumn>
    </FpGrid>
  </FpContainer>
</template>

<script>
import { FpBody, FpContainer, FpGrid, FpGridColumn, FpHeader3 } from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FpContainer,
    FpGrid,
    FpGridColumn,
    FpHeader3,
    FpBody
  }
};
</script>
