import Box from 'elements/box/box.vue';
import Table from 'elements/table/table.vue';
import Modal from 'elements/modal/modal.vue';
import { TableDefinition } from 'libs/tableDefinition';
import VueDropzone from 'vue2-dropzone';
import { Claim, InternalClaim } from 'libs/api';
import {
  formatCurrencyFromCents,
  resolveCategoryValueToLabel,
  resolveChargeStatusValueToLabel,
  resolveChargeStatusValueToLabelClass,
  showNotification
} from 'libs/utils/helpers.js';

import { CHARGE_DETAIL_PAGE } from 'components/apps/ops/routes';
import { DEFAULT_ITEMS_PER_PAGE } from '../../libs/utils/constants';

export default {
  props: {
    productId: {
      type: Number,
      required: true
    },
    productClass: {
      type: String,
      default: 'flatbond'
    },
    csrf: {
      type: String,
      required: true
    }
  },
  mounted: function() {
    this._fetchCharges();
  },
  data: function() {
    return {
      loading: true,
      documentsLoading: true,
      chargesApi: new Claim(this, this.productClass),
      internalChargesApi: new InternalClaim(this),
      charges: [],
      chargesData: { columns: [], rows: [] },
      showDocumentsModal: false,
      id: null,
      category: null,
      description: null,
      amount: null,
      documents: [],
      dropzoneOptions: this._generateDropzoneOptions(),
      onCompletedUpload: this._dropzoneComplete.bind(this)
    };
  },
  computed: {
    totalAmount: function() {
      const totalAmountInCents = this.charges.reduce((totalAmountSoFar, charge) => {
        return totalAmountSoFar + charge.amount;
      }, 0);

      return formatCurrencyFromCents(totalAmountInCents);
    },
    documentsDataForTable: function() {
      return new TableDefinition()
        .setEmptyMessage('No evidence was uploaded!')
        .addInternalGenericDocumentDownload(
          'File',
          document => document.file_name,
          documentObj => {
            return {
              document: documentObj
            };
          }
        )
        .addFlatbondUserRole('Uploaded by', document => ({
          flatbondId: this.productId,
          userId: document.created_by
        }))
        .addAction(
          '',
          () => 'X',
          document => {
            return {
              action: () => this.removeDocument(document),
              colourClass: 'btn-danger'
            };
          }
        )
        .build(this.documents);
    },
    availablePages() {
      if (!this.documents) {
        return {};
      }
      const pages = {};
      for (let i = 0; i <= Math.ceil(this.documents.length / DEFAULT_ITEMS_PER_PAGE); i++) {
        pages[i] = true;
      }
      return pages;
    }
  },
  methods: {
    _fetchCharges: async function() {
      this.loading = true;

      try {
        var response = await this.chargesApi.list(this.productId);
      } finally {
        this.loading = false;
      }

      this.charges = response.data;
      this._assembleChargesTable(this.charges);
    },
    _assembleChargesTable: function(data) {
      this.chargesData = new TableDefinition()
        .setEmptyMessage('No charges so far 👌')
        .addAction(
          'Charge ID',
          charge => charge.id,
          charge => {
            return {
              action: () =>
                this.$router.push({
                  name: CHARGE_DETAIL_PAGE,
                  params: {
                    chargeId: `${charge.id}`
                  }
                })
            };
          }
        )
        .addSimpleRow('Category', charge => resolveCategoryValueToLabel(charge.category))
        .addSimpleRow('Description', charge => charge.description)
        .addSimpleRow('Amount', charge => formatCurrencyFromCents(charge.amount))
        .addLabel(
          'Status',
          charge => resolveChargeStatusValueToLabel(charge.status),
          charge => {
            return { colourClass: resolveChargeStatusValueToLabelClass(charge.status) };
          }
        )
        .addAction(
          'Documents',
          () => 'View documents',
          charge => {
            return {
              action: () => {
                this._openDocumentsModal(charge);
              }
            };
          }
        )
        .build(data);
    },
    async _openDocumentsModal(charge) {
      this.id = charge.id;
      this.category = resolveCategoryValueToLabel(charge.category);
      this.description = charge.description;
      this.amount = formatCurrencyFromCents(charge.amount);
      this.documentsLoading = true;
      this.showDocumentsModal = true;
      try {
        const response = await this.chargesApi.get(charge.id);
        this.documents = response.data.claim_documents;
      } catch (error) {
        showNotification(this, 'Documents could not be loaded', 'error');
      } finally {
        this.documentsLoading = false;
      }
    },
    hideModal() {
      this.showDocumentsModal = false;
    },
    _generateDropzoneOptions: function() {
      return {
        url: '/api/private/v1/document/generic/',
        maxFiles: 1,
        headers: { 'X-CSRFToken': this.csrf },
        params: () => {
          return {
            type: 'claim_document',
            claim_id: this.claimId
          };
        }
      };
    },
    _dropzoneComplete: function(file) {
      this.$refs['evidence-upload'].removeFile(file);

      if (file.status === 'error') {
        showNotification(this, 'Evidence document can not be uploaded', 'error');
      }
    },
    addUploadedDocument: async function(_, newFile) {
      this.documents.push(newFile);

      try {
        await this.internalChargesApi.addDocument(this.id, newFile.id);
      } catch (error) {
        showNotification(this, 'Evidence document can not be added', 'error');
        return;
      } finally {
        this.loading = false;
      }
      showNotification(this, 'Evidence document added successfully', 'success');
    },
    removeDocument: async function(document) {
      try {
        await this.internalChargesApi.removeDocument(this.id, document.id);
      } catch (error) {
        showNotification(this, 'Document cannot be removed', 'error');
        return;
      }
      this.documents = this.documents.filter(doc => doc.id !== document.id);
      showNotification(this, 'Removed document successfully', 'success');
    }
  },
  components: {
    'box': Box,
    'v-table': Table,
    'modal': Modal,
    'v-dropzone': VueDropzone
  }
};
