export default {
  mounted: function() {
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
    }
  },
  props: {
    searchLabel: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  validations: {
    query: {}
  },
  data: function() {
    return {
      query: this.value
    };
  },
  methods: {
    onSearch: function() {
      this.$emit('search');
    },
    onClear: function() {
      this.$emit('clear');
    }
  },
  watch: {
    value: function() {
      this.query = this.value;
    },
    query: function() {
      this.$emit('input', this.query);
    }
  }
};
