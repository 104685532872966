import { showNotification } from '@/libs/utils/helpers';

export default {
  data() {
    return {
      result: null,
      loading: true
    };
  },
  mounted() {
    this._fetch();
  },
  methods: {
    async fetch() {
      throw new Error('Implement the fetch method when using the fetch-mixin.');
    },
    async refetch() {
      this._fetch();
    },
    async _fetch() {
      this.loading = true;
      try {
        this.result = await this.fetch();
      } catch (err) {
        showNotification(this, 'Failed to fetch data', 'error');
        // eslint-disable-next-line no-console
        console.error(err);
      }
      this.loading = false;
    }
  }
};
