export default {
  extractErrorsToFieldValidations: function(validations, field, errors) {
    const validation = validations[field];

    if (validation) {
      validation.invalid = true;
      validation.message = errors[field][0];
    }
  },

  buildValidationsObject: function(fieldsToValidate) {
    const fieldsToValidateObject = fieldsToValidate.reduce((previous, field) => {
      previous[field] = {
        invalid: false,
        message: ''
      };

      return previous;
    }, {});

    return fieldsToValidateObject;
  }
};
