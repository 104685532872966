import { InternalChargeApiBase } from './base';
import { formatDateForApi, currencyUnitsToCents } from '@/libs/utils/helpers';

export class InternalChargePaymentPlanApi extends InternalChargeApiBase {
  create(tenantId, startDate, amount, period, initialDueDate = null, initialAmount = null) {
    const data = {
      flatbond_tenant_id: tenantId,
      start_date: formatDateForApi(startDate),
      amount: currencyUnitsToCents(amount),
      period: period,
      initial_due_date: formatDateForApi(initialDueDate),
      initial_amount: currencyUnitsToCents(initialAmount)
    };
    return super.post(`payment-plan/`, data, 'Failed to create payment plan');
  }

  list(query = '') {
    return super.get(`payment-plan/${query}`, 'Failed to list payment plans');
  }

  get(planId) {
    return super.get(`payment-plan/${planId}`, 'Failed to get payment plan');
  }

  acceptOnBehalfOfTenant(planId) {
    return super.put(
      `payment-plan/${planId}/accept/`,
      {},
      'Failed to accept plan on tenants behalf'
    );
  }
}
