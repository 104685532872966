import * as Sentry from '@sentry/browser';
import Box from 'elements/box/box.vue';
import {
  FLATBOND_TYPE_OPTIONS,
  RENT_PERIODS,
  MINIMUM_NUMBER_OF_TENANTS,
  MAXIMUM_NUMBER_OF_TENANTS
} from 'libs/utils/constants';
import { calculateRentByWeekInPence } from 'libs/utils/helpers';
import axios from 'axios';
import Modal from 'elements/modal/modal.vue';
import ButtonGroupSelect from 'elements/button_group_select/button_group_select.vue';
import NumberStepper from 'elements/number_stepper/number_stepper.vue';

export default {
  props: {
    internal: {
      type: Boolean,
      required: false
    }
  },
  data: function() {
    return {
      rentForPeriod: null,
      rentPeriod: RENT_PERIODS.MONTH,
      flatbondOptions: FLATBOND_TYPE_OPTIONS,
      flatbondType: FLATBOND_TYPE_OPTIONS[0].value,
      numberOfTenants: MINIMUM_NUMBER_OF_TENANTS,
      weeklyRent: '...',
      monthlyRent: '...',
      calculatedFeeWithoutVat: '...',
      calculatedFeeWithVat: '...',
      discountedWithVat: '...',
      discountedWithoutVat: '...',
      discountAmount: '...',
      readyToCalculate: true,
      calculatedOnce: false,
      show: false,
      constants: {
        RENT_PERIODS,
        MINIMUM_NUMBER_OF_TENANTS,
        MAXIMUM_NUMBER_OF_TENANTS
      }
    };
  },
  watch: {
    rent: function() {
      this.readyToCalculate = true;
    },
    flatbondType: function() {
      this.readyToCalculate = true;
    },
    numberOfTenants: function() {
      this.readyToCalculate = true;
    }
  },
  computed: {
    rent: function() {
      return calculateRentByWeekInPence(this.rentPeriod, this.rentForPeriod);
    },
    discountIsApplied: function() {
      const discountFloat = Number(this.discountAmount.replace(/[^0-9.-]+/g, ''));
      return discountFloat > 0;
    }
  },
  methods: {
    selectRentPeriod: function(period) {
      this.rentPeriod = period;
    },
    calculateRent: function() {
      const options = {
        params: {
          rent: this.rent,
          flatbond_type: this.flatbondType,
          number_of_tenants: this.numberOfTenants
        }
      };

      return axios
        .get('/api/private/v1/flatbond/calculate_fees_for_rent/', options)
        .then(response => {
          this.readyToCalculate = false;
          this.calculatedOnce = true;
          this.weeklyRent = response.data.weekly_rent;
          this.monthlyRent = response.data.monthly_rent;
          this.calculatedFeeWithVat = response.data.total_fee_with_vat;
          this.discountedWithVat = response.data.discounted_fee_with_vat;
          this.discountAmount = response.data.discount_amount;
        })
        .catch(error => {
          error.name = 'FlatbondCalculateFeesForRent';
          Sentry.captureException(error, { extra: this.$data });
          throw error;
        });
    }
  },
  components: {
    'box': Box,
    'modal': Modal,
    'button-group-select': ButtonGroupSelect,
    'number-stepper': NumberStepper
  }
};
