import DatePicker from 'vuejs-datepicker';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Moment from 'moment';

export default {
  props: {
    inputType: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Object, Date],
      default: null
    },
    validation: {
      type: Object,
      default: function() {
        return {};
      }
    },
    validationMessages: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    serverValidationsErrorMessages: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabledDates: {
      type: Object,
      default: function() {
        return {};
      }
    },
    openDate: {
      type: Date,
      default: function() {
        return new Moment().toDate();
      }
    },
    selectOptions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    currencyOptions: {
      type: Object,
      default: function() {
        return {};
      }
    },
    noOptionsText: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    readonly: Boolean,
    alignRight: Boolean,
    hideClear: Boolean,
    onSearchHandler: Function,
    onBlurHandler: Function
  },
  data: function() {
    return {
      inputValue: this.value,
      search: this.onSearchHandler ? this.onSearchHandler : () => {},
      selectBlur: this.onBlurHandler ? this.onBlurHandler : () => {}
    };
  },
  methods: {
    onClick: function() {
      this.$emit('click');
    },
    isValid: function() {
      return this.validation.$invalid === false && this.serverValidationsErrorMessages.length === 0;
    },
    isInvalid: function() {
      return this.validation.$invalid || this.serverValidationsErrorMessages.length > 0;
    },
    isError: function() {
      return this.validation.$error;
    },
    hasValue: function() {
      return Boolean(this.value);
    },
    getInvalidLevel: function() {
      if (this.validation.$invalid) {
        return 'danger';
      } else if (this.serverValidationsErrorMessages.length > 0) {
        return 'warning';
      }
    },
    wasValidated: function() {
      return this.validation.$dirty || this.serverValidationsErrorMessages.length > 0;
    }
  },
  watch: {
    value: function() {
      this.inputValue = this.value;
    },
    inputValue: function() {
      this.$emit('input', this.inputValue);
      if (this.validation.$touch) {
        this.validation.$touch();
      }
    }
  },
  computed: {
    id: function() {
      return `id_${this.name}`;
    },
    feedback_id: function() {
      return `id_${this.name}_feedback`;
    }
  },
  components: {
    'date-picker': DatePicker,
    'v-select': VueSelect
  }
};
