export default {
  props: {
    title: {
      type: String,
      default: null
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerHasContent() {
      if (this.$slots['header']) {
        return true;
      }
      return false;
    },
    footerHasContent() {
      if (this.$slots['footer']) {
        return true;
      }
      return false;
    }
  }
};
