import { OrganisationUnitApiBase } from './base';

export class OrganisationUnitApi extends OrganisationUnitApiBase {
  listBranches() {
    return super.get('', 'Failed to list branches');
  }

  getHomeUnit() {
    return super.get('home-unit', 'Failed to list home unit');
  }
}
