import { required, minLength, maxLength, email, minValue, numeric } from 'vuelidate/lib/validators';

const EMAIL_LENGTH = 254;
export const EMAIL = { email, maxLength: maxLength(EMAIL_LENGTH) };
export const EMAIL_REQUIRED = { required, ...EMAIL };

const NAME_MAX_LENGTH = 254;
export const NAME = { maxLength: maxLength(NAME_MAX_LENGTH) };
export const NAME_REQUIRED = { required, ...NAME };

const ADDRESS_LINE_LENGTH = 254;
export const ADDRESS_LINE_1 = { maxLength: maxLength(ADDRESS_LINE_LENGTH) };
export const ADDRESS_LINE_1_REQUIRED = { required, ...ADDRESS_LINE_1 };
export const ADDRESS_LINE_2 = { maxLength: maxLength(ADDRESS_LINE_LENGTH) };
export const ADDRESS_LINE_2_REQUIRED = { required, ...ADDRESS_LINE_2 };

const POSTCODE_LENGTH = 8;
export const POSTCODE = { maxLength: maxLength(POSTCODE_LENGTH) };
export const POSTCODE_REQUIRED = { required, ...POSTCODE };

const CITY_MAX_LENGTH = 254;
export const CITY = { maxLength: maxLength(CITY_MAX_LENGTH) };
export const CITY_REQUIRED = { required, ...CITY };

const ACCOUNT_NUMBER_EXACT_LENGTH = 8;
export const ACCOUNT_NUMBER = {
  minLength: minLength(ACCOUNT_NUMBER_EXACT_LENGTH),
  maxLength: maxLength(ACCOUNT_NUMBER_EXACT_LENGTH)
};
export const ACCOUNT_NUMBER_REQUIRED = { required, ...ACCOUNT_NUMBER };

const SORT_CODE_EXACT_LENGTH = 6;
export const SORT_CODE = {
  minLength: minLength(SORT_CODE_EXACT_LENGTH),
  maxLength: maxLength(SORT_CODE_EXACT_LENGTH)
};
export const SORT_CODE_REQUIRED = { required, ...SORT_CODE };

const COMPANY_REGISTRATION_NUMBER_EXACT_LENGTH = 8;
export const COMPANY_REGISTRATION_NUMBER = {
  minLength: minLength(COMPANY_REGISTRATION_NUMBER_EXACT_LENGTH),
  maxLength: maxLength(COMPANY_REGISTRATION_NUMBER_EXACT_LENGTH)
};
export const COMPANY_REGISTRATION_NUMBER_REQUIRED = { ...COMPANY_REGISTRATION_NUMBER, required };

const VAT_NUMBER_MIN_LENGTH = 11;
const VAT_NUMBER_MAX_LENGTH = 14;
export const VAT_NUMBER = {
  minLength: minLength(VAT_NUMBER_MIN_LENGTH),
  maxLength: maxLength(VAT_NUMBER_MAX_LENGTH)
};
export const VAT_NUMBER_REQUIRED = { ...VAT_NUMBER, required };

export const addressSchema = {
  address_line_1: {
    required,
    maxLength: maxLength(ADDRESS_LINE_LENGTH)
  },
  address_line_2: { required, maxLength: maxLength(ADDRESS_LINE_LENGTH) },
  city: { required, maxLength: maxLength(ADDRESS_LINE_LENGTH) },
  county: { required, maxLength: maxLength(ADDRESS_LINE_LENGTH) },
  postcode: POSTCODE_REQUIRED
};

export const personSchema = {
  title: { required },
  first_name: NAME_REQUIRED,
  last_name: NAME_REQUIRED,
  email_address: { required, email, maxLength: maxLength(EMAIL_LENGTH) },
  ...addressSchema
};

export const RENT = { numeric, minValue: minValue(1) };
export const RENT_REQUIRED = { required, ...RENT };
