import axios from 'axios/index';
import { ITEMS_PER_PAGE } from '@/libs/utils/constants';
import Moment from 'moment';
import {
  formatDateForApi,
  logExceptionOnSentry,
  resolveConstant,
  showNotification
} from './utils/helpers';

class Api {
  constructor(vue) {
    this.vue = vue;
    this.errorName = 'ApiError';
  }

  _paginationQuery(page) {
    return `page=${page + 1}`;
  }

  _getOptions() {
    return {
      headers: {
        'X-CSRFTOKEN': this.vue.csrf
      }
    };
  }

  _handleError(errorTitle) {
    return error => {
      error.name = this.errorName;

      const extraErrorDetails = {};
      if (error.response) {
        extraErrorDetails.data = error.response.data;
        extraErrorDetails.status = error.response.status;
        extraErrorDetails.headers = error.response.headers;
      } else if (error.request) {
        extraErrorDetails.request = error.request;
      } else {
        extraErrorDetails.message = error.message;
      }
      extraErrorDetails.config = error.request;

      logExceptionOnSentry(error, extraErrorDetails);
      showNotification(this.vue, errorTitle, 'error');

      throw error;
    };
  }
}

class ClaimLinkedApi extends Api {
  constructor(vue, productClass) {
    super(vue);
    this.productClass = productClass;
    this.errorName = 'ClaimLinkedApiError';
  }

  _buildClaimParamsWithEntity(productId) {
    const options = {
      params: {}
    };

    options.params[this.productClass] = productId;

    return options;
  }

  _buildClaimParamsWithId(productId) {
    const options = {
      params: {}
    };

    options.params[`${this.productClass}_id`] = productId;

    return options;
  }
}

export class OrganizationGroup extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'OrganizationGroupError';
  }

  update(id, data) {
    return axios
      .put(`/api/private/v1/organization/group/${id}/`, data, this._getOptions())
      .catch(this._handleError('Failed to update company details'));
  }

  progressOnboardingState(id, newState) {
    const data = {
      onboarding_status: newState
    };
    return axios
      .post(
        `/api/private/v1/organization/group/${id}/progress_onboarding_state/`,
        data,
        this._getOptions()
      )
      .catch(this._handleError('Failed to update company details'));
  }
}

export class InternalOrganizationGroup extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalOrganizationGroupError';
  }

  list(query = '') {
    return axios
      .get(`/api/private/v1/organization/internal/group/${query}`)
      .catch(this._handleError('Failed to retrieve the organisation groups'));
  }

  get(id) {
    return axios
      .get(`/api/private/v1/organization/internal/group/${id}`)
      .catch(this._handleError(`Failed to retrieve the organisation group with id: ${id}`));
  }

  create(
    name,
    commission,
    introducerId,
    emailDomain,
    accountManagerId,
    salesManagerId,
    signOnDate,
    rightmoveId
  ) {
    const data = {
      name: name,
      commission: commission / 100,
      introducer_id: introducerId,
      email_domain: emailDomain,
      account_manager_id: accountManagerId,
      sales_manager_id: salesManagerId,
      signed_on: formatDateForApi(signOnDate),
      rightmove_id: rightmoveId
    };
    return axios
      .post(`/api/private/v1/organization/internal/group/`, data, this._getOptions())
      .catch(this._handleError('Failed to create organization'));
  }
}

export class OrganizationUser extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'OrganizationUserError';
  }

  create(email) {
    const data = {
      invite_email: email,
      tags: []
    };
    return axios
      .post(`/api/private/v1/organization/admin/organization-user/`, data, this._getOptions())
      .catch(this._handleError('Failed to update company details'));
  }

  list() {
    return axios
      .get('/api/private/v1/organization/admin/organization-user/')
      .catch(this._handleError('Failed to retrieve the organisation users'));
  }

  destroy(id) {
    return axios
      .delete(`/api/private/v1/organization/admin/organization-user/${id}/`, this._getOptions())
      .catch(this._handleError('Failed to retrieve the organisation users'));
  }
}

export class InternalOrganizationUser extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalOrganizationUserError';
  }

  create(group, email, grantGroupAdmin, homeBranchId, homeRole) {
    const data = {
      group: group,
      invite_email: email,
      grant_group_admin: grantGroupAdmin,
      home_unit_id: homeBranchId,
      home_unit_role: homeRole,
      tags: []
    };
    return axios
      .post(`/api/private/v1/organization/internal/organization-user/`, data, this._getOptions())
      .catch(this._handleError('Failed to update company details'));
  }

  list(query) {
    return axios
      .get(`/api/private/v1/organization/internal/organization-user/${query}`)
      .catch(this._handleError('Failed to retrieve the organisation users'));
  }

  activate(id) {
    return axios
      .put(
        `/api/private/v1/organization/internal/organization-user/${id}/activate/`,
        {},
        this._getOptions()
      )
      .catch(this._handleError('Failed to activate organisation user'));
  }

  deactivate(id) {
    return axios
      .put(
        `/api/private/v1/organization/internal/organization-user/${id}/deactivate/`,
        {},
        this._getOptions()
      )
      .catch(this._handleError('Failed to deactivate organisation user'));
  }

  remove(id) {
    return axios
      .delete(`/api/private/v1/organization/internal/organization-user/${id}/`, this._getOptions())
      .catch(this._handleError('Failed to remove organisation user'));
  }
}

export class OrganizationUnit extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'OrganizationUnitError';
  }

  create(branchName, numberOfUnits, branchEmail) {
    const data = {
      name: branchName,
      units_under_management: numberOfUnits,
      email: branchEmail
    };
    return axios
      .post(`/api/private/v1/organization/unit/`, data, this._getOptions())
      .catch(this._handleError('Failed to update company details'));
  }

  list() {
    return axios
      .get('/api/private/v1/organization/unit/')
      .catch(this._handleError('Failed to retrieve the organisation units'));
  }

  destroy(id) {
    return axios
      .delete(`/api/private/v1/organization/unit/${id}/`, this._getOptions())
      .catch(this._handleError('Failed to retrieve the organisation units'));
  }

  homeUnit() {
    return axios
      .get('/api/private/v1/organization/unit/home/')
      .catch(this._handleError("Failed to retrieve the user's home unit"));
  }

  rooms(buildingId) {
    return axios
      .get(`/api/private/v1/organization/unit/${buildingId}/room/`)
      .catch(this._handleError('Failed to retrieve the building rooms'));
  }
}

export class InternalOrganizationUnit extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalOrganizationUnitError';
  }

  create(
    groupId,
    branchName,
    grantAdministration,
    numberOfUnits,
    branchEmail,
    accountManagerId,
    salesManagerId,
    hubspotId
  ) {
    const data = {
      group: groupId,
      name: branchName,
      grant_administration: grantAdministration,
      units_under_management: numberOfUnits,
      email: branchEmail,
      account_manager_id: accountManagerId,
      sales_manager_id: salesManagerId,
      hubspot_id: hubspotId
    };
    return axios
      .post(`/api/private/v1/organization/internal/unit/`, data, this._getOptions())
      .catch(this._handleError('Failed to update company details'));
  }

  list(groupId, type = 'branch,area,division') {
    return axios
      .get(`/api/private/v1/organization/internal/unit/?group_id=${groupId}&type__in=${type}`)
      .catch(this._handleError('Failed to retrieve the organisation units'));
  }

  destroy(id) {
    return axios
      .delete(`/api/private/v1/organization/internal/unit/${id}/`, this._getOptions())
      .catch(this._handleError('Failed to retrieve the organisation units'));
  }
}

export class OnboardingDocument extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'OnboardingDocumentError';
  }

  list() {
    return axios
      .get('/api/private/v1/document/onboarding/')
      .catch(this._handleError('Failed to retrieve documents'));
  }
}

export class CoreProductApi extends ClaimLinkedApi {
  constructor(vue, productClass) {
    super(vue, productClass);
    this.errorName = 'CoreProductApiError';
  }

  submitClaims(productId) {
    const data = {};

    data[this.productClass] = productId;

    return axios
      .post(`/api/private/v1/${this.productClass}/claims/submit/`, data, this._getOptions())
      .catch(this._handleError('Failed to submit the claims'));
  }

  updateTags(productId, tags) {
    const data = {
      tags: tags
    };
    return axios
      .put(`/api/private/v1/${this.productClass}/${productId}/`, data, this._getOptions())
      .catch(this._handleError('Failed to update the tags'));
  }

  cancelWithTags(productId, tags) {
    const data = {
      change_type: resolveConstant('PRODUCT_STATUS_CANCEL'),
      tags: tags
    };

    data[this.productClass] = productId;

    return axios
      .post(`/api/private/v1/${this.productClass}/internal/update/`, data, this._getOptions())
      .catch(this._handleError('Failed to cancel'));
  }
}

export class InternalFlatbond extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalFlatbondError';
  }

  listStartingSoon(page) {
    return axios
      .get(`/api/private/v1/flatbond/internal/starting-soon/?${this._paginationQuery(page)}`)
      .catch(this._handleError('Failed to retrieve the flatbonds starting soon'));
  }

  listClosingSoon(page) {
    return axios
      .get(`/api/private/v1/flatbond/internal/closing-soon/?${this._paginationQuery(page)}`)
      .catch(this._handleError('Failed to retrieve the flatbonds closing soon'));
  }

  listActivePastCloseDate(page) {
    return axios
      .get(
        `/api/private/v1/flatbond/internal/active-past-close-date/?${this._paginationQuery(page)}`
      )
      .catch(this._handleError('Failed to retrieve the active flatbonds past close date'));
  }

  listNotSent(page) {
    return axios
      .get(`/api/private/v1/flatbond/internal/not-sent/?${this._paginationQuery(page)}`)
      .catch(this._handleError('Failed to retrieve the not sent flatbonds'));
  }

  list(query) {
    return axios
      .get(`/api/private/v1/flatbond/internal/${query}`)
      .catch(this._handleError('Failed to retrieve the not sent flatbonds'));
  }

  get(id) {
    return axios
      .get(`/api/private/v1/flatbond/internal/${id}`)
      .catch(this._handleError('Failed to retrieve the not sent flatbonds'));
  }

  activateFlatbond(productId) {
    return axios
      .post(`/api/private/v1/flatbond/internal/${productId}/verify/`, {}, this._getOptions())
      .catch(this._handleError('Failed to activate flatbond'));
  }

  retrieveVerificationProgress(productId) {
    return axios
      .get(`/api/private/v1/flatbond/internal/${productId}/verification`)
      .catch(this._handleError('Could not retrieve verification progress for flatbond'));
  }

  updateVerificationProgress(productId, progress) {
    const progressApiObject = {
      verified_address: progress.verifiedAddress,
      verified_dates: progress.verifiedDates,
      verified_members: progress.verifiedMembers,
      verified_rent: progress.verifiedRent,
      verified_documents: progress.verifiedDocuments,
      verified_previous_tenancies: progress.verifiedPreviousTenancies,
      verified_references_uploaded: progress.verifiedReferencesUploaded
    };

    return axios
      .put(
        `/api/private/v1/flatbond/internal/${productId}/verification/${progress.id}/`,
        progressApiObject,
        this._getOptions()
      )
      .catch(this._handleError('Failed to update verification progress for flatbond'));
  }

  updateFlatbondRentAmount(id, amount) {
    const data = {
      new_rent_per_week_in_cents: amount
    };

    return axios
      .post(`/api/private/v1/flatbond/internal/${id}/update-rent/`, data, this._getOptions())
      .catch(this._handleError(`Failed to update rent amount for flatbond ${id}`));
  }

  getUserRoles(flatbondId, userId) {
    return axios
      .get(`/api/private/v1/flatbond/internal/${flatbondId}/user_roles/?user=${userId}`)
      .catch(this._handleError('Failed to fetch user roles'));
  }

  charges(flatbondId) {
    return axios
      .get(`/api/private/v1/flatbond/internal/${flatbondId}/charges/`)
      .catch(this._handleError('Failed to fetch flatbond charges'));
  }
}

export class Flatbond extends CoreProductApi {
  constructor(vue, productClass) {
    productClass = productClass || 'flatbond';
    super(vue, productClass);
    this.errorName = 'FlatbondError';
  }

  listToAction(page, pageSize = ITEMS_PER_PAGE) {
    return axios
      .get(
        `/api/private/v1/flatbond/to-action/?${this._paginationQuery(page)}&page_size=${pageSize}`
      )
      .catch(this._handleError('Failed to retrieve the flatbonds to action'));
  }
}

export class FlatbondExtension extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'FlatbondExtensionError';
  }

  create(newCloseDate, extensionType, flatbondId) {
    const data = {
      new_close_date: new Moment(newCloseDate).utc().format('YYYY-MM-DD'),
      type: extensionType,
      flatbond: flatbondId
    };

    return axios
      .post(`/api/private/v1/flatbond/${flatbondId}/extend/`, data, this._getOptions())
      .catch(this._handleError('Failed to extend the flatbond'));
  }
}

export class Subscription extends CoreProductApi {
  constructor(vue, productClass) {
    productClass = productClass || 'subscription';
    super(vue, productClass);
    this.errorName = 'SubscriptionError';
  }

  create(subscriptionData) {
    const data = {
      active_deskbond: {
        rent: subscriptionData.rent,
        start_date: subscriptionData.startDate,
        close_date: subscriptionData.closeDate,
        room_name: subscriptionData.roomName,
        room_area: subscriptionData.roomArea
      },
      member_email: subscriptionData.memberEmail,
      building: subscriptionData.building
    };

    return axios
      .post(`/api/private/v1/subscription/`, data, this._getOptions())
      .catch(this._handleError('Failed to create the subscription'));
  }
}

export function getProductApiClass(productClass) {
  switch (productClass) {
    case 'subscription':
      return Subscription;
    default:
      return Flatbond;
  }
}

export class Tenant extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'TenantError';
  }

  confirmTerms(tenantId) {
    return axios
      .post(`/api/private/v1/tenant/${tenantId}/confirm/`, {}, this._getOptions())
      .catch(this._handleError(`Failed to confirm terms for this tenant`));
  }
}

export class Document extends ClaimLinkedApi {
  constructor(vue, productClass) {
    super(vue, productClass);
    this.errorName = 'DocumentError';
  }

  list(productId) {
    const options = this._buildClaimParamsWithEntity(productId);

    return axios
      .get(`/api/private/v1/document/${this.productClass}/`, options)
      .catch(this._handleError(`Failed to retrieve ${this.productClass} documents`));
  }

  listForClaimsSubmission(productId) {
    const options = this._buildClaimParamsWithEntity(productId);

    return axios
      .get(`/api/private/v1/document/${this.productClass}/claims-submission`, options)
      .catch(this._handleError('Failed to retrieve claims submission documents'));
  }

  delete(productId, documentId) {
    const options = {
      ...this._getOptions(),
      data: {
        [this.productClass]: productId
      }
    };

    return axios
      .delete(`/api/private/v1/document/${this.productClass}/${documentId}`, options)
      .catch(this._handleError('Failed to delete the document'));
  }
}

export class InternalClaim extends ClaimLinkedApi {
  constructor(vue, productClass) {
    super(vue, productClass);
    this.errorName = 'InternalClaimError';
  }

  list() {
    return axios
      .get('/api/private/v1/claim/internal/')
      .catch(this._handleError('Failed to retrieve claims'));
  }

  get(claimId) {
    return axios
      .get(`/api/private/v1/claim/internal/${claimId}`)
      .catch(this._handleError('Failed to retrieve claims'));
  }

  addDocument(claimId, documentId) {
    const data = {
      document_id: documentId
    };

    return axios
      .post(`/api/private/v1/claim/internal/${claimId}/add-document/`, data, this._getOptions())
      .catch(this._handleError('Failed to update the claim'));
  }

  removeDocument(claimId, documentId) {
    const data = {
      document_id: documentId
    };

    return axios
      .post(`/api/private/v1/claim/internal/${claimId}/remove-document/`, data, this._getOptions())
      .catch(this._handleError('Failed to update the claim'));
  }
}

export class Claim extends ClaimLinkedApi {
  constructor(vue, productClass) {
    super(vue, productClass);
    this.errorName = 'ClaimError';
  }

  create(newClaimData) {
    const data = {
      category: newClaimData.category,
      description: newClaimData.description,
      amount: newClaimData.amount,
      documents_ids: newClaimData.documentsIds
    };

    data[`${this.productClass}_id`] = newClaimData.productId;

    return axios
      .post('/api/private/v1/claim/', data, this._getOptions())
      .catch(this._handleError('Failed to create the claim'));
  }

  get(claimId) {
    return axios
      .get(`/api/private/v1/claim/${claimId}/`, this._getOptions())
      .catch(this._handleError('Failed to retrieve the claim'));
  }

  list(productId) {
    const options = this._buildClaimParamsWithEntity(productId);

    return axios
      .get('/api/private/v1/claim/', options)
      .catch(this._handleError('Failed to retrieve claims'));
  }

  update(claimId, newClaimData) {
    const data = {
      category: newClaimData.category,
      description: newClaimData.description,
      amount: newClaimData.amount,
      documents_ids: newClaimData.documentsIds
    };

    data[`${this.productClass}_id`] = newClaimData.productId;

    return axios
      .put(`/api/private/v1/claim/${claimId}/`, data, this._getOptions())
      .catch(this._handleError('Failed to update the claim'));
  }

  delete(claimId) {
    return axios
      .delete(`/api/private/v1/claim/${claimId}/`, this._getOptions())
      .catch(this._handleError('Failed to delete the claim'));
  }
}

export class ClaimsCase extends ClaimLinkedApi {
  constructor(vue, productClass) {
    super(vue, productClass);
    this.errorName = 'ClaimsCaseError';
  }

  getOrCreate(productId) {
    const options = this._buildClaimParamsWithId(productId);

    return axios
      .get(`/api/private/v1/claim/case/get-or-create/`, options)
      .catch(this._handleError('Failed to retrieve the claims case'));
  }

  update(claimsCase) {
    const data = {
      is_covered_by_insurance: claimsCase.isCoveredByInsurance,
      pay_to: claimsCase.payTo,
      tenant_phone_number: claimsCase.tenantPhoneNumber
    };

    return axios
      .put(`/api/private/v1/claim/case/${claimsCase.id}/`, data, this._getOptions())
      .catch(this._handleError('Failed to update the claim case'));
  }
}

export class InternalFlatbondClaimsCase extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalFlatbondClaimsCaseError';
  }

  get(caseId) {
    return axios
      .get(`/api/private/v1/claim/internal/case/${caseId}/`)
      .catch(this._handleError('Failed to retrieve the claims case'));
  }

  list(query = '') {
    return axios
      .get(`/api/private/v1/claim/internal/case/${query}`)
      .catch(this._handleError('Failed to retrieve the claims cases'));
  }

  update(flatbondClaimsCase) {
    const data = {
      is_covered_by_insurance: flatbondClaimsCase.isCoveredByInsurance,
      pay_to: flatbondClaimsCase.payTo,
      handler_id: flatbondClaimsCase.handlerId
    };

    return axios
      .put(
        `/api/private/v1/claim/internal/case/${flatbondClaimsCase.id}/`,
        data,
        this._getOptions()
      )
      .catch(this._handleError('Failed to update the claims case'));
  }

  raiseDispute(id) {
    return axios
      .post(`/api/private/v1/claim/internal/case/${id}/raise-dispute/`, {}, this._getOptions())
      .catch(this._handleError('Failed to raise dispute'));
  }

  close(id) {
    return axios
      .post(`/api/private/v1/claim/internal/case/${id}/close/`, {}, this._getOptions())
      .catch(this._handleError('Failed to close case'));
  }

  getPaymentStatus(id) {
    return axios
      .get(`/api/private/v1/claim/internal/case/${id}/payment-status/`)
      .catch(this._handleError('Failed to fetch payment status'));
  }

  deemAccepted(flatbondClaimsCaseId) {
    return axios
      .post(
        `/api/private/v1/claim/internal/case/${flatbondClaimsCaseId}/deem-accepted/`,
        {},
        this._getOptions()
      )
      .catch(this._handleError('Failed to update the claims case'));
  }

  createAdjudicationReport(flatbondClaimsCaseId) {
    return axios
      .post(
        `/api/private/v1/claim/internal/case/${flatbondClaimsCaseId}/create-adjudication-report/`,
        {},
        this._getOptions()
      )
      .catch(this._handleError('Failed to update the claims case'));
  }
}

export class ReferenceRequest extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'ReferenceRequestError';
  }

  create(tenantEmail) {
    const data = {
      user_email: tenantEmail
    };

    return axios
      .post(`/api/private/v1/referencing/request/`, data, this._getOptions())
      .catch(this._handleError('Failed to create reference request'));
  }

  list(query = '') {
    return axios
      .get(`/api/private/v1/referencing/request/${query}`)
      .catch(this._handleError('Failed to retrieve reference requests'));
  }

  listForTenant() {
    return axios
      .get('/api/private/v1/referencing/tenant-request/')
      .catch(this._handleError('Failed to retrieve reference requests'));
  }

  getForTenant(requestId) {
    return axios
      .get(`/api/private/v1/referencing/tenant-request/${requestId}`)
      .catch(this._handleError('Failed to retrieve reference requests'));
  }

  get(requestId) {
    return axios
      .get(`/api/private/v1/referencing/request/${requestId}/`)
      .catch(this._handleError('Failed to retrieve reference request'));
  }

  getNotes(requestId) {
    return axios
      .get(`/api/private/v1/referencing/request/${requestId}/notes/`)
      .catch(this._handleError('Failed to retrieve reference request'));
  }

  addNote(requestId, message) {
    const data = {
      message: message
    };

    return axios
      .post(
        `/api/private/v1/referencing/request/${requestId}/create-note/`,
        data,
        this._getOptions()
      )
      .catch(this._handleError('Failed to create reference request'));
  }

  getStatus(requestId) {
    return axios
      .get(`/api/private/v1/referencing/request/${requestId}/status/`)
      .catch(this._handleError('Failed to retrieve reference request'));
  }

  approve(requestId) {
    const data = {};
    return axios
      .post(`/api/private/v1/referencing/request/${requestId}/approve/`, data, this._getOptions())
      .catch(this._handleError('Failed to approve reference request'));
  }

  approveWithCondition(requestId, description, condition) {
    const data = { description: description, condition: condition };
    return axios
      .post(
        `/api/private/v1/referencing/request/${requestId}/conditionally-approve/`,
        data,
        this._getOptions()
      )
      .catch(this._handleError('Failed to approve reference request'));
  }

  reject(requestId) {
    const data = {};
    return axios
      .post(`/api/private/v1/referencing/request/${requestId}/reject/`, data, this._getOptions())
      .catch(this._handleError('Failed to reject reference request'));
  }

  getForFlatbond(flatbondId) {
    return axios
      .get(`/api/private/v1/referencing/request/tenant/?flatbond_id=${flatbondId}`)
      .catch(this._handleError('Failed to retrieve reference requests'));
  }
}

export class InternalReferenceRequest extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalReferenceRequestError';
  }

  list(query = '') {
    return axios
      .get(`/api/private/v1/referencing/internal/request/${query}`)
      .catch(this._handleError('Failed to retrieve reference requests'));
  }

  get(requestId) {
    return axios
      .get(`/api/private/v1/referencing/internal/request/${requestId}/`)
      .catch(this._handleError('Failed to retrieve reference request'));
  }
}

export class ReferenceResponse extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'ReferenceResponseError';
  }

  update(referenceResponseId, isApproved) {
    const data = {
      is_approved: isApproved
    };

    return axios
      .put(`/api/private/v1/referencing/response/${referenceResponseId}/`, data, this._getOptions())
      .catch(this._handleError('Failed to create reference request'));
  }

  getDownloadLink(responseId) {
    return `/api/private/v1/referencing/response/${responseId}/download-pdf/`;
  }
}

export class InternalReferenceResponse extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalReferenceResponseError';
  }

  getDownloadLink(responseId) {
    return `/api/private/v1/referencing/internal/response/${responseId}/download-pdf/`;
  }
}

export class Deposits extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'DepositsError';
  }

  create(data) {
    return axios
      .post(`/api/private/v1/deposits/`, data, this._getOptions())
      .catch(this._handleError('Failed to create a deposit'));
  }
}

export class MyDeposits extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'MyDepositsError';
  }

  get(importJobId) {
    return axios.get(
      `/api/private/v1/deposits/services/mydeposits/import_jobs/${importJobId}`,
      this._getOptions()
    );
  }
}

export class Properties extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'PropertiesError';
  }

  create(address, postcode, city, country, buildingName, buildingNumber, landlordEmail, managedBy) {
    const data = {
      address: address,
      postcode: postcode,
      city: city,
      country: country,
      building_name: buildingName,
      building_number: buildingNumber,
      landlord_email: landlordEmail,
      managed_by: managedBy
    };

    return axios
      .post(`/api/private/v1/properties/`, data, this._getOptions())
      .catch(this._handleError('Failed to create a property'));
  }

  list(searchQuery) {
    let query = '';
    if (searchQuery) {
      query = `?q=${searchQuery}`;
    }
    return axios
      .get(`/api/private/v1/properties/${query}`)
      .catch(this._handleError('Failed to fetch properties'));
  }

  get(propertyId) {
    return axios
      .get(`/api/private/v1/properties/${propertyId}`)
      .catch(this._handleError('Failed to get a property'));
  }
}

export class PropertyApplication extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'PropertyApplicationError';
  }

  create(propertyId, members, rent, isGuarantor, startDate, duration) {
    const data = {
      property: propertyId,
      members: members,
      rent: rent,
      is_guarantor: isGuarantor,
      start_date: new Moment(startDate).utc().format('YYYY-MM-DD'),
      duration: duration
    };

    return axios
      .post(`/api/private/v1/properties/applications/`, data, this._getOptions())
      .catch(this._handleError('Failed to create a property application'));
  }

  listForProperty(propertyId) {
    return axios
      .get(`/api/private/v1/properties/applications/?property_id=${propertyId}`)
      .catch(this._handleError('Failed to retrieve reference requests'));
  }

  list(query) {
    return axios
      .get(`/api/private/v1/properties/applications/${query}`)
      .catch(this._handleError('Failed to retrieve reference requests'));
  }

  get(id) {
    return axios.get(`/api/private/v1/properties/applications/${id}`);
  }
}

export class PartnerSignup extends Api {
  constructor(vue) {
    super(vue);
    this.errorName = 'PartnerSignupError';
  }

  create(
    fullName,
    phoneNumber,
    email,
    companyName,
    companyNumber,
    companyAddress,
    numberOfUnits,
    numberOfStaff,
    numberOfOffices,
    websiteUrl,
    type
  ) {
    const data = {
      full_name: fullName,
      phone_number: phoneNumber,
      email: email,
      company: {
        name: companyName,
        company_number: companyNumber,
        company_address: companyNumber,
        number_of_units: numberOfUnits,
        number_of_staff: numberOfStaff,
        number_of_offices: numberOfOffices,
        website: websiteUrl,
        type: type
      }
    };

    return axios
      .post(`/api/private/v1/partner/open/signup/`, data, this._getOptions())
      .catch(this._handleError('Failed to sign up, please try again later'));
  }

  get(id) {
    return axios.get(`/api/private/v1/partner/open/signup/${id}/`);
  }

  getInternal(id) {
    return axios.get(`/api/private/v1/partner/internal/signup/${id}/`);
  }

  approve(id) {
    return axios.post(
      `/api/private/v1/partner/internal/signup/${id}/approve/`,
      {},
      this._getOptions()
    );
  }

  reject(id) {
    return axios.post(
      `/api/private/v1/partner/internal/signup/${id}/reject/`,
      {},
      this._getOptions()
    );
  }

  markHandledOffline(id) {
    return axios.post(
      `/api/private/v1/partner/internal/signup/${id}/handled_offline/`,
      {},
      this._getOptions()
    );
  }

  attendedWebinar(id, commission) {
    const data = {
      commission: commission
    };
    return axios.post(
      `/api/private/v1/partner/internal/signup/${id}/attended-webinar/`,
      data,
      this._getOptions()
    );
  }

  getCurrentUsersPartnerSignup() {
    return axios.get(`/api/private/v1/partner/signup/current-user/`);
  }

  completedDocuments() {
    return axios.post(
      `/api/private/v1/partner/signup/complete-documents-step/`,
      {},
      this._getOptions()
    );
  }

  completedOrganization() {
    return axios.post(
      `/api/private/v1/partner/signup/complete-organization-setup-step/`,
      {},
      this._getOptions()
    );
  }

  completedInvites() {
    return axios.post(
      `/api/private/v1/partner/signup/complete-user-invite-step/`,
      {},
      this._getOptions()
    );
  }

  list({ page = 1, pageSize = 10 } = {}) {
    const search = `?page=${encodeURIComponent(page)}&page_size=${encodeURIComponent(pageSize)}`;
    const url = `/api/private/v1/partner/internal/signup/${search}`;
    return axios.get(url);
  }
}
