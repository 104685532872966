import { InternalClaimApiBase } from './base';

export class InternalClaimCaseApi extends InternalClaimApiBase {
  listCasesSuitableForPaymentPlan() {
    return super.get(
      'case/suitable-for-payment-plan',
      'Failed to list cases suitable for payment plan'
    );
  }

  completeReview(id) {
    return super.post(`case/${id}/complete-review/`, {}, `Failed to complete case review ${id}`);
  }

  getClaimsCaseVerification(id) {
    return super.get(`case/${id}/verification/`, `Failed to retrieve case verification  ${id}`);
  }

  updateClaimsCaseChecks(id, verificationId, checklist) {
    const data = checklist.reduce(
      (acc, { key, value }) => {
        acc[key] = value;
        return acc;
      },
      { case_id: id }
    );

    return super.put(
      `case/${id}/verification/${verificationId}/`,
      data,
      `Failed to update case verification ${id}`
    );
  }
}
