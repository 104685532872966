import { InternalEventApiBase } from './base';

export class InternalEventNotesApi extends InternalEventApiBase {
  create(namespace, note, type, verificationIssue, model) {
    let data = {
      note: note,
      type: type,
      verification_issue: verificationIssue
    };
    data[`${namespace}_id`] = model;

    return super.post('notes/', data, 'Failed to create note');
  }
}
