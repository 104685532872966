export default {
  props: {
    errors: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
