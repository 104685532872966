import { formatUtcDateForDisplayWithTime } from 'libs/utils/helpers';

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDate() {
      return formatUtcDateForDisplayWithTime(this.value);
    }
  }
};
