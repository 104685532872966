<template>
  <fp-input-hint v-if="errorMessage" error data-testid="validations-hint">
    {{ errorMessage }}
  </fp-input-hint>
</template>

<script>
import { logMessageOnSentry } from '@/libs/utils/helpers';
import { Hint } from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    'fp-input-hint': Hint
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    errorMessage: function() {
      switch (true) {
        case this.config.required === false:
          return 'This field is required';
        case this.config.email === false:
          return 'Please enter a valid email';
        case this.config.integer === false:
          return 'Please enter a number';
        case this.config.url === false:
          return 'Please enter a valid url';
        case this.config.minLength === false:
          return `The minimum length is ${this.config.$params.minLength.min}`;
        case this.config.maxLength === false:
          return `The maximum length is ${this.config.$params.maxLength.max}`;
        case this.config.numeric === false:
          return 'The value must be a number';
        case this.config.minValue === false:
          return `The minimum value is ${this.config.$params.minValue.min}`;
        case this.config.validPostcode === false:
          return 'Please enter a valid UK postcode';
        case this.config.between === false:
          return `The value must be between ${this.config.$params.between.min} and
          ${this.config.$params.between.max}`;
        case this.config.checked === false:
          return this.$t(this.config.$params.checked.message);
        case this.config.$error === true:
          logMessageOnSentry(
            'Unhandled validation for one of this: ' +
              Object.keys(this.config)
                .filter(x => x.startsWith('$') === false)
                .join(',')
          );
          return 'Something is wrong with this field';
        default:
          return '';
      }
    }
  }
};
</script>
