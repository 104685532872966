import BoxRounded from 'elements/box_rounded/box_rounded.vue';
import Table from 'elements/table/table.vue';
import VueElementLoading from 'vue-element-loading';
import ReferenceRequestProgress from 'components/apps/referencing/reference_request/reference_request_progress/reference_request_progress.vue';
import ReferenceRequestDownload from 'components/apps/referencing/reference_request/reference_request_download/reference_request_download.vue';
import ReferenceRequestGuarantorForm from 'components/apps/referencing/reference_request/reference_request_guarantor_form/reference_request_guarantor_form.vue';
import ReferenceRequestStatus from 'components/apps/referencing/reference_request/reference_request_status/reference_request_status.vue';
import { getReferenceConditions } from 'libs/utils/referencing';

export default {
  inject: {
    referenceRequestApi: {
      default: null
    },
    referenceResponseApi: {
      default: null
    }
  },
  props: {
    csrf: {
      type: String,
      required: true
    },
    applicant: {
      type: Object,
      required: true
    },
    rent: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.detectDescriptionSize();
    window.addEventListener('resize', this.detectDescriptionSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectDescriptionSize);
  },
  data() {
    return {
      description: '',
      loading: false,
      progress: null,
      guarantorFormIsOpen: false
    };
  },
  computed: {
    responseId() {
      if (this.hasResponse) {
        return this.applicant.reference.response.id;
      }
      return 0;
    },
    decisionDescription() {
      if (
        !this.applicant.reference.referencedecision ||
        !this.applicant.reference.referencedecision.description
      ) {
        return;
      }
      return this.applicant.reference.referencedecision.description;
    },
    hasResponse() {
      return !!(this.applicant.reference && this.applicant.reference.response);
    },
    isGuarantor() {
      return this.applicant.reference.is_guarantor && this.applicant.guarantor_for !== null;
    },
    referenceTitle() {
      if (this.guarantorFormIsOpen) {
        return `Add a guarantor for ${this.userEmail}`;
      }
      if (this.isGuarantor) {
        return `Guarantor for ${this.applicant.guarantor_for.user_email}`;
      }
      return `Reference result for ${this.userEmail}`;
    },
    referenceData() {
      return getReferenceConditions(this.applicant.reference);
    },
    userEmail() {
      return this.applicant.reference ? this.applicant.reference.user_email : ``;
    }
  },
  methods: {
    detectDescriptionSize() {
      if (this.showFullDescription === false) {
        this.descriptionIsOverflowing =
          this.$refs.descriptionText.scrollHeight > this.$refs.descriptionText.clientHeight;
      }
    },
    collapseDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    onGuarantorSuccess() {
      this.refreshGuarantors();
      this.guarantorFormIsOpen = false;
    },
    refreshGuarantors() {
      this.$emit('refresh-guarantors');
    },
    openGuarantorForm() {
      this.guarantorFormIsOpen = true;
    },
    closeGuarantorForm() {
      this.guarantorFormIsOpen = false;
    }
  },
  components: {
    'v-table': Table,
    'box-rounded': BoxRounded,
    'vue-element-loading': VueElementLoading,
    'reference-request-download': ReferenceRequestDownload,
    'reference-request-guarantor-form': ReferenceRequestGuarantorForm,
    'reference-request-progress': ReferenceRequestProgress,
    'reference-request-status': ReferenceRequestStatus
  }
};
