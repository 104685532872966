import ReferenceRequestResult from 'components/apps/referencing/reference_request/reference_request_result/reference_request_result.vue';
import ReferenceRequestNotes from 'components/apps/referencing/reference_request/reference_request_notes/reference_request_notes.vue';
import ReferenceApplicationTenantList from 'components/apps/referencing/reference_application/reference_application_detail/reference_application_tenant_list/reference_application_tenant_list.vue';
import ReferenceApplicationGuarantorList from 'components/apps/referencing/reference_application/reference_application_detail/reference_application_guarantor_list/reference_application_guarantor_list.vue';
import BoxRounded from 'elements/box_rounded/box_rounded.vue';
import {
  REFERENCING_REQUEST_STATUSES,
  PROPERTY_APPLICATION_STATUSES
} from 'libs/constants/generatedConstants';
import { FlatfairConstant } from 'libs/flatfairConstant';
import { TitleBarMixin } from 'libs/mixins/titleBarMixin';
import {
  DANGER_TEXT,
  MOVEM_SUGGESTED_DECISIONS,
  REFERENCE_APPLICATION_STATUSES,
  REFERENCE_REQUEST_STATUS,
  SUCCESS_TEXT
} from 'libs/utils/constants';
import {
  convertWeeklyToMonthlyRent,
  convertWeeklyToMonthlyRentInUnits,
  formatCurrencyFromCents
} from 'libs/utils/helpers';
import { determineApplicationStatus, sumAllApplicantsAffordability } from 'libs/utils/referencing';

function addToQueryParam(name, value, queryString) {
  if (!queryString) {
    return `?${name}=${value}`;
  }
  return `${queryString}&${name}=${value}`;
}

function getFlatbondParameters(application) {
  const property = application.property;

  let queryString = '';
  queryString = addToQueryParam('address', property.address, queryString);
  queryString = addToQueryParam('postcode', property.postcode, queryString);
  queryString = addToQueryParam('city', property.city, queryString);
  queryString = addToQueryParam('country', property.country, queryString);
  queryString = addToQueryParam('rent', application.rent / 100, queryString);
  queryString = addToQueryParam('rent_period', 'week', queryString);
  queryString = addToQueryParam('rent_currency', property.rent_currency, queryString);
  queryString = addToQueryParam('application', application.id, queryString);
  queryString = addTenantParameters(application, queryString);

  return queryString;
}

function addTenantParameters(application, queryString) {
  application.applicants.forEach(
    (applicant, index) =>
      (queryString = addToQueryParam(`&tenant${index + 1}`, applicant.user_email, queryString))
  );
  return queryString;
}

export default {
  mounted() {
    this.listenForPrimaryActionClick(this.backToList);
    return this._requestApplication();
  },
  beforeRouteLeave(to, from, next) {
    this.setTitleBarIconElement('');
    next();
  },
  inject: {
    propertyApplicationApi: {
      default: null
    }
  },
  props: {
    csrf: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  mixins: [TitleBarMixin],
  data() {
    return {
      application: { property: '', reference: {} },
      applicationStatusConstant: new FlatfairConstant(REFERENCE_APPLICATION_STATUSES),
      referenceStatusConstant: new FlatfairConstant(REFERENCE_REQUEST_STATUS),
      refetchNotes: 0,
      movemDecisionConstant: new FlatfairConstant(MOVEM_SUGGESTED_DECISIONS),
      loading: false,
      selectedReference: null
    };
  },
  computed: {
    applicationTitle() {
      const statusLabel = this.applicationStatusConstant.getLabel(this.application.status);
      return this.application.id ? `#${this.application.id} - ${statusLabel}` : ``;
    },
    applicationStatusText() {
      return this.application ? determineApplicationStatus(this.application) : '';
    },
    applicationStatusIconElement() {
      const statusColour = this.applicationStatusConstant.getColour(this.application.status);
      const statusIcon = this.applicationStatusConstant.getIcon(this.application.status);

      return `
        <div class="status-bubble-large ${statusColour}">
          <i class="fa fa-fw fa-2x ${statusIcon}"></i>
        </div>
      `;
    },
    agentFullName() {
      return this.application.created_by ? this.application.created_by.full_name : ``;
    },
    affordabilityColourClass() {
      const monthlyRent = convertWeeklyToMonthlyRentInUnits(this.application.rent);
      return this.totalAffordability > monthlyRent ? SUCCESS_TEXT : DANGER_TEXT;
    },
    hasGuarantors() {
      return this.application.guarantors && this.application.guarantors.length > 0;
    },
    propertyAddress() {
      if (this.application && this.application.property) {
        return `${this.application.property.address}, ${this.application.property.city}, ${this.application.property.postcode}`;
      }
      return ``;
    },
    referenceApplicants() {
      return this.application && this.application.applicants ? this.application.applicants : [];
    },
    referenceIsBeingCreated() {
      return (
        this.application.status === PROPERTY_APPLICATION_STATUSES.CREATING ||
        this.application.applicants.find(
          applicant => applicant.reference.status === REFERENCING_REQUEST_STATUSES.CREATING
        )
      );
    },
    isLoading() {
      return !this.referenceIsBeingCreated && this.loading;
    },
    rent() {
      if (this.application && this.application.rent) {
        const monthlyRent = convertWeeklyToMonthlyRent(this.application.rent);
        return formatCurrencyFromCents(monthlyRent);
      }
      return '£ -';
    },
    totalAffordability() {
      return this.application.applicants
        ? sumAllApplicantsAffordability(this.application.applicants)
        : null;
    },
    totalAffordabilityFormatted() {
      return typeof this.totalAffordability === 'number'
        ? formatCurrencyFromCents(this.totalAffordability)
        : '£ -';
    }
  },
  methods: {
    backToList() {
      this.$router.push('/');
    },
    async _requestApplication() {
      this.loading = true;

      if (this.propertyApplicationApi) {
        try {
          const response = await this.propertyApplicationApi.retrieve(parseInt(this.id));
          this.application = response.data;
          this.updateTitleBarData();
          this.checkIfNeedsToReload();
        } finally {
          this.loading = false;
        }
      }
    },
    createFlatbond() {
      window.location = `/flatbonds/create${getFlatbondParameters(this.application)}`;
    },
    setCurrentReference(reference) {
      this.selectedReference = reference;
    },
    preselectFirstApplicant(application) {
      if (application.applicants && application.applicants.length === 1) {
        this.referenceSelected(application.applicants[0]);
      }
    },
    checkIfNeedsToReload() {
      if (this.referenceIsBeingCreated) {
        setTimeout(() => {
          this.refetchNotes++;
          this._requestApplication();
        }, 5000);
      }
    },
    updateTitleBarData() {
      this.setTitleBarTitle(this.applicationTitle);
      this.setTitleBarSubtitle(this.applicationStatusText);
      this.setTitleBarIconElement(this.applicationStatusIconElement);
      this.setTitleBarButtonClasses(['flatfair-button-outline', 'primary']);
      this.setTitleBarButtonText('Back to all applications');
    }
  },
  components: {
    'box-rounded': BoxRounded,
    'application-tenant-list': ReferenceApplicationTenantList,
    'application-guarantor-list': ReferenceApplicationGuarantorList,
    'reference-request-result': ReferenceRequestResult,
    'reference-request-notes': ReferenceRequestNotes
  }
};
