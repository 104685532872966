<template>
  <FpImage
    :maxHeight="maxHeight"
    :maxWidth="maxWidth"
    :fillHorizontal="fillHorizontal"
    :fillVertical="fillVertical"
    :alignTop="alignTop"
    :alignRight="alignRight"
    :alignBottom="alignBottom"
    :alignLeft="alignLeft"
    data-testid="image"
  >
    <img :src="src" alt="alt" />
  </FpImage>
</template>

<script>
import { FpImage } from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FpImage
  },
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: null
    },
    fillHorizontal: {
      type: Boolean,
      default: false
    },
    fillVertical: {
      type: Boolean,
      default: false
    },
    alignTop: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    alignBottom: {
      type: Boolean,
      default: false
    },
    alignLeft: {
      type: Boolean,
      default: false
    }
  }
};
</script>
