<template>
  <sc-theme-provider :theme="defaultTheme">
    <slot></slot>
  </sc-theme-provider>
</template>
<script>
import { defaultTheme, ThemeProvider } from '@flatfair/vue-fairplay/atoms';
import '@fortawesome/fontawesome-pro/css/light.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/solid.css';

export default {
  components: {
    'sc-theme-provider': ThemeProvider
  },
  data() {
    return { defaultTheme };
  }
};
</script>
