import Cookies from 'js-cookie';

const defaultLocale = 'en-GB';

export const FEATURE_CONVERSION_FLATBOND = 'conversion_flatbond';
export const FEATURE_DRAFT_FLATBOND = 'draft_flatbond';
export const FEATURE_REFERENCING = 'referencing';
export const FEATURE_DEPOSITS = 'deposits';

export default {
  user: window._flatfairContext && window._flatfairContext.user,
  csrfToken: Cookies.get('csrftoken') || '',
  publicPath: window._flatfairContext && window._flatfairContext.publicPath,
  features: new Set((window._flatfairContext && window._flatfairContext.features) || []),
  locale: (window._flatfairContext && window._flatfairContext.locale) || defaultLocale,

  featureEnabled(feature) {
    return this.features.has(feature);
  }
};
