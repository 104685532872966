<template>
  <FpModalBackdrop data-testid="modal-backdrop">
    <FpModalBox data-testid="modal-box">
      <FpModalContent data-testid="modal-content">
        <slot name="body" data-testid="slot-body"></slot>
      </FpModalContent>
      <FpModalActions data-testid="modal-actions">
        <FpButtons>
          <FpButton @click="$emit('cancelButtonClick')" secondary data-testid="modal-cancel-button">
            <FpButtonText>
              {{ cancelButtonText }}
            </FpButtonText>
          </FpButton>
          <FpButton @click="$emit('confirmButtonClick')" data-testid="modal-confirm-button">
            <FpButtonText>
              {{ confirmButtonText }}
            </FpButtonText>
          </FpButton>
        </FpButtons>
      </FpModalActions>
    </FpModalBox>
  </FpModalBackdrop>
</template>

<script>
import {
  FpButton,
  FpButtons,
  FpButtonText,
  FpModalActions,
  FpModalBackdrop,
  FpModalBox,
  FpModalContent
} from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FpModalBackdrop,
    FpModalBox,
    FpModalContent,
    FpModalActions,
    FpButtons,
    FpButton,
    FpButtonText
  },
  props: {
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    }
  }
};
</script>
