import { InternalFlatbondApiBase } from './base';

export class InternalFlatbondAdministratorApi extends InternalFlatbondApiBase {
  list(flatbondId) {
    return super.get(
      `administrator/?flatbond__id=${flatbondId}&page_size=50`,
      'Failed to list the flatbond managers'
    );
  }

  updateSingle(flatbondId, organizationUserId) {
    const payload = {
      flatbond_id: flatbondId,
      organization_user_id: organizationUserId
    };

    return super.put(
      `administrator/update-single/`,
      payload,
      'Failed to update the manager for this flatbond'
    );
  }

  updateMultiple(flatbondId, organizationUserId) {
    const payload = {
      flatbond_id: flatbondId,
      organization_user_id: organizationUserId
    };

    return super.put(
      `administrator/update-multiple/`,
      payload,
      'Failed to update the manager for all their flatbonds'
    );
  }
}
