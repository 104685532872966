import { InternalTenantApiBase } from './base';

export class InternalTenantApi extends InternalTenantApiBase {
  list(query = '') {
    return super.get(`${query}`, 'Failed to list tenants');
  }

  get(tenantId) {
    return super.get(`${tenantId}`, 'Failed to get tenant');
  }

  updateDetails(tenantId, tenant) {
    const data = {
      first_name: tenant.firstName,
      last_name: tenant.lastName,
      invite_email: tenant.email
    };
    return super.put(`${tenantId}/`, data, 'Failed to update tenant details');
  }

  disablePaymentNameChecking(tenantId) {
    return super.put(
      `${tenantId}/disable-payment-name-checking/`,
      {},
      'Failed to disable payment name checking'
    );
  }

  enablePaymentNameChecking(tenantId) {
    return super.put(
      `${tenantId}/enable-payment-name-checking/`,
      {},
      'Failed to enable payment name checking'
    );
  }
}
