import BoxRounded from 'elements/box_rounded/box_rounded.vue';
import DropdownSelect from 'elements/dropdown_select/dropdown_select.vue';
import SearchInput from 'elements/search_input/search_input.vue';
import Table from 'elements/table/table.vue';
import { FlatfairConstant } from 'libs/flatfairConstant';
import { TableMixin } from 'libs/mixins/tableMixin';
import { TitleBarMixin } from 'libs/mixins/titleBarMixin';
import { REFERENCE_APPLICATION_STATUSES, REFERENCE_REQUEST_STATUS } from 'libs/utils/constants';
import { buildQuery } from 'libs/utils/helpers';
import { TableDefinition } from 'libs/tableDefinition';

export default {
  mounted() {
    this.updateTitleBarData();
    this.listenForPrimaryActionClick(this._goToCreateApplicationForm);
    return this._fetchData();
  },
  inject: {
    propertyApplicationApi: {
      default: null
    }
  },
  props: {
    csrf: {
      type: String,
      required: true
    },
    flatbondId: {
      type: Number,
      required: false
    },
    eventBus: {
      type: Object,
      required: true
    }
  },
  mixins: [TableMixin, TitleBarMixin],
  data() {
    return {
      applicationStatusConstant: new FlatfairConstant(REFERENCE_APPLICATION_STATUSES),
      propertyApplicationsData: { columns: {}, rows: [] },
      loading: false,
      filterOptions: REFERENCE_REQUEST_STATUS,
      queryFilter: ''
    };
  },
  watch: {
    queryFilter: {
      handler() {
        this.query.applicant__reference__status = this.queryFilter;
        this.onSearch();
      }
    }
  },
  methods: {
    onSearch() {
      this.propertyApplicationsData.rows = [];
      this.query.page = 1;
      this.$router.push({ query: { page: '1' } });
      this.clearPagination();
      this.$nextTick(() => {
        this._fetchData();
      });
    },
    applicationSelected(application) {
      this.$router.push({
        name: 'property-application-detail',
        params: {
          id: `${application.id}`
        }
      });
    },
    _goToCreateApplicationForm() {
      this.$router.push(`/new-reference`);
    },
    async _fetchData() {
      this.loading = true;

      if (this.propertyApplicationApi) {
        try {
          const { data } = await this.propertyApplicationApi.list(buildQuery(this.query));
          this.updatePagination(data);
          this._assemblePropertyApplications(this.pagination.data);
        } finally {
          this.loading = false;
        }
      }
    },
    updateTitleBarData() {
      this.setTitleBarTitle('Referencing');
      this.setTitleBarSubtitle('You can create and review reference applications here.');
      this.setTitleBarButtonText('Create a new application');
      this.setTitleBarButtonClasses(['flatfair-button', 'primary']);
    },
    _assemblePropertyApplications: function(applicationData) {
      this.propertyApplicationsData = new TableDefinition()
        .setEmptyMessage('No property applications found.')
        .addSimpleRow('Application ID', application => `# ${application.id}`)
        .addSimpleRow(
          'Address',
          application => `${application.property.address}, ${application.property.postcode}`
        )
        .addSimpleRow('Lead tenant', application => {
          return application.applicants && application.applicants.length > 0
            ? application.applicants[0].user_email
            : 'No applicant found';
        })
        .addLabel(
          'Status',
          application => this.applicationStatusConstant.getLabel(application.status),
          application => {
            return {
              colourClass: this.applicationStatusConstant.getColour(application.status)
            };
          }
        )
        .addAction(
          'Details',
          () => 'Details',
          application => {
            return {
              action: this.applicationSelected.bind(null, application)
            };
          }
        )
        .build(applicationData);
    }
  },
  components: {
    'v-table': Table,
    'box': BoxRounded,
    'search-input': SearchInput,
    'dropdown-select': DropdownSelect
  }
};
