import AddressFinder from 'components/address_finder/address_finder.vue';
import { Input } from '@/fairplay';
import Validations from '@/elements/validations/validations';
import { SectionRow } from '@/elements/section_row/section_row';

export default {
  props: {
    address: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    },
    backendValidations: {
      type: Object,
      required: true
    }
  },
  methods: {
    onSelectCompanyAddress(selectedAddress) {
      this.address.line_1 = selectedAddress.line_1;
      this.address.line_2 = selectedAddress.line_2;
      this.address.city = selectedAddress.town_or_city;
      this.address.postcode = selectedAddress.postcode;
    }
  },
  components: {
    'address-finder': AddressFinder,
    'ff-text-input': Input,
    'ff-validations': Validations,
    'section-row': SectionRow
  }
};
