import { InternalSelfBillingApiBase } from './base';

export class InternalSelfBillingApi extends InternalSelfBillingApiBase {
  getOrCreate(groupId) {
    return super.get(
      `billing-details/get-or-create?organisation_group=${groupId}`,
      'Could not retrieve self-billing details'
    );
  }

  updateAddress(data) {
    return super.put(`address/${data.id}/`, data, 'Could not update address');
  }

  updateBankAccount(data) {
    return super.put(`bank-account/${data.id}/`, data, 'Could not update bank account');
  }

  updateBillingDetails(data) {
    return super.put(`billing-details/${data.id}/`, data, 'Could not update self-billing details');
  }

  updateAdministrator(billingDetailsId, newAdministratorId) {
    const payload = { organization_user_id: newAdministratorId || null };
    return super.put(
      `billing-details/${billingDetailsId}/administrator/`,
      payload,
      'Could not update self-billing administrator'
    );
  }

  requestReview(billingDetailsId) {
    return super.post(
      `billing-details/${billingDetailsId}/request-review/`,
      null,
      'Could not request review'
    );
  }

  approve(billingDetailsId) {
    return super.post(
      `billing-details/${billingDetailsId}/approve/`,
      null,
      'Could not approve self-billing details'
    );
  }
}
