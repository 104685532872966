import { REFERENCING_REQUEST_STATUSES } from 'libs/constants/generatedConstants';
import { FlatfairConstant } from 'libs/flatfairConstant';
import {
  MOVEM_INDIVIDUAL_CHECK_RESULTS,
  MOVEM_SUGGESTIONS,
  REFERENCE_APPLICATION_STATUSES,
  REFERENCE_STATUS_CANCELLED,
  REFERENCE_STATUS_INVITED,
  REFERENCE_STATUS_IN_PROGRESS,
  REFERENCE_STATUS_PENDING_REVIEW,
  REFERENCE_SUGGESTIONS
} from 'libs/utils/constants';
import {
  calculateAffordability,
  formatCurrencyFromCents,
  convertWeeklyToMonthlyRent
} from 'libs/utils/helpers';

function _getStatusFrom(result, name) {
  let status = {};
  if (!result || !(result.result in MOVEM_INDIVIDUAL_CHECK_RESULTS)) {
    return status;
  }

  const referenceStatus = MOVEM_INDIVIDUAL_CHECK_RESULTS[result.result];
  status.value = referenceStatus.value;
  status.symbolClass = referenceStatus.symbolClass;
  status.colourClass = referenceStatus.colourClass;

  status.status = result.message;
  status.name = name;

  return status;
}

function _getIncomeStatus(rent, salary, affordabilityMultiplier) {
  let status = {};
  const affordability = calculateAffordability(salary, affordabilityMultiplier);
  const rentPerMonth = convertWeeklyToMonthlyRent(rent);
  if (affordability > rentPerMonth) {
    status['symbolClass'] = 'fa-check';
    status['colourClass'] = 'text-flatfair-success';
    status['status'] = `Sufficient income reported: affordability of ${formatCurrencyFromCents(
      affordability
    )} to cover monthly rent of ${formatCurrencyFromCents(rentPerMonth)}`;
  } else {
    status['symbolClass'] = 'fa-close';
    status['colourClass'] = 'text-flatfair-danger';
    status['status'] = `Insufficient income reported: affordability of ${formatCurrencyFromCents(
      affordability
    )} to cover monthly rent of ${formatCurrencyFromCents(rentPerMonth)}`;
  }

  status['name'] = 'Income';

  return status;
}

export function getReferenceConditions(referenceData) {
  if (
    referenceData.status === REFERENCE_STATUS_INVITED ||
    referenceData.status === REFERENCE_STATUS_IN_PROGRESS ||
    referenceData.status === REFERENCE_STATUS_CANCELLED ||
    referenceData.status === REFERENCE_STATUS_PENDING_REVIEW
  ) {
    return [];
  }
  let referenceConditions = [];
  const summary = referenceData.response.summary;

  referenceConditions.push(_getStatusFrom(summary['address'], 'Address verification'));
  referenceConditions.push(_getStatusFrom(summary['identity_summary'], 'Identity verification'));
  referenceConditions.push(_getStatusFrom(summary['bankruptcies'], 'Adverse credit'));
  if ('judgments' in summary) {
    referenceConditions.push(_getStatusFrom(summary['judgments'], 'Judgments'));
  }
  referenceConditions.push(_getStatusFrom(summary['incomes'], 'Income verification'));
  referenceConditions.push(_getStatusFrom(summary['expenses'], 'Rental payments'));
  referenceConditions.push(
    _getIncomeStatus(
      referenceData.rent,
      referenceData.response.salary,
      referenceData.affordability_multiplier
    )
  );

  return referenceConditions;
}

export function determineReferenceOutcome(reference) {
  if (reference.response) {
    const affordability = calculateAffordability(
      reference.response.salary,
      reference.affordability_multiplier
    );
    const canAffordRent = affordability > reference.rent;
    let movemDecision = reference.response.decision;
    movemDecision = MOVEM_SUGGESTIONS.PASSED;

    if (movemDecision === MOVEM_SUGGESTIONS.PASSED && canAffordRent) {
      return REFERENCE_SUGGESTIONS.ACCEPTABLE;
    }

    if (movemDecision === MOVEM_SUGGESTIONS.PASSED && !canAffordRent) {
      return REFERENCE_SUGGESTIONS.ACCEPTABLE_WITH_CONDITION;
    }

    if (
      movemDecision === MOVEM_SUGGESTIONS.FAILED ||
      movemDecision === MOVEM_SUGGESTIONS.MANUAL_FAILED
    ) {
      return REFERENCE_SUGGESTIONS.NOT_ACCEPTABLE;
    }
  }

  return REFERENCE_SUGGESTIONS.PENDING;
}

export function determineApplicationStatus(application) {
  const applicationStatus = new FlatfairConstant(REFERENCE_APPLICATION_STATUSES);
  if (application.status === REFERENCING_REQUEST_STATUSES.IN_PROGRESS) {
    return getProgressOfApplicantsText(application.applicants);
  } else {
    return applicationStatus.getBody(application.status);
  }
}

export function getProgressOfApplicantsText(applicants) {
  const numberOfApplicants = applicants.length;
  const numberOfCompletedReferences = applicants.filter(
    applicant => applicant.reference.status === REFERENCING_REQUEST_STATUSES.COMPLETED
  ).length;

  return `${numberOfCompletedReferences} of ${numberOfApplicants} applicants have completed the referencing process. Once all references are completed, you will be able to make a decision.`;
}

export function sumAllApplicantsAffordability(applicants) {
  const filteredApplicants = applicants.filter(applicant => applicant.reference.response);
  if (!filteredApplicants.length) return null;
  return filteredApplicants.reduce(
    (total, applicant) =>
      total +
      calculateAffordability(
        applicant.reference.response.salary,
        applicant.reference.affordability_multiplier
      ),
    0
  );
}
