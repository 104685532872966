import {
  REFERENCE_REQUEST_STATUS,
  TENANT_REFERENCE_REQUEST_STATUS,
  REFERENCE_REQUEST_DEFAULT_STATUS
} from 'libs/utils/constants';

export const getReferenceStatusObject = value => {
  const status = REFERENCE_REQUEST_STATUS.find(status => value === status.value);
  if (status) {
    return status;
  }
  return REFERENCE_REQUEST_DEFAULT_STATUS;
};

export const getReferenceStatusLabel = value => {
  const status = REFERENCE_REQUEST_STATUS.find(status => value === status.value);
  if (status) {
    return status.label;
  }
  return REFERENCE_REQUEST_DEFAULT_STATUS;
};

export const getTenantReferenceStatusDisplay = value => {
  const status = TENANT_REFERENCE_REQUEST_STATUS.find(status => value === status.value);
  if (status) {
    return status.label;
  }
  return '';
};

export const getReferenceStatusClass = value => {
  const status = REFERENCE_REQUEST_STATUS.find(status => value === status.value);
  if (status) {
    return status.colourClass;
  }
  return 'grey-400';
};
