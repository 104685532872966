import { InternalLandlordApiBase } from './base';
import { formatDateForApi } from '@/libs/utils/helpers';

export class InternalLandlordApi extends InternalLandlordApiBase {
  get(landlordId) {
    return super.get(`${landlordId}`, 'Failed to get landlord');
  }

  update({ landlordId, address, city, postcode, country, dateOfBirth, nationality }) {
    const data = {
      address: address,
      city: city,
      postcode: postcode,
      country: country,
      date_of_birth: formatDateForApi(dateOfBirth),
      nationality: nationality || ''
    };
    return super.put(`${landlordId}/`, data, 'Failed to update landlord');
  }
}
