<template>
  <FpFlowStepWrapper>
    <FpFlowStepNav class="flow-step__nav" mobile-only>
      <FfBreadcrumb
        v-if="!hideBackButton"
        @click="$emit('previousActionClick')"
        label="Back"
        data-testid="nav-back-button"
      />
      <FpLink
        v-if="skipActionLabel"
        @click="$emit('skipActionClick')"
        data-testid="nav-skip-button"
      >
        <FpLinkText thin>{{ skipActionLabel }}</FpLinkText>
      </FpLink>
    </FpFlowStepNav>
    <FfProgressBar
      v-if="showProgressBar"
      :percentage="progress"
      data-testid="progress-bar"
      desktop-only
    />
    <FpFlowStep class="flow-step" data-testid="flow-step-wrapper">
      <FpFlowStepMain class="flow-step__main">
        <FpFlowStepPercentage v-if="showProgressBar" desktop-only>
          {{ progress }}%
        </FpFlowStepPercentage>
        <FpFlowStepContent ref="flowStepForm" class="flow-step__form">
          <slot name="form" />
          <FpDevice v-if="mainActionVisible" mobile-only>
            <FpGrid>
              <FpGridColumn>
                <FpButton
                  :loading="processingMainAction"
                  @click="$emit('mainActionClick')"
                  :disabled="mainActionDisabled"
                  fluid
                  large
                  data-testid="mobile-action-next-button"
                >
                  {{ mainActionLabel }}
                </FpButton>
              </FpGridColumn>
            </FpGrid>
          </FpDevice>
        </FpFlowStepContent>
        <FpFlowStepActions class="flow-step__actions" desktop-only>
          <FfBreadcrumb
            v-if="!hideBackButton"
            @click="$emit('previousActionClick')"
            label="Back"
            data-testid="action-back-button"
          />
          <FpButton
            :loading="processingMainAction"
            @click="$emit('mainActionClick')"
            v-if="mainActionVisible"
            :disabled="mainActionDisabled"
            large
            data-testid="action-next-button"
          >
            {{ mainActionLabel }}
          </FpButton>
          <FpFlowStepSkipAction v-if="skipActionLabel">
            <FpLink @click="$emit('skipActionClick')" data-testid="action-skip-button">
              <FpLinkText>{{ skipActionLabel }}</FpLinkText>
            </FpLink>
          </FpFlowStepSkipAction>
        </FpFlowStepActions>
      </FpFlowStepMain>
      <FpFlowStepAside v-if="!hideRightColumn" :style="featureStyle" class="flow-step__aside">
        <FpFLowStepAsideTooltip>
          <slot name="right" />
        </FpFLowStepAsideTooltip>
      </FpFlowStepAside>
    </FpFlowStep>
  </FpFlowStepWrapper>
</template>

<script>
import FfBreadcrumb from '@/fairplay/breadcrumb';
import { FpButton } from '@flatfair/vue-fairplay/molecules';
import FfProgressBar from '@/fairplay/progress_bar';
import {
  FpDevice,
  FpFlowStep,
  FpFlowStepActions,
  FpFlowStepAside,
  FpFLowStepAsideTooltip,
  FpFlowStepContent,
  FpFlowStepMain,
  FpFlowStepNav,
  FpFlowStepPercentage,
  FpFlowStepSkipAction,
  FpFlowStepWrapper,
  FpGrid,
  FpGridColumn,
  FpLink,
  FpLinkText
} from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FfBreadcrumb,
    FpButton,
    FpLink,
    FpLinkText,
    FfProgressBar,
    FpFlowStepWrapper,
    FpFlowStep,
    FpFlowStepMain,
    FpFlowStepAside,
    FpFLowStepAsideTooltip,
    FpFlowStepActions,
    FpFlowStepContent,
    FpFlowStepNav,
    FpFlowStepSkipAction,
    FpGrid,
    FpGridColumn,
    FpFlowStepPercentage,
    FpDevice
  },
  props: {
    hideBackButton: {
      type: Boolean,
      default: false
    },
    mainActionLabel: {
      type: String,
      default: 'Next'
    },
    skipActionLabel: {
      type: String,
      default: ''
    },
    progress: {
      type: Number,
      default: -1
    },
    processingMainAction: {
      type: Boolean,
      default: false
    },
    mainActionVisible: {
      type: Boolean,
      default: true
    },
    mainActionDisabled: {
      type: Boolean,
      default: false
    },
    hideRightColumn: {
      type: Boolean,
      default: false
    },
    featureStyle: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    showProgressBar() {
      return this.progress !== undefined && this.progress >= 0 && this.progress <= 100;
    }
  },
  mounted: function() {
    this.$on('mainScrollTop', this.onScrollTopMain);
  },
  methods: {
    onScrollTopMain() {
      this.$refs.flowStepForm.$el.scrollTop = 0;
    }
  }
};
</script>
