import { Input } from '@/fairplay';
import Validations from '@/elements/validations/validations';
import { SectionRow } from '@/elements/section_row/section_row';
import { TrackMixin } from '@/libs/mixins';

export default {
  props: {
    formValues: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      required: true
    },
    backendValidations: {
      type: Object,
      required: true
    }
  },
  mixins: [TrackMixin],
  watch: {
    'formValues.vat_registered': function() {
      if (this.formValues.vat_registered === false) {
        this.formValues.vat_number = '';
      }
    }
  },
  components: {
    'ff-text-input': Input,
    'ff-validations': Validations,
    'section-row': SectionRow
  }
};
