import { Body1 } from '@/elements/headings';

export const SectionRow = {
  props: {
    label: {
      type: String,
      required: true
    },
    description: String
  },
  template: `
  <div class="section-container">
    <div class="row">
      <div class="col-md-6">
        <body1>{{ label }}</body1>
        <p class="sc-Body2" v-if="description">{{ description }}</p>
      </div>
      <span class="col-md-6"><slot /></span>
    </div>
  </div>
  `,
  components: {
    body1: Body1
  }
};
