<template>
  <FpCheckbox :disabled="disabled" :indeterminate="indeterminate" :tabindex="tabindex">
    <input
      :id="id"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      :error="error"
      :tabindex="-1"
      @change="onChange($event)"
      type="checkbox"
      data-testid="checkbox-input"
    />
    <FpCheckboxLabel :tabindex="-1">
      <slot />
    </FpCheckboxLabel>
  </FpCheckbox>
</template>

<script>
import { FpCheckbox, FpCheckboxLabel } from '@flatfair/vue-fairplay/atoms';
import _camelCase from 'lodash/camelCase';

export default {
  components: {
    FpCheckbox,
    FpCheckboxLabel
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Boolean, Number],
      default: ''
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: -1
    }
  },
  computed: {
    id() {
      const { name, value } = this;
      return `${_camelCase(name)}_${_camelCase(value)}`;
    }
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
    }
  }
};
</script>
