import Box from 'elements/box/box.vue';
import { PartnerSignup, OnboardingDocument } from 'libs/api';
import { getStaticUrlFromPlaceholder, setPageTitle, showNotification } from 'libs/utils/helpers';
import { BRANCH_CREATION_PAGE } from '../routes';

export default {
  mounted: function() {
    this.fetchDocuments();
    if (this.isOnboarding) {
      setPageTitle(
        'Follow these steps for successful onboarding',
        'Download the documents and follow the steps bellow.'
      );
    } else {
      setPageTitle('Onboarding documents', 'Download the documents and follow the steps bellow.');
    }
  },
  data: function() {
    return {
      documentsApi: new OnboardingDocument(this),
      partnerApi: new PartnerSignup(this),
      documents: [],
      hasConfirmed: false,
      showError: false
    };
  },
  methods: {
    completedDocuments: function() {
      if (this.hasConfirmed) {
        this.partnerApi.completedDocuments();
        this.$router.push({ name: BRANCH_CREATION_PAGE });
      } else {
        this.showError = true;
      }
    },
    fetchDocuments: function() {
      this.documentsApi
        .list()
        .then(result => {
          this.documents = result.data;
        })
        .catch(() => {
          showNotification(this, 'Failed to retrieve documents list', 'error');
        });
    },
    getDownloadUrl: function(document) {
      return `/api/private/v1/document/onboarding/${document.id}/downloadable-link/`;
    }
  },
  computed: {
    downloadIcon: function() {
      return getStaticUrlFromPlaceholder('images/download.svg', this.staticPlaceholder);
    }
  },
  props: {
    csrf: {
      type: String,
      required: true
    },
    staticPlaceholder: {
      type: String,
      required: true
    },
    isOnboarding: {
      type: Boolean,
      default: true
    }
  },
  components: {
    box: Box
  }
};
