import { ReferencingApiBase } from '@/libs/apis/referencing/base';

export class ReferenceRequestApi extends ReferencingApiBase {
  list(query = '') {
    return super.get(query, 'Failed to fetch the list of reference requests');
  }

  retrieve(requestId) {
    return super.get(requestId, 'Could not retrieve a reference request for the specified ID');
  }

  getStatus(requestId) {
    return super.get(
      `request/${requestId}/status`,
      'Could not retrieve the status for this reference request'
    );
  }

  getNotes(requestId) {
    return super.get(
      `request/${requestId}/notes`,
      'Could not retrieve any notes for this reference request'
    );
  }

  addNote(requestId, message) {
    const data = {
      message: message
    };

    return super.post(
      `request/${requestId}/create-note/`,
      data,
      'Could not create a note for this reference request'
    );
  }

  getForFlatbond(flatbondId) {
    return super.get(
      `tenant/?flatbond_id=${flatbondId}`,
      'Could not retrieve any reference requests for this flatbond'
    );
  }
}
