export const MYDEPOSITS_ACCOUNT_TYPES = {
  AGENT: 'agent',
  CORPORATE_LANDLORD: 'corporate-landlord'
};

export const ONBOARDING_STATES = {
  COMPLETED: 'completed',
  CREATED: 'created',
  DOCUMENTS_FINAL: 'final documents',
  DOCUMENTS_REVIEW: 'documents review',
  DOCUMENTS_UPLOAD: 'documents upload'
};

export const ORGANISATION_REPORTING_TYPE = {
  BTR: 'btr',
  OTHER: 'other',
  SPICERHAART: 'spicerhaart'
};

export const ORGANIZATION_STATES = {
  ACTIVE: 'active',
  CREATED: 'created',
  ONBOARDING: 'onboarding',
  SUSPENDED: 'suspended'
};

export const ORGANIZATION_TYPES = {
  BRANCH: 'branch',
  GROUP_DIVISION: 'group_division'
};

export const ORGANIZATION_UNIT_ROLE = {
  ADMIN: 'admin',
  USER: 'user'
};

export const ORGANIZATION_UNIT_TYPES = {
  AREA: 'area',
  BRANCH: 'branch',
  CHANNEL: 'channel',
  DIVISION: 'division',
  GROUP: 'group'
};

export const SELF_BILLING_FREQUENCY_TYPES = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly'
};

export const Organization = {
  AGENT_ASSIGNMENT_LEVEL_OPTIONS: [
    {
      value: 'branch',

      label: 'Branch'
    },

    {
      value: 'area',

      label: 'Area'
    },

    {
      value: 'division',

      label: 'Division'
    },

    {
      value: 'group',

      label: 'Group'
    }
  ],
  COMMISSION_MAX_VALIDATION_MESSAGE: 'The commission can not be greater than 100.0%',
  COMMISSION_MIN_VALIDATION_MESSAGE: 'The commission can not be less than 0.0%',
  MAX_COMMISSION: 1.0,
  MIN_COMMISSION: 0.0,

  MYDEPOSITS_ACCOUNT_TYPE_OPTIONS: [
    {
      value: 'agent',

      label: 'Agent'
    },

    {
      value: 'corporate-landlord',

      label: 'Corporate Landlord'
    }
  ],

  ONBOARDING_STATE_OPTIONS: [
    {
      value: 'created',

      label: 'Company details'
    },

    {
      value: 'documents upload',

      label: 'Documents Upload'
    },

    {
      value: 'documents review',

      label: 'Documents review'
    },

    {
      value: 'final documents',

      label: 'Final documents'
    },

    {
      value: 'completed',

      label: 'Completed'
    }
  ],

  ORGANISATION_REPORTING_TYPE_OPTIONS: [
    {
      value: 'spicerhaart',

      label: 'Spicerhaart'
    },

    {
      value: 'other',

      label: 'Other'
    },

    {
      value: 'btr',

      label: 'BTR'
    }
  ],

  ORGANIZATION_STATE_OPTIONS: [
    {
      value: 'created',

      label: 'Created'
    },

    {
      value: 'onboarding',

      label: 'onboarding'
    },

    {
      value: 'active',

      label: 'Active'
    },

    {
      value: 'suspended',

      label: 'Suspended'
    }
  ],

  ORGANIZATION_TYPE_OPTIONS: [
    {
      value: 'group_division',

      label: 'GroupDivision'
    },

    {
      value: 'branch',

      label: 'Branch'
    }
  ],

  ORGANIZATION_UNIT_TYPE_OPTIONS: [
    {
      value: 'branch',

      label: 'Branch'
    },

    {
      value: 'area',

      label: 'Area'
    },

    {
      value: 'division',

      label: 'Division'
    },

    {
      value: 'group',

      label: 'Group'
    },

    {
      value: 'channel',

      label: 'Channel'
    }
  ],

  SELF_BILLING_FREQUENCY_OPTIONS: [
    {
      value: 'monthly',

      label: 'Monthly'
    },

    {
      value: 'quarterly',

      label: 'Quarterly'
    }
  ],
  UNKNOWN_USER_EMAIL: 'Unknown'
};

export const LANDLORD_TYPES = {
  CORPORATE: 'CORPORATE',
  PRIVATE: 'PRIVATE'
};

export const Landlord = {
  LANDLORD_INVITE_FROM_DEPOSIT: 'deposit',
  LANDLORD_INVITE_FROM_FLATBOND: 'flatbond',
  LANDLORD_INVITE_FROM_INVITE_APP: 'invite_app',
  LANDLORD_INVITE_FROM_PLATFORM: 'platform',

  LANDLORD_INVITE_SOURCES: [
    {
      value: 'flatbond',

      label: 'flatbond'
    },

    {
      value: 'invite_app',

      label: 'invite app'
    },

    {
      value: 'platform',

      label: 'platform'
    },

    {
      value: 'deposit',

      label: 'deposit'
    }
  ]
};

export const PAYMENT_DECLINE_REASON = {
  FRAUDULENT: 'fraudulent',
  UNKNOWN: 'unknown'
};

export const PAYMENT_FAILURE_REASON = {
  AUTHENTICATION_FAILURE: 'authentication_failure',
  CARD_DECLINED: 'card_declined',
  CARD_EXPIRED: 'card_expired',
  INCORRECT_CVC: 'incorrect_cvc',
  INCORRECT_NUMBER: 'incorrect_number',
  INCORRECT_POSTCODE: 'incorrect_postcode',
  UNKNOWN: 'unknown'
};

export const PAYMENT_STATUS = {
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  SUCCEEDED: 'succeeded',
  UNPAID: 'unpaid'
};

export const PAYMENT_TYPE = {
  CHARGE: 'charge',
  DISPUTE: 'dispute',
  MEMBERSHIP: 'membership'
};

export const SUPPORTED_PAYMENT_METHOD = {
  CARD: 'card'
};

export const StripePaymentErrorCode = {
  CARD_DECLINED: 'card_declined',
  EXPIRED_CARD: 'expired_card',
  INCORRECT_CVC: 'incorrect_cvc',
  INCORRECT_NUMBER: 'incorrect_number',
  INCORRECT_ZIP: 'incorrect_zip',
  PAYMENT_INTENT_AUTHENTICATION_FAILURE: 'payment_intent_authentication_failure'
};

export const StripePaymentErrorDeclineCode = {
  FRAUDULENT: 'fraudulent'
};

export const Payments = {
  DEFAULT_PAYMENT_METHODS: ['card'],
  INTENT_MAX_LENGTH: 100,
  PAYMENT_DECLINE_REASON_MAX_LENGTH: 10,

  PAYMENT_DECLINE_REASON_OPTIONS: [
    {
      value: 'unknown',

      label: 'Unknown'
    },

    {
      value: 'fraudulent',

      label: 'Fraudulent'
    }
  ],
  PAYMENT_FAILURE_REASONS_REQUIRING_PAYMENT_DECLINE_REASON: ['card_declined'],
  PAYMENT_FAILURE_REASON_MAX_LENGTH: 22,

  PAYMENT_FAILURE_REASON_OPTIONS: [
    {
      value: 'unknown',

      label: 'Unknown'
    },

    {
      value: 'authentication_failure',

      label: 'Authentication failure'
    },

    {
      value: 'incorrect_postcode',

      label: 'Incorrect postcode'
    },

    {
      value: 'card_declined',

      label: 'Card declined'
    },

    {
      value: 'card_expired',

      label: 'Card expired'
    },

    {
      value: 'incorrect_cvc',

      label: 'Incorrect CVC'
    },

    {
      value: 'incorrect_number',

      label: 'Incorrect card number'
    }
  ],
  PAYMENT_STATUS_MAX_LENGTH: 11,

  PAYMENT_STATUS_OPTIONS: [
    {
      value: 'unpaid',

      label: 'Unpaid'
    },

    {
      value: 'in_progress',

      label: 'In progress'
    },

    {
      value: 'succeeded',

      label: 'Successful'
    },

    {
      value: 'failed',

      label: 'Failed'
    },

    {
      value: 'cancelled',

      label: 'Cancelled'
    }
  ],
  PAYMENT_TYPE_MAX_LENGTH: 10,

  PAYMENT_TYPE_OPTIONS: [
    {
      value: 'membership',

      label: 'membership'
    },

    {
      value: 'charge',

      label: 'charge'
    },

    {
      value: 'dispute',

      label: 'dispute'
    }
  ],
  STRIPE_PAYMENT_ERROR_CODES_REQUIRING_DECLINE_CODE: ['card_declined'],
  STRIPE_PAYMENT_ERROR_CODE_TO_PAYMENT_FAILURE_REASON_MAP: {
    payment_intent_authentication_failure: 'authentication_failure',
    incorrect_zip: 'incorrect_postcode',
    card_declined: 'card_declined',
    expired_card: 'card_expired',
    incorrect_cvc: 'incorrect_cvc',
    incorrect_number: 'incorrect_number'
  },
  STRIPE_PAYMENT_ERROR_DECLINE_CODE_TO_PAYMENT_DECLINE_REASON_MAP: { fraudulent: 'fraudulent' }
};

export const ACCOUNTING_CATEGORIES = {
  DISCOUNT: 'discount',
  MEMBERSHIP_PAYMENT: 'membership_payment',
  PARTNER_COMMISSION: 'partner_commission',
  STRIPE_FEE: 'stripe_fee',
  SUBSIDIZED_DISCOUNT: 'subsidized_discount'
};

export const ACCOUNT_TYPES = {
  ASSET: 'asset',
  INCOME_EXPENSE: 'income_expense',
  LIABILITY: 'liability'
};

export const ENTRY_TYPES = {
  CREDIT: 'credit',
  DEBIT: 'debit'
};

export const Bookkeeping = {
  DEFAULT_CURRENCY_ISO_CODE: 'gbp'
};

export const FEATURE_FLAG_TYPE = {
  CONVERSION_FLATBOND: 'conversion_flatbond',
  DEPOSIT_REPLACEMENT_SCHEDULE: 'deposit_replacement_schedule',
  DRAFT_FLATBOND: 'draft_flatbond',
  FLATBOND_PRE_VERIFICATION: 'flatbond_pre_verification'
};

export const Frontend = {};

export const MYDEPOSITS_ENTITY_TYPE = {
  CORPORATE: 'CORPORATE',
  PRIVATE: 'PRIVATE'
};

export const MYDEPOSITS_GRANT_TYPES = {
  AUTHORIZATION_CODE: 'authorization_code'
};

export const MYDEPOSITS_IMPORT_JOB_STATUSES = {
  COMPLETE: 'complete',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started'
};

export const MYDEPOSITS_IMPORT_JOB_TYPES = {
  INITIAL: 'initial',
  SYNC: 'sync'
};

export const MYDEPOSITS_PRODUCTS = {
  CUSTODIAL_ENGLAND: 'mydeposits-custodial-eng'
};

export const MYDEPOSITS_PROPERTY_TYPES = {
  FLAT: 'FLAT',
  HOUSE: 'HOUSE',
  MAISONETTE: 'MAISONETTE',
  ROOM: 'ROOM'
};

export const MYDEPOSITS_TENANCY_STATUS = {
  AWAITING_PAYMENT: 'awaiting payment',
  PROTECTED: 'protected',
  REGISTERED: 'registered',
  UNPROTECTED: 'unprotected'
};

export const MYDEPOSITS_TENANCY_TYPES = {
  ASSURED_SHORTHOLD: 'ASSURED_SHORTHOLD',
  COMPANY_LEY: 'COMPANY_LET',
  NON_ASSURED_SHORTHOLD: 'NON_ASSURED_SHORTHOLD'
};

export const MYDEPOSITS_TITLES = {
  DR: 'Dr',
  LADY: 'Lady',
  LORD: 'Lord',
  MISS: 'Miss',
  MR: 'Mr',
  MRS: 'Mrs',
  MS: 'Ms',
  PROFESSOR: 'Professor',
  SIR: 'Sir'
};

export const Mydeposits = {
  DEPOSIT_CERTIFICATE_DOCUMENT_NAME: 'deposit_certificate_for_agent_to_sign.pdf',
  MYDEPOSITS_COUNTRY_CODES: [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AN',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BM',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KS',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NT',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'PZ',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'ST',
    'SV',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TM',
    'TN',
    'TO',
    'TP',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UM',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YU',
    'ZA',
    'ZM',
    'ZR',
    'ZW'
  ],
  MYDEPOSITS_COUNTRY_CODE_GB: 'GB'
};

export const Training = {
  NORMAL_AGENT_QUIZ_NAME: 'normal-agent-quiz'
};

export const HOMELET_APPLICANT_TYPES = {
  GUARANTOR: 'Guarantor',
  TENANT: 'Tenant'
};

export const HOMELET_GUARANTOR_LIMITS = {
  MAX_GUARANTORS_COUNT: 8,
  MIN_RENT_SHARE: 2000
};

export const HOMELET_RECOMMENDATION_STATUSES = {
  ACCEPTABLE: 'Acceptable',
  ACCEPTABLE_WITH_CONDITION: 'Acceptable With Condition',
  ACCEPTABLE_WITH_GUARANTOR: 'Acceptable With Guarantor',
  ACCEPTABLE_WITH_GUARANTOR_WITH_CONDITION: 'Acceptable With Guarantor With Condition',
  ACCEPTABLE_WITH_GUARANTOR_ZERO_HOUR_CONTRACT: 'Acceptable With Guarantor Zero Hour Contract',
  ACCEPTABLE_ZERO_HOUR_CONTRACT: 'Acceptable Zero Hour Contract',
  ACCEPTABLE_ZERO_RENT_WITH_COTENANT: 'Acceptable Zero Rent With Cotenant',
  AWAITING_COMPLETION_OF_LEAD_TENANT: 'Awaiting Completion Of Lead Tenant',
  AWAITING_FURTHER_INFORMATION: 'Awaiting Further Information',
  NOT_ACCEPTABLE: 'Not Acceptable',
  NOT_ACCEPTABLE_ZERO_CONTRACT_HOUR: 'Not Acceptable Zero Contract Hour',
  NOT_ACCEPTABLE_ZERO_RENT_WITH_COTENANT: 'Not Acceptable Zero Rent With Cotenant'
};

export const REFERENCE_RECOMMENDATION_STATUSES = {
  ACCEPTABLE: 'acceptable',
  ACCEPTABLE_WITH_CONDITION: 'acceptable_with_condition',
  NOT_ACCEPTABLE: 'not_acceptable'
};

export const REFERENCING_DECISIONS = {
  APPROVED: 'approved',
  CONDITIONALLY_APPROVED: 'conditionally_approved',
  REJECTED: 'rejected'
};

export const REFERENCING_DECISION_CONDITIONS = {
  GUARANTOR: 'guarantor',
  OTHER: 'other',
  PAY_UPFRONT: 'prepayment_of_rent'
};

export const REFERENCING_PROVIDER = {
  HOMELET: 'homelet',
  MOVEM: 'movem'
};

export const REFERENCING_REQUEST_SECURITY_STATUSES = {
  DEPOSIT: 'deposit',
  FLATBOND: 'flatbond',
  UNKNOWN: 'unknown'
};

export const REFERENCING_REQUEST_STATUSES = {
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  CREATING: 'creating',
  EXPIRED: 'expired',
  INVITED: 'invited',
  IN_PROGRESS: 'in_progress',
  PENDING_CASE_CREATION: 'pending_case_creation',
  PENDING_REVIEW: 'pending_review'
};

export const Referencing = {
  REFERENCE_RECOMMENDATION_OPTIONS: [
    {
      value: 'acceptable',

      label: 'Acceptable'
    },

    {
      value: 'acceptable_with_condition',

      label: 'Acceptable With Condition'
    },

    {
      value: 'not_acceptable',

      label: 'Not Acceptable'
    }
  ],
  REFERENCING_PROVIDERS_OPTIONS: [('movem', 'Movem'), ('homelet', 'Homelet')]
};

export const HELLOSIGN_CALLBACK_EVENTS = {
  ALL_SIGNED: 'signature_request_all_signed',
  SIGNED: 'signature_request_signed'
};

export const HELLOSIGN_SIGNATURE_ROLES = {
  FLATFAIR: 'flatfair',
  LANDLORD: 'landlord'
};

export const HELLOSIGN_SIGNATURE_STATUSES = {
  AWAITING_SIGNATURE: 'awaiting_signature',
  SIGNED: 'signed'
};

export const SIGN_DOCUMENT_TYPES = {
  AGENT_TC: 'agent_terms_and_conditions',
  LANDLORD_TC: 'landlord_terms_and_conditions',
  PARTNER_TC: 'partner_introducer_agreement'
};

export const TENANT_TERMS_TEMPLATES = {
  DEFAULT: 'default-tenant-terms-template',
  LICENSE: 'license-tenant-terms-template'
};

export const Esign = {
  COMPANY_LICENSE_TENANT_TERMS_TEMPLATE_ID: '92adff7a96bc93fb36399fdd5989de283f0deb24',
  COMPANY_SCOTTISH_TENANT_TERMS_TEMPLATE_ID: '76c4f6ffd7b34b799905852169db88234b24816c',
  COMPANY_TENANT_TERMS_TEMPLATE_ID: 'bf4ae352c558c3faa5404b3f6333fd761703e4e8',
  DEPOSIT_CERTIFICATE_SIGNED_DOCUMENT_NAME: 'deposit_certificate_signed_by_the_agent.pdf',
  DEPOSIT_REPLACEMENT_SCHEDULE_COMPANY_LETS_TEMPLATE_ID: '91d68869f6325e1c64ddbb5a20dcc3352415327c',
  DEPOSIT_REPLACEMENT_SCHEDULE_TEMPLATE_ID: '98e10975e96c1be1a5d35b34b7645b47c35aa7a4',
  ESIGN_DEPOSIT_CERTIFICATE_FORM_FIELDS: [
    [
      {
        api_id: 'agent_name',
        name: 'Agent name',
        type: 'text',
        x: 118,
        y: 293,
        width: 176,
        height: 18,
        required: true,
        signer: 0,
        page: 5
      },
      {
        api_id: 'agent_signature',
        name: 'Agent signature',
        type: 'signature',
        signing_options: { default: 'type' },
        x: 334,
        y: 293,
        width: 180,
        height: 20,
        required: true,
        signer: 0,
        page: 5
      }
    ]
  ],
  ESIGN_DEPOSIT_CERTIFICATE_MESSAGE: 'Please sign the Deposit Certificate document',
  ESIGN_DEPOSIT_CERTIFICATE_SUBJECT: 'MyDeposits Deposit Certificate',
  ESIGN_DEPOSIT_CERTIFICATE_TITLE: 'Deposit Certificate',
  HELLOSIGN_CALLBACK_EXPECTED_RESPONSE_TO_PREVENT_RETRY: 'Hello API Event Received',
  HELLOSIGN_DOCUMENT_SIGNED_STATUS_CODE: 'signed',
  LANDLORD_OFFER_ASSIGN_ONLY_TEMPLATE_ID: 'd21d1f00841112aeb5e871139b57785fc7d91c1f',
  LANDLORD_OFFER_SIGNED_DOCUMENT_NAME: 'landlord_offer_signed.pdf',
  LANDLORD_OFFER_TEMPLATE_ID: '388d649f54538eb1512933685b0012ce5efb248b',
  LANDLORD_TERMS_COMPANY_LETS_TEMPLATE_ID: '33aed6d1f2976cde22dc2c0ad008585a587ddbcb',
  LANDLORD_TERMS_TEMPLATE_ID: '66bf00a3a0e0585393052471416acb9f80bdc51b',
  LICENSE_TENANT_TERMS_TEMPLATE_ID: '213f1e48d711b3b6a6d049f7a4b9bc87214210d0',
  PDF_FORMAT: 'pdf',
  SCOTTISH_TENANT_TERMS_TEMPLATE_ID: 'ee74b5e6668d9c9852a541b2e297e546410c56c6',
  SELF_BILLING_AGREEMENT_TEMPLATE_ID: '8fdb8d7b5e035376c7613ad6b0139475af4d872b',
  TENANT_TERMS_TEMPLATE_ID: '34bc4d1201ebdd933002db33d530fce0f3b6c84e',

  TENANT_TERMS_TEMPLATE_OPTIONS: [
    {
      value: 'default-tenant-terms-template',

      label: 'Default template for tenant terms'
    },

    {
      value: 'license-tenant-terms-template',

      label: 'License template for tenant terms'
    }
  ]
};

export const FLATBOND_COUNTRIES = {
  ENGLAND: 'gb-eng',
  SCOTLAND: 'gb-sct',
  UNITED_KINGDOM: 'gb',
  WALES: 'gb-wls'
};

export const FLATBOND_CREATION_METHOD = {
  PRIVATE_API_V1: 'private_api_v1',
  PRIVATE_API_V1_DRAFT: 'private_api_v1_draft',
  PUBLIC_API_V1: 'public_api_v1',
  PUBLIC_API_V2: 'public_api_v2',
  UNKNOWN: 'unknown'
};

export const FLATBOND_CREATION_RENT_PERIODS = {
  MONTH: 'month',
  WEEK: 'week'
};

export const FLATBOND_CURRENCIES = {
  GBP: 'gbp'
};

export const FLATBOND_EXTENSION_TYPE = {
  FIXED_TERM: 'fixed_term',
  PERIODIC: 'periodic'
};

export const FLATBOND_FIELDS_MAX_LENGTH = {
  COMPANY_NAME: 100,
  FIRST_NAME: 100,
  LAST_NAME: 100,
  REFERENCING_CONDITION: 200,
  REFERENCING_CONDITION_TYPE: 30,
  REFERENCING_RESULT: 30
};

export const FLATBOND_MANAGED_BY = {
  AGENT: 'agent',
  LANDLORD: 'landlord'
};

export const FLATBOND_PRODUCT_TYPES = {
  DEPOSIT: 'deposit',
  FLATBOND: 'flatbond'
};

export const FLATBOND_SOURCES = {
  DEPOSIT_IMPORT: 'deposit-import',
  PLATFORM: 'platform'
};

export const FLATBOND_STATUSES = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  CLAIM_DISPUTE: 'claim_disputed',
  CLAIM_IN_REVIEW: 'claim_in_review',
  CLAIM_PENDING_PAYMENT: 'claim_pending_payment',
  CLAIM_SENT: 'claim_sent',
  CLAIM_START: 'claim_started',
  CLOSED: 'closed',
  CREATED: 'created',
  EXPIRED: 'expired',
  PENDING_AGENT_ACTION: 'pending_agent_action',
  PENDING_LANDLORD_ACTION: 'pending_landlord_action',
  PENDING_TENANT_ACTION: 'pending_tenant_action',
  PENDING_TENANT_REFERENCING: 'pending_tenant_referencing',
  PENDING_VERIFICATION: 'pending_verification'
};

export const FLATBOND_TAGS = {
  APPROVE_ON_LANDLORD_BEHALF: 'Approved on behalf of the landlord'
};

export const FLATBOND_TENANT_TYPES = {
  COMPANY: 'company',
  PRIVATE: 'private'
};

export const FLATBOND_TYPES = {
  ALL: 'all',
  CONVERSION: 'conversion',
  NEW_TENANCY: 'new_tenancy'
};

export const FLATBOND_UPDATE_CHANGE_TYPE = {
  ACTIVATED: 'activated',
  ADD_TENANT: 'add_tenant',
  CANCELLED: 'cancelled',
  CHANGED_TENANT: 'changed_tenant',
  CLAIMS_SENT: 'claims_sent',
  CLAIMS_SUBMITTED_FOR_REVIEW: 'claims_submitted_for_review',
  CLOSED: 'closed',
  DISPUTE_STARTED: 'dispute_started',
  EXTENDED: 'extended',
  PENDING_VERIFICATION: 'pending_verification',
  REMOVE_TENANT: 'remove_tenant',
  RENT_UPDATED: 'rent_updated',
  REQUESTED_REFERENCING: 'requested_referencing',
  SENT_TO_LANDLORD: 'send_to_landlord',
  SENT_TO_TENANTS: 'sent_to_tenants'
};

export const REFERENCING_CONDITION_TYPES = {
  GUARANTOR: 'guarantor',
  OTHER: 'other',
  PROOF_OF_ADDRESS: 'proof_of_address',
  RENT_UPFRONT: 'rent_upfront'
};

export const REFERENCING_RESULT_TYPES = {
  APPROVED: 'approved',
  CONDITIONALLY_APPROVED: 'conditionally_approved',
  REJECTED: 'rejected'
};

export const TENANCY_TYPES = {
  ASSURED_SHORTHOLD: 'ASSURED_SHORTHOLD',
  COMPANY_LET: 'COMPANY_LET',
  LICENSE: 'LICENSE',
  NON_ASSURED_SHORTHOLD: 'NON_ASSURED_SHORTHOLD'
};

export const Flatbonds = {
  DEFAULT_FLATBOND_COUNTRY: 'gb-eng',
  DEFAULT_RENT_CURRENCY: 'gbp',

  FLATBOND_PRODUCT_TYPE_OPTIONS: [
    {
      value: 'flatbond',

      label: 'flatbond'
    },

    {
      value: 'deposit',

      label: 'Deposit'
    }
  ],
  MANDATORY_NUMBER_OF_TENANTS_ON_COMPANY_LET: 1,
  MAXIMUM_RENT_PER_MONTH: 866600,
  MAXIMUM_RENT_PER_WEEK: 200000,
  MINIMUM_MEMBERSHIP_FEE_WITHOUT_VAT: 12000,
  MINIMUM_RENT_PER_MONTH_TO_CREATE: 10900,
  MINIMUM_RENT_PER_WEEK: 12000,
  MINIMUM_RENT_PER_WEEK_TO_CREATE: 2500,
  NUMBER_OF_FLATBONDS_FOR_VERIFICATION_NOTIFICATION: 0,

  REFERENCING_CONDITION_TYPE_OPTIONS: [
    {
      value: 'proof_of_address',

      label: 'Proof of address'
    },

    {
      value: 'guarantor',

      label: 'Guarantor'
    },

    {
      value: 'rent_upfront',

      label: 'Rent upfront'
    },

    {
      value: 'other',

      label: 'Other'
    }
  ],

  REFERENCING_DECISION_OPTIONS: [
    {
      value: 'approved',

      label: 'Approved'
    },

    {
      value: 'rejected',

      label: 'Rejected'
    },

    {
      value: 'conditionally_approved',

      label: 'Conditionally Approved'
    }
  ]
};

export const API_ACTIONS = {
  GET: 'list',
  LIST: 'list'
};

export const ENVIRONMENT = {
  DEVELOPMENT: 'local',
  PRODUCTION: 'production',
  STAGING: 'staging',
  TEST: 'test'
};

export const HUBSPOT_LEAD_SOURCE_COMPANY_PROPERTY = {
  SELF_ON_BOARDING: 'Self on-boarding'
};

export const Utils = {};

export const STRIPE_REFUND_REASONS = {
  DUPLICATE: 'duplicate',
  FRAUDULENT: 'fraudulent',
  REQUESTED_BY_CUSTOMER: 'requested_by_customer'
};

export const Tenants = {
  FLATBOND_CANCELLED_COST: 0,
  RAISE_DISPUTE_COST_IN_PENCE: 10000
};

export const FUNDS_TRANSFER_STATUS_COLOURS = {
  PENDING: 'amber-300',
  RECEIVED: 'success',
  SENT: ''
};

export const FUNDS_TRANSFER_STATUS_DISPLAY = {
  PENDING: 'Pending transfer',
  RECEIVED: 'MyDeposits confirmed transfer',
  SENT: 'Pending MyDeposits confirmation'
};

export const FUND_TRANSFER_STATUSES = {
  PENDING: 'pending',
  RECEIVED: 'received',
  SENT: 'sent'
};

export const Deposits = {};

export const CHARGE_PAYMENT_PLAN_INSTALLMENT_PERIOD_TYPE = {
  MONTHLY: 'monthly',
  WEEKLY: 'weekly'
};

export const CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS = {
  CREATED: 'created',
  PAID_IN_FULL: 'paid_in_full',
  PARTIALLY_PAID: 'partially_paid',
  PAYMENT_PAST_DUE: 'payment_past_due',
  PENDING_PAYMENT: 'pending_payment'
};

export const CHARGE_PAYMENT_PLAN_STATUS = {
  ACCEPTED: 'accepted',
  CLOSED: 'closed',
  CREATED: 'created',
  IN_PROGRESS: 'in_progress',
  PENDING_AGREEMENT: 'pending_agreement',
  PENDING_INITIAL_PAYMENT: 'pending_initial_payment'
};

export const DISCOUNT_MODES = {
  FIXED_FEE: 'fixed_fee',
  PERCENTAGE: 'percentage'
};

export const DISCOUNT_PRODUCTS = {
  CONVERSION_FLATBONDS: 'conversion_flatbonds',
  DISPUTE: 'dispute',
  FEES: 'fees',
  FLATBONDS: 'flatbonds',
  NEW_FLATBONDS: 'new_flatbonds'
};

export const DISCOUNT_TYPES = {
  INDIVIDUAL: 'individual',
  ORGANIZATION: 'organization'
};

export const Transactions = {};

export const PROPERTY_APPLICATION_STATUSES = {
  ACTION_REQUIRED: 'action_required',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  CREATING: 'creating',
  EXPIRED: 'expired',
  INVITED: 'invited',
  IN_PROGRESS: 'in_progress',
  PENDING_REVIEW: 'pending_review'
};

export const PROPERTY_STATUSES = {
  CREATED: 'created',
  PENDING_REFERENCING: 'pending_referencing',
  PENDING_REFERENCING_DECISION: 'pending_referencing_decision',
  RENTED: 'rented'
};

export const PROPERTY_TYPES = {
  FLAT: 'FLAT',
  HOUSE: 'HOUSE',
  MAISONETTE: 'MAISONETTE',
  ROOM: 'ROOM'
};

export const Properties = {};

export const Review = {
  FLATFAIR_GROUP_ID: 1
};

export const ACCESS_DOMAINS = {
  BATCH_PAYMENT: 'batch-payments',
  BATCH_PAYMENTS: 'batch-payments',
  BOOKKEEPING: 'bookkeeping',
  FLATBONDS: 'flatbonds',
  LANDLORD_OFFER: 'landlord-offer',
  OPS: 'ops',
  ORGANIZATION_UNITS: 'organization-units',
  PAYMENT: 'payment',
  REFERENCING: 'referencing',
  REFUND: 'refund',
  SELF_BILLING: 'self-billing',
  TENANT: 'tenant'
};

export const ACCESS_TYPES = {
  ACTION: 'action',
  VIEW: 'view'
};

export const Users = {};

export const RENT_ARREARS_AGREEMENT_TYPES = {
  OTHER: 'other',
  RENT_HOLIDAY: 'rent_holiday',
  RENT_REDUCTION: 'rent_reduction',
  REPAYMENT_PLAN: 'repayment_plan'
};

export const RENT_ARREARS = {
  RENT_ARREARS_AGREEMENT_CHOICES: [
    {
      value: 'rent_holiday',

      label: 'Rent holiday'
    },

    {
      value: 'rent_reduction',

      label: 'Rent reduction'
    },

    {
      value: 'repayment_plan',

      label: 'Repayment plan'
    },

    {
      value: 'other',

      label: 'Other'
    }
  ]
};

export const DOCUMENT_ACCESS_LEVELS = {
  INTERNAL: 'internal',
  PUBLIC: 'public'
};

export const DOCUMENT_TYPES = {
  ADDITIONAL_REFERENCING_DOCUMENT: 'additional_referencing_document',
  AST: 'assured_shorthold_tenancy',
  AST_ADDENDUM_TEMPLATE: 'ast_addendum_template',
  AST_COMPANY_TEMPLATE: 'ast_company_template',
  AST_TEMPLATE: 'ast_template',
  CHECK_IN: 'check_in',
  CHECK_OUT: 'check_out',
  CLAIMS_INVOICE: 'claims_invoice',
  CLAIM_DOCUMENT: 'claim_document',
  DEPOSIT_PROTECTION_CERTIFICATE: 'deposit_protection_certificate',
  DEPOSIT_PROTECTION_CERTIFICATE_SIGNED: 'deposit_protection_certificate_signed',
  DEPOSIT_REPLACEMENT_AGREEMENT: 'deposit_replacement_agreement',
  DEPOSIT_REPLACEMENT_SCHEDULE: 'deposit_replacement_schedule',
  FLATBOND_CERTIFICATE: 'flatbond_certificate',
  INSTRUCTION_FORM_TEMPLATE: 'instruction_form_template',
  INVENTORY_REPORT: 'inventory_report',
  LANDLORD_OFFER_SIGNED: 'landlord_offer_signed',
  LANDLORD_SUBJECT_TO_CONTRACT_TEMPLATE: 'landlord_subject_to_contract_template',
  ONBOARDING_DOCUMENT: 'onboarding_document',
  REFERENCING_RESULT: 'referencing_result',
  TENANCY_ADDENDUM: 'tenancy_addendum',
  TENANCY_AGREEMENT: 'tenancy_agreement',
  TENANT_OFFER_LETTER_TEMPLATE: 'tenant_offer_letter_template',
  TENANT_SUBJECT_TO_CONTRACT_TEMPLATE: 'tenant_subject_to_contract_template',
  TENANT_TERMS_OF_BUSINESS: 'tenant_terms_of_business_template',
  TERMS_OF_BUSINESS_TEMPLATE: 'terms_of_business_template',
  UNKNOWN: 'unknown'
};

export const Documents = {
  INTERNAL_DOCUMENT_TYPES: ['referencing_result', 'landlord_offer_signed']
};

export const CHARGE_EVENT_TYPE = {
  ALL_SUBMITTED: 'all-charges-submitted',
  CREATED: 'charge-created',
  DEADLINE_SOON: 'charges-deadline-soon',
  DISPUTED: 'charges-disputed',
  ESTABLISHED: 'charges-established',
  PAYMENT_DUE: 'charges-payment-due',
  RESPONSE: 'charge-response',
  RESPONSE_AGENT_ACCEPTED: 'charge-response-agent-accepted',
  RESPONSE_AGENT_COUNTERED: 'charge-response-agent-countered',
  RESPONSE_TENANT_ACCEPTED: 'charge-response-tenant-accepted',
  RESPONSE_TENANT_COUNTERED: 'charge-response-tenant-countered',
  SUBMITTED_FOR_REVIEW: 'submitted-for-review'
};

export const DEPOSIT_EVENT_TYPE = {
  PROTECTED: 'deposit-protected'
};

export const EVENT_NAME = {
  COMMENT: 'comment',
  EXTERNAL_COMMUNICATION: 'external-communication',
  FLATBOND_VERIFICATION_ISSUE: 'flatbond-verification-issue'
};

export const EVENT_SOURCE = {
  PLATFORM: 'platform'
};

export const EVENT_TYPE = {
  COMMUNICATION: 'communication',
  NOTE: 'note'
};

export const FLATBOND_EVENT_TYPE = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  CHANGED: 'changed',
  CLOSED_WITHOUT_CLAIMS: 'closed-without-clams',
  CLOSING_SOON: 'closing-soon',
  DOCUMENT_UPLOADED: 'document-uploaded',
  EXTENDED: 'extended',
  READY_TO_ACTIVATE: 'flatbond-ready-to-activate',
  READY_TO_PRE_VERIFY: 'flatbond-ready-to-pre-verify',
  SENT: 'sent',
  STARTING_SOON: 'starting-soon',
  WITHIN_END_DATE_COOLDOWN: 'within-end-date-cooldown'
};

export const INVITE_EVENT_TYPE = {
  AGENT_INVITED: 'agent-invited',
  AGENT_PEER_INVITED: 'agent-peer-invited',
  GUARANTOR_INVITED_REFERENCING: 'guarantor-invited-referencing',
  LANDLORD_INVITED: 'landlord-invited',
  LANDLORD_INVITED_FLATBOND: 'landlord-invited-flatbond',
  TENANT_INVITED: 'tenant-invited',
  TENANT_INVITED_DEPOSIT_CREATED: 'tenant-invited-deposit-created',
  TENANT_INVITED_DEPOSIT_PROTECTED: 'tenant-invited-deposit-protected',
  TENANT_INVITED_REFERENCING: 'tenant-invited-referencing'
};

export const LANDLORD_EVENT_TYPE = {
  INVITED: 'landlord-invited',
  INVITED_FLATBOND: 'landlord-invited-flatbond'
};

export const LANDLORD_OFFER_EVENT_TYPE = {
  APPROVED: 'landlord-offer-approved',
  CANCELLED: 'landlord-offer-cancelled',
  COMPLETED: 'landlord-offer-completed',
  CREATED: 'landlord-offer-created',
  REMINDER: 'landlord-offer-reminder',
  SIGNED_INTERNALLY: 'landlord-offer-signed-internally',
  SIGNED_LANDLORD: 'landlord-offer-signed-landlord'
};

export const NOTE_TYPE = {
  CALL: 'call',
  COMMENT: 'comment',
  EMAIL: 'email',
  LETTER: 'letter',
  OTHER: 'other',
  VERIFICATION_ISSUE: 'verification-issue',
  ZENDESK_CHAT: 'zendesk-chat'
};

export const NOTE_TYPE_OPTIONS = {
  OPTIONS: [
    {
      value: 'comment',

      label: 'Comment'
    },

    {
      value: 'verification-issue',

      label: 'Verification issue'
    },

    {
      value: 'letter',

      label: 'Letter'
    },

    {
      value: 'email',

      label: 'Email'
    },

    {
      value: 'call',

      label: 'Call'
    },

    {
      value: 'zendesk-chat',

      label: 'Zendesk chat'
    },

    {
      value: 'other',

      label: 'Other'
    }
  ]
};

export const ORGANIZATION_GROUP_EVENT = {
  CREATED: 'organisation-group-created',
  NAME: 'organisation-group-event'
};

export const PAYMENT_EVENT_TYPE = {
  DISPUTE_PAYMENT_SUCCESSFUL: 'dispute-payment-successful',
  MEMBERSHIP_PAYMENT_SUCCESSFUL: 'membership-payment-successful',
  NAME_CHECK_FAILED: 'payment-name-check-failed',
  NAME_CHECK_PASSED: 'payment-name-check-passed'
};

export const REFERENCE_EVENT_TYPE = {
  APPROVED: 'reference-approved',
  COMPLETED: 'reference-completed',
  CONDITIONALLY_APPROVED: 'reference-conditionally-approved',
  CREATED: 'reference-created',
  REJECTED: 'reference-rejected',
  UPDATED: 'reference-updated'
};

export const SELF_BILLING_EVENT_TYPE = {
  AGREEMENT_SIGNED: 'agreement-signed',
  REVIEW_REMINDER: 'review-reminder',
  REVIEW_REQUESTED: 'review-requested'
};

export const TRAINING_EVENT_TYPE = {
  REMINDER: 'reminder'
};

export const USER_EVENT_TYPE = {
  EMAIL_CONFIRMATION_REQUESTED: 'email-confirmation-requested'
};

export const VERIFICATION_ISSUE = {
  ADDRESS_MISMATCH: 'address-mismatch',
  DATES_MISMATCH: 'dates-mismatch',
  EMPTY_AST: 'ast-empty',
  EXTRA_TENANTS: 'extra-tenants',
  MISSING_FLATFAIR_CLAUSE: 'missing-flatfair-clause',
  MISSING_TENANTS: 'missing-tenants',
  OTHER: 'other-verification-issue',
  RENT_MISMATCH: 'rent-mismatch',
  TENANT_NAME_MISMATCH: 'tenant-name-mismatch'
};

export const VERIFICATION_OPTIONS = {
  OPTIONS: [
    {
      value: 'missing-flatfair-clause',

      label: 'Missing flatfair clause'
    },

    {
      value: 'dates-mismatch',

      label: 'Dates mismatch'
    },

    {
      value: 'missing-tenants',

      label: 'Missing tenants'
    },

    {
      value: 'extra-tenants',

      label: 'Extra tenants'
    },

    {
      value: 'tenant-name-mismatch',

      label: 'Tenant name mismatch'
    },

    {
      value: 'ast-empty',

      label: 'Empty ast'
    },

    {
      value: 'rent-mismatch',

      label: 'Rent mismatch'
    },

    {
      value: 'other-verification-issue',

      label: 'Other verification issue'
    }
  ]
};

export const Events = {
  ADJUDICATION_MESSAGE: 'adjudication-message',
  ADJUDICATION_MESSAGE_CREATE: 'adjudication-message-create',
  CHARGE_CASE_TRANSACTION_CREATED_EVENT: 'charge-case-transaction-created',
  CHARGE_EVENT: 'charge-event',
  CHARGE_PAYMENT_PLAN_ACCEPTED: 'charge-payment-plan-accepted',
  CHARGE_PAYMENT_PLAN_CREATED: 'charge-payment-plan-created',
  CHARGE_PAYMENT_PLAN_EVENT: 'charge-payment-plan-event',
  CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS_UPDATED: 'charge-payment-plan-installment-status-updated',
  DEPOSIT_EVENT: 'deposit-event',
  DEPOSIT_RELEASE_DUE_EVENT: 'release-deposit-due',
  FLATBOND_CANCELED_EVENT: 'flatbond-canceled',
  FLATBOND_CLOSING_SOON: 'flatbond-closing-soon',
  FLATBOND_CLOSING_SOON_EVENT: 'flatbond-closing-soon',
  FLATBOND_EVENT: 'flatbond-message',
  FLATBOND_EXTENDED_EVENT: 'flatbond-extended',
  FLATBOND_PAST_END_DATE_COOLDOWN: 'flatbond-past-end-date-cooldown',
  FLATBOND_STARTING_SOON: 'flatbond-starting-soon',
  FLATBOND_STARTING_SOON_EVENT: 'flatbond-starting-soon',
  FLATBOND_WITHIN_END_DATE_COOLDOWN: 'flatbond-within-end-date-cooldown',
  FLATPIE_BUS: 'flatpie-bus',
  INVITE_EVENT: 'invite-event',
  LANDLORD_EVENT: 'landlord-event',
  LANDLORD_OFFER_EVENT: 'landlord-offer-event',
  LANDLORD_OFFER_EVENT_CREATE: 'landlord-offer-event-create',
  MYDEPOSITS_IMPORT_JOB_CREATED: 'mydeposits-import-job-created',
  ORGANISATION_USER_ASSIGNED_ROLE: 'organisation-user-assigned-role',
  ORGANISATION_USER_EVENT: 'organisation-user-event',
  ORGANISATION_USER_INVITED: 'organisation-user-invited',
  ORGANISATION_USER_SELECTED_HOME_UNIT: 'organisation-user-selected-home-unit',
  PARTNER_MESSAGE: 'partner-message',
  PARTNER_MESSAGE_APPROVED: 'partner-message-approved',
  PARTNER_MESSAGE_ATTENDED_WEBINAR: 'partner-message-attended-webinar',
  PARTNER_MESSAGE_COMPLETED_DOCUMENTS_ONBOARDING: 'partner-message-completed-documents-onboarding',
  PARTNER_MESSAGE_COMPLETED_ORGANIZATION_SETUP: 'partner-message-completed-organization-setup',
  PARTNER_MESSAGE_COMPLETED_USER_INVITE: 'partner-message-completed-user-invite',
  PARTNER_MESSAGE_HANDLED_OFFLINE: 'partner-message-handled-offline',
  PARTNER_MESSAGE_REGISTERED: 'partner-message-registered',
  PARTNER_MESSAGE_REGISTERED_FOR_WEBINAR: 'partner-message-registered-for-webinar',
  PARTNER_MESSAGE_REJECTED: 'partner-message-rejected',
  PARTNER_MESSAGE_SIGNED_AGREEMENT: 'partner-message-signed-agreement',
  PAYMENT_EVENT: 'payment-event',
  REFERENCE_EVENT: 'reference-event',
  SELF_BILLING_EVENT: 'self-billing-event',
  TRAINING_EVENT: 'training-event',
  TRANSACTION_EVENT: 'transaction-event',
  TRANSACTION_FLATBOND_CANCELLED_EVENT: 'transaction-flatbond-cancelled',
  TRANSACTION_FLATBOND_CREATED_EVENT: 'transaction-flatbond-created',
  TRANSACTION_FLATBOND_DISCOUNT_ADDED_EVENT: 'transaction-flatbond-discount-added',
  TRANSACTION_FLATBOND_DISCOUNT_REMOVED_EVENT: 'transaction-flatbond-discount-removed',
  TRANSACTION_MEMBERSHIP_PAID_EVENT: 'transaction-membership-paid',
  TRANSACTION_MEMBERSHIP_REFUNDED_EVENT: 'transaction-membership-refunded',
  USER_EVENT: 'user-event'
};

export const COMPANY_TYPES = {
  INSTITUTIONAL: 'institutional_landlord',
  LETTING_AGENT: 'letting_agent',
  PORTFOLIO: 'portfolio_landlord'
};

export const ONBOARDING_STATUSES = {
  BRANCH_CREATION: 'branch_creation',
  COMPLETED: 'completed',
  CREATED: 'created',
  DOCUMENTS_ONBOARDING: 'documents_onboarding',
  PENDING_COMPANY_VERIFICATION: 'pending_company_verification',
  PENDING_CONTRACT: 'pending_contract',
  PENDING_WEBINAR: 'pending_webinar_registration',
  USER_INVITE: 'user_invite'
};

export const Onboarding = {
  DEFAULT_ORGANISATION_COMMISSION: 0.1,
  MAXIMUM_COMMISSION: 50,
  MINIMUM_COMMISSION: 0
};

export const CASE_DEBT_PURCHASE_STATUS = {
  ACTIVE: 'active',
  AUTHORITY_AGREED: 'authority_agreed',
  DECLINED: 'declined',
  DELAYED: 'delayed',
  PENDING_AGENT_ACTION: 'pending_agent_action'
};

export const CHARGE_ACCEPTOR = {
  ADJUDICATOR: 'adjudicator',
  AGENT: 'agent',
  TENANT: 'tenant'
};

export const CLAIM_CATEGORIES = {
  AGENCY: 'agency',
  BILLS: 'bills',
  CONTRACTORS: 'contractors',
  DAMAGE: 'damage',
  GARDENING: 'gardening',
  OTHER: 'other',
  PROFESSIONAL_CLEANING: 'professional_cleaning',
  RENT: 'rent'
};

export const CLAIM_STATUSES = {
  ACCEPTED: 'accepted',
  ADJUDICATED: 'adjudicated',
  CANCELED: 'canceled',
  COUNTERED: 'countered',
  COUNTER_ACCEPTED: 'counter_accepted',
  COUNTER_DECLINED: 'counter_declined',
  CREATED: 'created',
  DEEMED_ACCEPTED: 'deemed_accepted',
  DISPUTED: 'disputed',
  IN_REVIEW: 'in_review',
  PAID: 'paid',
  SENT: 'sent',
  WAIVED: 'waived'
};

export const DEBT_COLLECTION_STATUS = {
  ATTENDANCE_IN_PROGRESS: 'attendance_in_progress',
  AWAITING_SEALED_WRIT: 'awaiting_sealed_writ',
  CONTACT_MADE: 'contact_made',
  COURT_APPLICATION_IN_PROGRESS: 'court_application_in_progress',
  DEBT_MANAGEMENT_PLAN_IN_PROGRESS: 'debt_management_plan_in_progress',
  NEGOTIATING_PAYMENT_PLAN: 'negotiating_payment_plan',
  PENDING_CONTACT: 'pending_contact',
  PRE_VISIT_CHECKS: 'pre_visits_checks',
  RESOLVED: 'resolved'
};

export const DEBT_RECOVERY_STRATEGY = {
  BAILIFF_STRATEGY: 'bailiff_strategy',
  CCJ_HCEO: 'ccj_hceo',
  EXTERNAL_DEBT_RECOVERY: 'external_debt_recovery',
  EXTERNAL_PAYMENT_PLAN: 'external_pp',
  INTERNAL_PAYMENT_PLAN: 'internal_payment_plan',
  IVA: 'iva',
  NO_CONTRACT_PARTIAL_STRIPE: 'no_contract_partial_stripe',
  PAID_PRE_LIT: 'paid_pre_lit',
  PRE_COURT: 'pre_court'
};

export const FLATBOND_CLAIM_CASE_STATUS = {
  CLOSED: 'closed',
  CREATED: 'created',
  DISPUTE_COOL_DOWN: 'dispute_cool_down',
  DISPUTE_PENDING_DECISION: 'dispute_pending_decision',
  DISPUTE_PENDING_DECISION_UPLOAD: 'dispute_pending_decision_upload',
  IN_DEBT_COLLECTION: 'in_debt_collection',
  IN_NEGOTIATION: 'in_negotiation',
  IN_REVIEW: 'in_review',
  LANDLORD_OFFER_SENT: 'landlord_offer_sent',
  PENDING_DEBT_COLLECTION: 'pending_debt_collection',
  PENDING_LANDLORD_OFFER: 'pending_landlord_offer',
  PENDING_PAYMENT: 'pending_payment',
  PENDING_TENANT_RESPONSE: 'pending_tenant_response',
  TO_BE_CHARGED: 'to_be_charged',
  TO_BE_DEEMED_ACCEPTED: 'to_be_deemed_accepted'
};

export const LANDLORD_OFFER_STATUS = {
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  PENDING_APPROVAL: 'pending_approval',
  PENDING_INTERNAL_SIGNATURE: 'pending_internal_signature',
  PENDING_LANDLORD_SIGNATURE: 'pending_landlord_signature',
  PENDING_OFFER_PAYMENT: 'pending_offer_payment'
};

export const PAYMENT_COLLECTION_METHOD = {
  BANK_TRANSFER: 'bank_transfer',
  CHEQUE: 'cheque',
  DEBT_COLLECTION: 'debt_collection',
  STRIPE_FORCED: 'stripe_forced',
  STRIPE_INVOICE: 'stripe_invoice',
  STRIPE_PHONE: 'stripe_phone',
  STRIPE_PLATFORM: 'stripe_platform'
};

export const VALIDATION_STATUS = {
  DEADLINE_EXCEEDED: 'deadline_exceeded',
  FAILED_ELIGIBILITY_CRITERIA: 'failed_eligibility_criteria',
  FAILED_REFERENCING: 'failed_referencing',
  FRAUDULENT_ACTIVITY: 'fraudulent_activity',
  USER_ERROR: 'user_error',
  VALID: 'valid'
};

export const Claims = {
  CLAIMS_CASE_PAY_TO_CHOICES: [
    {
      value: 'pay_to_landlord',

      label: 'Pay to landlord'
    },

    {
      value: 'pay_to_agent',

      label: 'Pay to agent'
    }
  ],
  CLAIMS_FIRST_RESPONSE_DEADLINE_DAYS: 10,
  CLAIMS_INDEPENDENT_DISPUTE_RESOLUTION_DEADLINE_HOURS: 36,
  CLAIMS_PAYMENT_DEADLINE_DAYS: 5,
  CLAIMS_SUBMISSION_DEADLINE_DAYS: 28,
  CLAIM_AMOUNT_MAX_VALIDATION_MESSAGE: 'The amount can not be greater than £9999.00',
  CLAIM_AMOUNT_MIN_VALIDATION_MESSAGE: 'The amount can not be less than £1.00',
  COUNTERABLE_CLAIM_STATUSES: ['sent', 'countered', 'counter_declined'],
  HELLOSIGN_MANAGE_URL_TEMPLATE: 'https://app.hellosign.com/home/manage?guid={}',
  HELLOSIGN_SIGN_URL_TEMPLATE: 'https://app.hellosign.com/sign/{}',
  LANDLORD_OFFER_STATUS_TO_DATE_MAPPING: {
    pending_approval: 'created_on',
    pending_internal_signature: 'approved_on',
    pending_landlord_signature: 'offer_made_on',
    pending_offer_payment: 'landlord_signed_on',
    completed: 'offer_paid_on',
    cancelled: 'cancelled_on'
  },
  MAX_ARREARS_COVER_WEEKS: 6,
  MAX_CLAIM_AMOUNT_IN_CENTS: 999900,
  MAX_CLAIM_COUNTER_AMOUNT_IN_CENTS: 999900,
  MIN_CLAIM_AMOUNT_IN_CENTS: 0,
  MIN_CLAIM_COUNTER_AMOUNT_IN_CENTS: 0,
  NON_COUNTERABLE_CLAIM_STATUSES: [
    'created',
    'canceled',
    'waived',
    'disputed',
    'adjudicated',
    'accepted',
    'deemed_accepted',
    'counter_accepted',
    'paid'
  ],
  PAY_TO_AGENT: 'pay_to_agent',
  PAY_TO_LANDLORD: 'pay_to_landlord'
};
