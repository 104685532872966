<template>
  <div ref="select" :data-testid="TEST_IDS.WRAPPER">
    <FpLabel v-if="label" :id="ariaLabel">
      <FpLabelText>{{ label }}</FpLabelText>
    </FpLabel>
    <FpSelect
      :aria-labelledby="ariaLabel"
      :disabled="disabled"
      :active="active"
      :error="error"
      :data-testid="TEST_IDS.SELECT"
      @keyup.enter="toggleActiveState()"
      @keyup.esc="closeSelect()"
      tabindex="0"
      role="button"
    >
      <FpSelectValue :data-testid="TEST_IDS.VALUE" @click="toggleActiveState()">
        {{ selectText }}
        <FpSelectValueMultiple v-if="selectedAmount > 1">
          +{{ selectedAmount - 1 }} more
        </FpSelectValueMultiple>
      </FpSelectValue>
      <FpSelectArrow :data-testid="TEST_IDS.ARROW" @click="toggleActiveState()" />
      <FpSelectList
        :aria-expanded="active"
        :rows="rows"
        :data-testid="TEST_IDS.LIST"
        v-show="active"
        multiple
        role="listbox"
      >
        <FpSelectMultiple>
          <FpLabel>
            <FpLabelText>
              Select one or multiple values
            </FpLabelText>
          </FpLabel>
        </FpSelectMultiple>
        <FpSelectCheckboxListOption
          :selected="isAllSelected"
          @keyup.enter.stop="selectSingle(option)"
          tabindex="0"
          role="listitem"
        >
          <FfCheckbox :checked="isAllSelected" @change="selectAll()" indeterminate>
            {{ selectAllText }}
          </FfCheckbox>
        </FpSelectCheckboxListOption>
        <FpSelectCheckboxListOption
          v-for="(option, i) of options"
          :key="field + '-option-' + i"
          :value="option.value"
          :tabindex="!option.disabled ? 0 : -1"
          :disabled="option.disabled"
          :selected="!!selectedOptions[option.value]"
          @keyup.enter.stop="selectSingle(option)"
          @focusout="closeOnTabOut(option)"
          role="listitem"
        >
          <FfCheckbox
            :checked="!!selectedOptions[option.value]"
            :disabled="option.disabled"
            :value="option.value"
            @change="selectSingle(option)"
          >
            {{ option.label }}
          </FfCheckbox>
        </FpSelectCheckboxListOption>
      </FpSelectList>
    </FpSelect>
    <slot />
  </div>
</template>

<script>
import {
  FpLabel,
  FpLabelText,
  FpSelect,
  FpSelectArrow,
  FpSelectCheckboxListOption,
  FpSelectList,
  FpSelectMultiple,
  FpSelectValue,
  FpSelectValueMultiple
} from '@flatfair/vue-fairplay/atoms';
import FfCheckbox from '@/fairplay/checkbox';

export const TEST_IDS = {
  WRAPPER: 'multiSelect_wrapper',
  SELECT: 'multiSelect_select',
  LIST: 'multiSelect_selectList',
  OPTION: 'multiSelect_selectOption',
  VALUE: 'multiSelect_selectValue',
  ARROW: 'multiSelect_selectArrow'
};

export default {
  components: {
    FpLabel,
    FpLabelText,
    FpSelect,
    FpSelectValue,
    FpSelectValueMultiple,
    FpSelectArrow,
    FpSelectList,
    FpSelectCheckboxListOption,
    FpSelectMultiple,
    FfCheckbox
  },
  props: {
    label: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    defaultText: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: null
    },
    error: {
      type: [String, Number, Object, Date, Boolean],
      default: null
    },
    rows: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      active: false,
      searchInput: '',
      selectedOptions: {},
      TEST_IDS
    };
  },
  computed: {
    selectText() {
      return this.selectedAmount ? Object.values(this.selectedOptions)[0].label : '';
    },
    selectedAmount() {
      return Object.values(this.selectedOptions).length;
    },
    ariaLabel() {
      return `${this.field}-select`;
    },
    selectableOptions() {
      return this.options.filter(option => !option.disabled);
    },
    hasError() {
      return !!this.error;
    },
    isAllSelected() {
      return Object.values(this.selectedOptions).length === this.enabledOptionsAmount;
    },
    selectAllText() {
      return this.isAllSelected ? 'Unselect all' : 'Select all';
    },
    enabledOptionsAmount() {
      return Object.values(this.options).filter(
        option =>
          (this.value && this.value.find(value => value === option.value)) || !option.disabled
      ).length;
    }
  },
  created() {
    if (this.value) {
      this.value.forEach(value => {
        this.selectedOptions[value] = this.options.find(option => option.value === value);
      });
    }
    window.addEventListener('click', this.closeOnOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeOnOutsideClick);
  },
  methods: {
    isSelected({ value }) {
      return this.selectedOption && this.selectedOption.value === value;
    },
    _detectLastOption(option) {
      return option === this.selectableOptions[this.selectableOptions.length - 1];
    },
    toggleActiveState() {
      this.active = !this.active;
      if (!this.active) {
        this.searchInput = '';
      }
    },
    openSelect() {
      this.active = true;
    },
    closeSelect() {
      this.active = false;
      this.searchInput = '';
    },
    selectSingle(option) {
      const { value } = option;

      if (!option.disabled) {
        const selectedOptions = { ...this.selectedOptions };

        if (selectedOptions[value]) {
          delete selectedOptions[value];
        } else {
          selectedOptions[value] = option;
        }

        this.selectedOptions = { ...selectedOptions };

        this.$emit('input', Object.values(this.selectedOptions).map(selected => selected.value));
      }
    },
    selectAll() {
      const selectedOptions = {};
      if (this.isAllSelected) {
        Object.values(this.selectedOptions).forEach(selectedOption => {
          if (selectedOption.disabled) {
            selectedOptions[selectedOption.value] = selectedOption;
          }
        });
        this.selectedOptions = { ...selectedOptions };
      } else {
        this.options.forEach(option => {
          if (!option.disabled && !this.selectedOptions[option.value]) {
            selectedOptions[option.value] = option;
          }
        });
        this.selectedOptions = { ...this.selectedOptions, ...selectedOptions };
      }

      this.$emit('input', Object.values(this.selectedOptions).map(selected => selected.value));
    },
    closeOnOutsideClick(event) {
      const selectElement = this.$refs.select;
      const target = event.target;
      if (!target.closest('[ref="select"]') && !selectElement.contains(target)) {
        this.closeSelect();
      }
    },
    closeOnTabOut(option) {
      if (this._detectLastOption(option)) {
        this.closeSelect();
      }
    }
  }
};
</script>
