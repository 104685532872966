export default {
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number
    },
    step: {
      type: Number,
      default: 1
    },
    defaultValue: {
      type: Number,
      default: 1
    }
  },
  data: function() {
    return {
      value: this.defaultValue
    };
  },
  methods: {
    increase: function() {
      if (this.value + this.step <= this.max) {
        this.value += this.step;
        this.propagateChange();
      }
    },
    decrease: function() {
      if (this.value - this.step >= this.min) {
        this.value -= this.step;
        this.propagateChange();
      }
    },
    propagateChange: function() {
      this.$emit('input', this.value);
    }
  }
};
