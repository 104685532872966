// This mixin assembles 1 object with error messages mapped to fields and 1 array with generic backend errors.
//
// this.backendValidations is an object that contains the errors organised per field that we can map for the list
// of fields defined on this.backendValidationFields
//
// this.genericBackendValidations is an array that contains the list of all the errors that we can NOT map for
// the list of fields defined on this.backendValidationFields

import { FORM_CHECK_ERRORS_BELOW } from '@/libs/utils/constants';

export default {
  data() {
    return {
      backendValidations: {},
      genericBackendValidations: [],
      backendValidationFields: []
    };
  },
  mounted() {
    this._assembleEmptyBackendValidations();
  },
  methods: {
    resetBackendValidations() {
      this._assembleEmptyBackendValidations();
    },
    assembleBackendValidations(response) {
      this.resetBackendValidations();

      this.genericBackendValidations = [];

      const backendErrors = response.data;

      const genericErrors = Object.keys(backendErrors).reduce(
        (errorMessagesNotMappedToFields, fieldWithBackendErrors) => {
          const isFieldOnListOfFieldsToMap = this.backendValidationFields.includes(
            fieldWithBackendErrors
          );

          if (isFieldOnListOfFieldsToMap) {
            this._assembleMessagesForMappedField(
              backendErrors,
              fieldWithBackendErrors,
              this.backendValidations
            );

            return errorMessagesNotMappedToFields;
          }

          this._assembleMessagesForGenericErrors(
            backendErrors,
            fieldWithBackendErrors,
            errorMessagesNotMappedToFields
          );

          return errorMessagesNotMappedToFields;
        },
        []
      );

      if (genericErrors.length === 0) {
        genericErrors.push(FORM_CHECK_ERRORS_BELOW);
      }

      this.genericBackendValidations = genericErrors;
    },
    _assembleEmptyBackendValidations() {
      this.backendValidationFields.forEach(field => {
        this.$set(this.backendValidations, field, []);
      });
    },
    _assembleMessagesForMappedField(errorsPerField, field, validationsObject) {
      if (Array.isArray(errorsPerField[field])) {
        errorsPerField[field].forEach(error => {
          validationsObject[field].push(error);
        });
      } else {
        validationsObject[field].push(errorsPerField[field]);
      }
    },
    _assembleMessagesForGenericErrors(errorsPerField, field, errorMessages) {
      if (Array.isArray(errorsPerField[field])) {
        errorsPerField[field].forEach(error => {
          errorMessages.push(error);
        });
      } else {
        errorMessages.push(errorsPerField[field]);
      }
    }
  }
};
