import { PropertyApplicationApiBase } from './base';

export class PropertyApplicationApi extends PropertyApplicationApiBase {
  approveApplication(applicationId) {
    return super.post(`${applicationId}/approve/`, {}, 'Failed to approve property application');
  }

  conditionallyApproveApplication(applicationId, description, condition) {
    const data = {
      description: description,
      condition: condition
    };

    return super.post(
      `${applicationId}/conditionally-approve/`,
      data,
      'Failed to conditionally approve property application'
    );
  }

  rejectApplication(applicationId) {
    return super.post(`${applicationId}/reject/`, {}, 'Failed to reject property application');
  }
}
