export default {
  props: {
    document: {
      type: Object,
      required: false,
      deep: true
    }
  },
  computed: {
    relativeUrl: function() {
      return `/api/private/v1/document/internal/generic/${this.document.id}/downloadable-link/`;
    },
    hasDocument: function() {
      return this.document !== null;
    },
    linkName: function() {
      return this.document ? this.document.file_name : '-';
    }
  }
};
