<template>
  <FpLabel>
    <FpLabelText>{{ label }}</FpLabelText>
    <FpInputGroup>
      <FpInput
        :value="value"
        @input="onInput"
        :disabled="disabled"
        :error="error"
        inputmode="numeric"
        pattern="[0-9]*"
        data-testid="fairplay-input"
      />
      <FpInputPrepend>
        <FpIcon :code="prependIcon" :icon-style="IconStyle.solid" />
      </FpInputPrepend>
      <FpInputFloatingControl v-if="!!value" @click.prevent="onClear">
        <FpIcon :code="controlIcon" :icon-style="IconStyle.solid" />
      </FpInputFloatingControl>
    </FpInputGroup>
    <slot />
  </FpLabel>
</template>

<script>
import {
  FpIcon,
  FpInput,
  FpInputFloatingControl,
  FpInputGroup,
  FpInputPrepend,
  FpLabel,
  FpLabelText,
  IconCode,
  IconStyle
} from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FpLabel,
    FpLabelText,
    FpInput,
    FpInputGroup,
    FpInputPrepend,
    FpInputFloatingControl,
    FpIcon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prependIcon: IconCode.poundSign,
      controlIcon: IconCode.timesCircle,
      IconStyle
    };
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    onClear() {
      this.$emit('input', '');
    }
  }
};
</script>
