import { resolveSuggestedDecisionToObject } from '@/libs/utils/helpers';
import { getReferenceStatusObject } from '@/components/apps/referencing/utils';

export default {
  props: {
    reference: Object
  },
  mounted() {
    this.detectDescriptionSize();
    window.addEventListener('resize', this.detectDescriptionSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectDescriptionSize);
  },
  data: function() {
    return {
      description: '',
      descriptionIsOverflowing: false,
      showFullDescription: false
    };
  },
  computed: {
    referenceStatus() {
      return this.reference ? getReferenceStatusObject(this.reference.status) : {};
    },
    suggestedDecision() {
      return resolveSuggestedDecisionToObject(this.reference);
    },
    decisionDescription() {
      if (
        this.reference &&
        this.reference.referencedecision &&
        this.reference.referencedecision.description
      ) {
        return this.reference.referencedecision.description;
      }
      return '';
    },
    userEmail() {
      return this.reference ? this.reference.user_email : '';
    }
  },
  methods: {
    detectDescriptionSize() {
      if (this.showFullDescription === false) {
        this.descriptionIsOverflowing =
          this.$refs.descriptionText.scrollHeight > this.$refs.descriptionText.clientHeight;
      }
    },
    collapseDescription() {
      this.showFullDescription = !this.showFullDescription;
    }
  }
};
