/**
 * based on https://github.com/Coffcer/vue-bootstrap-modal  upgraded to work with vue 2.x
 * Bootstrap Style Modal Component for Vue
 * Depend on Bootstrap.css
 */
export default {
  props: {
    show: {
      type: Boolean,
      twoWay: true,
      default: false
    },
    title: {
      type: String,
      default: 'Modal'
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },

    force: {
      type: Boolean,
      default: false
    },

    transition: {
      type: String,
      default: 'fade'
    },

    okText: {
      type: String,
      default: 'OK'
    },

    cancelText: {
      type: String,
      default: 'Cancel'
    },

    okClass: {
      type: String,
      default: 'btn btn-mobile btn-success'
    },

    cancelClass: {
      type: String,
      default: 'btn btn-mobile btn-outline b-info text-info'
    },

    closeWhenOK: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      duration: null
    };
  },
  computed: {
    modalClass() {
      return {
        'modal-lg': this.large,
        'modal-sm': this.small,
        'modal-full': this.full
      };
    }
  },
  created() {
    if (this.show) {
      document.body.className += ' modal-open';
    }
  },
  beforeDestroy() {
    document.body.className = document.body.className.replace(/\s?modal-open/, '');
  },
  watch: {
    show(value) {
      if (value) {
        document.body.className += ' modal-open';
      } else {
        if (!this.duration) {
          this.duration =
            window
              // eslint-disable-next-line prettier/prettier
							.getComputedStyle(this.$refs.dialog)['transition-duration'].replace('s', '') * 1000;
        }
        window.setTimeout(() => {
          document.body.className = document.body.className.replace(/\s?modal-open/, '');
        }, this.duration || 0);
      }
    }
  },
  methods: {
    ok() {
      this.$emit('ok');
      if (this.closeWhenOK) {
        this.$emit('update:show', false);
      }
    },
    cancel() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },

    clickMask() {
      if (!this.force) {
        this.cancel();
      }
    }
  }
};
