<template>
  <BreadCrumb @click="handleClick" :href="href" tabindex="0" data-testid="breadcrumb-wrapper">
    <Icon :code="iconCode.angleLeft" :iconStyle="iconStyle.solid" />
    <BreadCrumbText
      >{{ label }}
      <slot />
    </BreadCrumbText>
  </BreadCrumb>
</template>

<script>
import {
  BreadCrumb,
  BreadCrumbText,
  Icon,
  IconCode,
  IconStyle
} from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    BreadCrumb,
    BreadCrumbText,
    Icon
  },

  props: {
    href: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    iconCode() {
      const { angleLeft } = IconCode;
      return { angleLeft };
    },

    iconStyle() {
      const { solid } = IconStyle;
      return { solid };
    }
  },

  methods: {
    handleClick() {
      if (!this.href) this.$emit('click');
    }
  }
};
</script>
