import {
  Body as FpBody,
  Header1 as FpHeader1,
  Header2 as FpHeader2,
  Header3 as FpHeader3,
  Header4 as FpHeader4
} from '@flatfair/vue-fairplay/atoms';

export const Header1 = {
  template: `
    <fp-header1 :margin-bottom="4">
      <slot></slot>
    </fp-header1>
  `,
  components: { 'fp-header1': FpHeader1 }
};

export const Header2 = {
  template: `
    <fp-header2 :margin-bottom="3">
      <slot></slot>
    </fp-header2>
  `,
  components: { 'fp-header2': FpHeader2 }
};

export const Header3 = {
  template: `
    <fp-header3 :margin-bottom="2">
      <slot></slot>
    </fp-header3>
  `,
  components: { 'fp-header3': FpHeader3 }
};

export const Header4 = {
  template: `
    <fp-header4 :margin-bottom="2">
      <slot></slot>
    </fp-header4>
  `,
  components: { 'fp-header4': FpHeader4 }
};

export const Body1 = {
  template: `
    <fp-body :margin-bottom="1">
      <slot></slot>
    </fp-body>
  `,
  components: { 'fp-body': FpBody }
};
