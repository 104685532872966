export const TitleBarMixin = {
  props: {
    eventBus: {
      required: true,
      type: Object
    }
  },
  beforeRouteLeave(to, from, next) {
    this.eventBus.$off('primaryAction');
    next();
  },
  methods: {
    listenForPrimaryActionClick(primaryActionFunction) {
      this.eventBus.$on('primaryAction', () => primaryActionFunction());
    },
    setTitleBarTitle(title) {
      this.eventBus.$emit('update:pageTitle', title);
    },
    setTitleBarSubtitle(subtitle) {
      this.eventBus.$emit('update:pageSubtitle', subtitle);
    },
    setTitleBarButtonText(buttonText) {
      this.eventBus.$emit('update:buttonText', buttonText);
    },
    setTitleBarButtonClasses(buttonClassArray) {
      this.eventBus.$emit('update:buttonClassObject', buttonClassArray);
    },
    setTitleBarButtonIconClass(iconClass) {
      this.eventBus.$emit('update:buttonIconClass', iconClass);
    },
    setTitleBarIconElement(elementString) {
      this.eventBus.$emit('update:iconElement', elementString);
    }
  }
};
