import FlatfairModal from 'elements/modal/modal.vue';
import FlatfairInput from 'elements/input/input.vue';
import FlatfairErrorPopup from 'elements/error_popup/error_popup.vue';
import { FlatfairError } from 'libs/error';
import VueElementLoading from 'vue-element-loading';
import { InternalEventNotesApi } from 'libs/apis';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  NOTE_TYPE,
  NOTE_TYPE_OPTIONS,
  VERIFICATION_OPTIONS as VERIFICATION_ISSUE_OPTIONS
} from 'libs/constants/generatedConstants';
import { showNotification } from 'libs/utils/helpers';
import { EVENT_NAMESPACE } from 'libs/utils/constants';
import { ErrorMixin } from 'libs/mixins';

export default {
  props: {
    csrf: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    }
  },
  mixins: [ErrorMixin],
  data() {
    let typeOptions = NOTE_TYPE_OPTIONS.OPTIONS;

    if (this.namespace !== EVENT_NAMESPACE.FLATBOND) {
      typeOptions = typeOptions.filter(option => option.value !== NOTE_TYPE.VERIFICATION_ISSUE);
    }

    return {
      eventNotesApi: new InternalEventNotesApi(this),
      creatingNote: false,
      note: null,
      typeOptions,
      selectedType: null,
      issueOptions: VERIFICATION_ISSUE_OPTIONS.OPTIONS,
      selectedIssue: null,
      show: false
    };
  },
  validations: {
    note: {
      required
    },
    selectedType: {
      required
    },
    selectedIssue: {
      required: requiredIf(form => {
        return form.$data.selectedType
          ? form.$data.selectedType.value === NOTE_TYPE.VERIFICATION_ISSUE
          : false;
      })
    }
  },
  computed: {
    showIssue() {
      return this.selectedType ? this.selectedType.value === NOTE_TYPE.VERIFICATION_ISSUE : false;
    }
  },
  methods: {
    showCreateNoteModal: function() {
      this.show = true;
    },
    create() {
      this._createNote();
    },
    cancel() {
      this._hideCreateNoteModal();
    },
    _hideCreateNoteModal() {
      this.note = null;
      this.selectedType = null;
      this.selectedIssue = null;
      this.show = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    _createNote() {
      this.creatingNote = true;

      return this.eventNotesApi
        .create(
          this.namespace,
          this.note,
          this.selectedType.value,
          this.selectedIssue ? this.selectedIssue.value : null,
          this.id
        )
        .then(() => {
          showNotification(this, 'Note saved', 'success');
          this.$emit('noteCreated');
        })
        .catch(error => {
          this.showError = true;
          this.errorDetails = new FlatfairError(
            error,
            'There has been an error creating the note.'
          );
        })
        .finally(() => {
          this.creatingNote = false;
          this._hideCreateNoteModal();
        });
    }
  },
  components: {
    'ff-modal': FlatfairModal,
    'ff-input': FlatfairInput,
    'ff-error-popup': FlatfairErrorPopup,
    'vue-element-loading': VueElementLoading
  }
};
