import { oneOf } from 'libs/utils/properties';
import FlatfairBox from 'elements/box/box.vue';
import NoteCreate from 'components/event_log/note_create/note_create.vue';
import NoteList from 'components/event_log/note_list/note_list.vue';
import { EVENT_NAMESPACE } from 'libs/utils/constants';

export default {
  props: {
    csrf: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true,
      validator: oneOf(EVENT_NAMESPACE)
    }
  },
  methods: {
    refreshNotes() {
      this.$refs.noteList.refreshNotes();
    }
  },
  components: {
    'ff-box': FlatfairBox,
    'note-create': NoteCreate,
    'note-list': NoteList
  }
};
