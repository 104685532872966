import { InternalFlatbond } from 'libs/api';
import VueElementLoading from 'vue-element-loading';

export default {
  props: {
    flatbondId: {
      required: true
    },
    userId: {
      required: true
    }
  },
  data() {
    return {
      api: new InternalFlatbond(),
      roles: {},
      loading: true,
      error: null
    };
  },
  mounted() {
    this._fetchRoles();
  },
  methods: {
    _fetchRoles() {
      this.loading = true;
      this.api
        .getUserRoles(this.flatbondId, this.userId)
        .then(({ data }) => {
          this.roles = data;
        })
        .catch(err => {
          this.error = err;
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  components: {
    'vue-element-loading': VueElementLoading
  }
};
