<template>
  <FpProgressBar
    :mobile-only="mobileOnly"
    :desktop-only="desktopOnly"
    class="progress-bar"
    data-testid="progress-bar-wrapper"
  >
    <FpProgress :percentage="percentage" />
  </FpProgressBar>
</template>

<script>
import { FpProgress, FpProgressBar } from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FpProgress,
    FpProgressBar
  },
  props: {
    percentage: {
      type: Number,
      required: true
    },
    mobileOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    desktopOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
