import { logMessageOnSentry } from 'libs/utils/helpers';

export class FlatfairConstant {
  constructor(definition) {
    this.constantDefinition = definition;
  }

  _findValueInConstantDefinition(value) {
    const constantValue = this.constantDefinition.find(constant => {
      return constant.value === value;
    });

    return constantValue;
  }

  getLabel(value) {
    const constantObject = this._findValueInConstantDefinition(value);

    if (constantObject) {
      return constantObject.label;
    }

    logMessageOnSentry(`No label found in constants for value: ${value}`);
    return `Unknown label for ${value}`;
  }

  getBody(value) {
    const constantObject = this._findValueInConstantDefinition(value);

    if (constantObject) {
      return constantObject.body;
    }

    logMessageOnSentry(`No body text found in constants for value: ${value}`);
    return `Unknown body text for ${value}`;
  }

  getIcon(value) {
    const constantObject = this._findValueInConstantDefinition(value);

    if (constantObject) {
      return constantObject.iconClass;
    }

    logMessageOnSentry(`No icon class found in constants for value: ${value}`);
    return ``;
  }

  getColour(value) {
    const constantObject = this._findValueInConstantDefinition(value);

    if (constantObject) {
      return constantObject.colourClass;
    }
    logMessageOnSentry(`No colour class found in constants for value: ${value}`);
    return ``;
  }
}
