import FlatfairModal from 'elements/modal/modal.vue';
import { FlatfairError } from 'libs/error';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    details: {
      type: Object,
      required: false,
      default: function() {
        return new FlatfairError(null, null);
      }
    }
  },
  data: function() {
    return {
      showErrorModel: false
    };
  },
  watch: {
    show: function() {
      if (this.show) {
        this.showErrorModel = true;
      }
    }
  },
  methods: {
    acknowledge: function() {
      this.showErrorModel = false;
      this.$emit('errorAcknowledged');
    }
  },
  components: {
    'ff-modal': FlatfairModal
  }
};
