import axios from 'axios';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import * as Sentry from '@sentry/browser';

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Postcode'
    },
    showManual: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      message: null,
      postcodeToSearch: null,
      loading: false,
      addressesOptions: [],
      selectedAddress: null,
      freshResults: false,
      insertManualButtonVisible: true
    };
  },
  watch: {
    selectedAddress: function(address) {
      if (!this.selectedAddress) {
        return this.$emit('deselected');
      }

      this.insertManualButtonVisible = false;
      const { label, ...fullAddressProperties } = address;

      this.$emit('selected', {
        address: label,
        postcode: this.postcodeToSearch,
        ...fullAddressProperties
      });
    }
  },
  methods: {
    findAddress: function() {
      this.message = '';
      this.freshResults = false;
      this.selectedAddress = null;
      this.insertManualButtonVisible = true;
      const postcodeToSearch = this.postcodeToSearch;

      if (!postcodeToSearch) {
        this.message = 'You need to provide a postcode to search for an address';
        return this.message;
      }

      this.loading = true;
      return this._callFindAddressAPI(postcodeToSearch);
    },
    insertManual: function() {
      this.freshResults = false;
      this.postcodeToSearch = null;
      this.message = null;
      this.$emit('manual');
    },
    _callFindAddressAPI: function(postcode) {
      const options = {
        params: {
          'api-key': '6r7bM5df_EaajpRw2K9EPA10627',
          'expand': true
        }
      };

      return axios
        .get(`https://api.getAddress.io/find/${postcode.trim()}`, options)
        .then(response => {
          this.addresses = response.data.addresses;
          this.addressesOptions = response.data.addresses.map(address => {
            const { formatted_address: formattedAddress, ...rest } = address;

            return {
              ...formattedAddress,
              ...rest,
              label: formattedAddress
                .filter(Boolean)
                .join(', ')
                .trim()
            };
          });
          this.freshResults = true;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.message = 'Please recheck the postcode provided, it seems to be incorrect';
          error.name = 'GetAddressIoError';
          Sentry.captureException(error, { extra: this.$data });
        });
    }
  },
  components: {
    'v-select': VueSelect
  }
};
