import FetchMixin from 'libs/mixins/fetchMixin.js';
import FormattedCurrency from 'elements/formatted_currency/formatted_currency.vue';
import { InternalFlatbond } from 'libs/api';

export default {
  mixins: [FetchMixin],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      api: new InternalFlatbond(this)
    };
  },
  methods: {
    fetch() {
      return this.api.charges(this.id).then(({ data }) => data);
    }
  },
  components: {
    'formatted-currency': FormattedCurrency
  }
};
