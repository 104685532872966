export class TableDefinition {
  constructor() {
    this.row_definitions = [];
    this.empty_message = '';
  }

  _addRowDefinition(row, valueFunction, isHidden) {
    const rowDefinition = {
      definition: { name: row, isHidden: isHidden },
      valueFunction: valueFunction,
      elementFunction: () => {
        return {};
      }
    };
    this.row_definitions.push(rowDefinition);

    return rowDefinition;
  }

  addSimpleRow(row, valueFunction, isHidden = false) {
    this._addRowDefinition(row, valueFunction, isHidden);

    return this;
  }

  addLink(row, valueFunction, linkFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, valueFunction, isHidden);
    rowDefinition.elementFunction = linkFunction;
    rowDefinition.definition.isLink = true;

    return this;
  }

  addFlatbondUserRole(row, elementFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, () => '', isHidden);
    rowDefinition.elementFunction = elementFunction;
    rowDefinition.definition.isFlatbondUserRole = true;
    return this;
  }

  addAction(row, valueFunction, actionFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, valueFunction, isHidden);
    rowDefinition.elementFunction = actionFunction;
    rowDefinition.definition.isAction = true;

    return this;
  }

  addLabel(row, valueFunction, labelFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, valueFunction, isHidden);
    rowDefinition.elementFunction = labelFunction;
    rowDefinition.definition.isLabel = true;

    return this;
  }

  addSymbol(row, valueFunction, symbolFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, valueFunction, isHidden);
    rowDefinition.elementFunction = symbolFunction;
    rowDefinition.definition.isSymbol = true;

    return this;
  }

  addDropzone(row, valueFunction, dropzoneFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, valueFunction, isHidden);
    rowDefinition.elementFunction = dropzoneFunction;
    rowDefinition.definition.isDropzone = true;

    return this;
  }

  addInternalGenericDocumentDownload(row, valueFunction, downloadFunction, isHidden = false) {
    let rowDefinition = this._addRowDefinition(row, valueFunction, isHidden);
    rowDefinition.elementFunction = downloadFunction;
    rowDefinition.definition.isInternalGenericDocumentDownload = true;

    return this;
  }

  setEmptyMessage(message = 'No records found.') {
    this.empty_message = message;

    return this;
  }

  build(data) {
    const columns = this.row_definitions.reduce((object, rowItem, index) => {
      object[index] = rowItem.definition;
      return object;
    }, {});
    const rows = data.map(dataItem => {
      if (!dataItem) {
        return {};
      }
      return this.row_definitions.reduce((object, rowItem, index) => {
        object[index] = { value: rowItem.valueFunction(dataItem) };
        const elementValue = rowItem.elementFunction(dataItem);
        Object.keys(elementValue).forEach(key => {
          object[index][key] = elementValue[key];
        });
        return object;
      }, {});
    });
    return {
      emptyMessage: this.empty_message,
      columns,
      rows
    };
  }
}
