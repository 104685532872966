import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import VueContentPlaceholders from 'vue-content-placeholders';
import VueElementLoading from 'vue-element-loading';
import Paginate from 'vuejs-paginate';
import VueIntl from '@/plugins/VueIntl';
import { resolveConstant } from './utils/helpers';
import ProgressBar from '@/elements/progress_bar/progress_bar.vue';
import Alert from 'elements/alert/alert.vue';
import { Header1, Header2, Header3, Header4 } from '@/elements/headings';
import SectionBox from '@/elements/section_box/section_box.vue';
import VueRouter from 'vue-router';
import Separator from '@/elements/separator/separator.vue';
import {
  Button,
  ButtonLink,
  Buttons,
  ButtonText,
  Container,
  Hint,
  Input,
  Label,
  LabelText,
  Link,
  LinkText,
  RadioButton,
  RadioButtonLabel,
  Slider,
  ToggleButton,
  ToggleButtonRoot,
  ToggleButtonText
} from '@flatfair/vue-fairplay/atoms';
import ThemeProvider from 'elements/theme_provider.vue';
import VueCurrencyInput from 'vue-currency-input';
import { FLATBOND_CURRENCIES } from '@/libs/constants/generatedConstants';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: resolveConstant('SENTRY_DSN'),
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: window.ENV
  });

  Sentry.configureScope(function(scope) {
    scope.setUser({
      email: window.userEmail,
      organisation: window.org,
      name: window.userName
    });
  });
}

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: FLATBOND_CURRENCIES.GBP,
    distractionFree: {
      hideNegligibleDecimalDigits: true,
      hideCurrencySymbol: false,
      hideGroupingSymbol: true
    },
    valueAsInteger: true,
    autoDecimalMode: false
  }
});
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.use(VueContentPlaceholders);
Vue.use(Vuelidate);
Vue.use(VueIntl());

// 3rd party
Vue.component('paginate', Paginate);
Vue.component('VueElementLoading', VueElementLoading);

// fairplay
Vue.component('ff-button', Button);
Vue.component('ff-button-text', ButtonText);
Vue.component('ff-button-link', ButtonLink);
Vue.component('ff-alert', Alert);
Vue.component('ff-buttons', Buttons);
Vue.component('ff-link', Link);
Vue.component('ff-link-text', LinkText);
Vue.component('ff-radio-button', RadioButton);
Vue.component('ff-radio-button-label', RadioButtonLabel);
Vue.component('fp-input', Input); // ff-input already exists
Vue.component('fp-label', Label);
Vue.component('fp-label-text', LabelText);
Vue.component('fp-input-hint', Hint);
Vue.component('fp-toggle-button', ToggleButton);
Vue.component('fp-toggle-button-root', ToggleButtonRoot);
Vue.component('fp-toggle-button-text', ToggleButtonText);
Vue.component('fp-toggle-slider', Slider);

// @/elements
Vue.component('section-box', SectionBox);
Vue.component('ff-theme-provider', ThemeProvider);
Vue.component('ff-container', Container);
Vue.component('ff-header1', Header1);
Vue.component('ff-header2', Header2);
Vue.component('ff-header3', Header3);
Vue.component('ff-header4', Header4);
Vue.component('ff-progress-bar', ProgressBar);
Vue.component('separator', Separator);
export default Vue.extend({
  delimiters: ['[[', ']]']
});
