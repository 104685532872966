import enGb from '@/locale/en-gb.json';
import enUs from '@/locale/en-us.json';
import config from '@/libs/utils/config';
import 'intl';
import 'intl/locale-data/jsonp/en.js';
import { IntlMessageFormat } from 'intl-messageformat';

export function loadLocale(locale, rawMessages) {
  return Object.keys(rawMessages).reduce(
    (acc, key) => ({
      ...acc,
      [key]: new IntlMessageFormat(rawMessages[key], locale)
    }),
    {}
  );
}

const messagesMap = {
  'en-gb': locale => loadLocale(locale, enGb),
  'en-us': locale => loadLocale(locale, { ...enGb, ...enUs })
};

const findLocale = ({ locale, messages }) => {
  const currentLocale = locale || config.locale.toLowerCase();
  const currentMessages = (messages || messagesMap[currentLocale])(locale);
  return { currentLocale, currentMessages };
};

export default ({ locale, messages } = {}) => {
  const { currentLocale, currentMessages } = findLocale({ locale, messages });
  const t = (key, values, defaultValue) => {
    const msg = currentMessages[key];
    if (!msg) {
      return defaultValue || key;
    }
    return msg.format(values);
  };
  const td = (date, options) => {
    return Intl.DateTimeFormat(currentLocale, options).format(date);
  };
  const tn = (number, options) => {
    return Intl.NumberFormat(currentLocale, options).format(number);
  };
  return {
    t,
    td,
    tn,
    install(Vue) {
      Vue.prototype.$t = t;
      Vue.prototype.$td = td;
      Vue.prototype.$tn = tn;
    }
  };
};

export const NUMBER_FORMAT_GBP = { style: 'currency', currency: 'GBP' };
export const NUMBER_FORMAT_USD = { style: 'currency', currency: 'USD' };
export const DATE_FORMAT_LONG = { dateStyle: 'long' };
export const DATE_FORMAT_DISPLAY = { day: 'numeric', month: 'short', year: 'numeric' };

export const IntlHtml = {
  props: {
    id: {
      type: String,
      required: true
    },
    variables: {
      type: Object,
      default: () => ({})
    },
    messages: {
      type: Function,
      default: null
    },
    locale: {
      type: String,
      default: null
    },
    tags: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    message() {
      const { currentMessages } = findLocale({ locale: this.locale, messages: this.messages });
      return currentMessages[this.id];
    }
  },
  methods: {
    tagRenderer(h) {
      return Object.keys(this.tags).reduce((acc, key) => {
        const tagHandler = this.tags[key];
        return {
          ...acc,
          [key]: (...children) => {
            if (Array.isArray(tagHandler)) {
              const [tag, domProps = {}] = tagHandler;
              return h(tag, { domProps }, children);
            } else {
              return tagHandler(h, children);
            }
          }
        };
      }, {});
    }
  },
  render(h) {
    return h('span', this.message.format({ ...this.variables, ...this.tagRenderer(h) }));
  }
};
