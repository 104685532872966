<template>
  <FfThemeProvider>
    <FpButton @click="openModal">
      <FpButtonText>
        {{ $t('reviewFlatbondTenants.updateDetails.openButton') }}
      </FpButtonText>
    </FpButton>
    <FfModal :show.sync="showModal">
      <div slot="title">{{ $t('reviewFlatbondTenants.updateDetails.modelTitle') }}</div>
      <div slot="modal-body">
        <FfInput
          v-model="tenant.firstName"
          :label="$t('reviewFlatbondTenants.updateDetails.labelFirstName')"
          name="firstName"
        ></FfInput>
        <FfInput
          v-model="tenant.lastName"
          :label="$t('reviewFlatbondTenants.updateDetails.labelLastName')"
          name="lastName"
        ></FfInput>
        <FfInput
          v-model="tenant.email"
          :label="$t('reviewFlatbondTenants.updateDetails.labelEmail')"
          name="email"
        ></FfInput>
      </div>
      <FpButtons slot="footer">
        <FpButton @click="onCancel" secondary>
          <FpButtonText>{{ $t('reviewFlatbondTenants.updateDetails.cancelButton') }}</FpButtonText>
        </FpButton>
        <FpButton :disabled="!isDetailChanged" @click="updateTenantDetails">
          <FpButtonText>{{ $t('reviewFlatbondTenants.updateDetails.confirmButton') }}</FpButtonText>
        </FpButton>
      </FpButtons>
    </FfModal>
  </FfThemeProvider>
</template>

<script>
import FfInput from 'elements/input/input.vue';
import FfModal from 'elements/modal/modal.vue';
import FfThemeProvider from '@/elements/theme_provider.vue';
import { FpButton, FpButtons, FpButtonText } from '@flatfair/vue-fairplay/atoms';
import { InternalTenantApi } from 'libs/apis';

export default {
  components: {
    FfInput,
    FfModal,
    FfThemeProvider,
    FpButton,
    FpButtons,
    FpButtonText
  },
  props: {
    tenantData: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      api: new InternalTenantApi(this),
      showModal: false,
      tenant: {
        firstName: '',
        lastName: '',
        email: ''
      }
    };
  },
  computed: {
    isDetailChanged() {
      return (
        this.tenant.firstName !== this.tenantData.first_name ||
        this.tenant.lastName !== this.tenantData.last_name ||
        this.tenant.email !== this.tenantData.email
      );
    }
  },
  methods: {
    async updateTenantDetails() {
      await this.api.updateDetails(this.tenantData.id, this.tenant);
      this.$emit('detailsUpdated');
      this.showModal = false;
    },
    openModal() {
      this.tenant = {
        firstName: this.tenantData.first_name,
        lastName: this.tenantData.last_name,
        email: this.tenantData.email
      };
      this.showModal = true;
    },
    onCancel() {
      this.showModal = false;
    }
  }
};
</script>
