import { InternalFlatbondApiBase } from './base';

export class InternalFlatbondApi extends InternalFlatbondApiBase {
  approveOnBehalfOfLandlord(flatbondId) {
    return super.post(
      `${flatbondId}/approve-on-landlord-behalf/`,
      {},
      'Failed to approve on landlords behalf'
    );
  }

  resetSignedDocuments(flatbondId) {
    return super.post(
      `${flatbondId}/reset-signed-documents/`,
      {},
      'Failed to reset signed documents'
    );
  }

  closeWithoutCharges(flatbondId) {
    return super.post(`${flatbondId}/close-without-claims/`, {}, 'Failed to close without charges');
  }

  updateFlatbondRentAmount(flatbondId, amount) {
    const data = {
      new_rent_per_week_in_cents: amount
    };

    return super.post(`${flatbondId}/update-rent/`, data, 'Failed to update rent amount');
  }

  retrieveVerificationProgress(flatbondId) {
    return super.get(
      `${flatbondId}/verification`,
      'Could not retrieve verification progress for flatbond'
    );
  }

  updateVerificationProgress(productId, progress) {
    const progressApiObject = {
      verified_address: progress.verifiedAddress,
      verified_dates: progress.verifiedDates,
      verified_members: progress.verifiedMembers,
      verified_rent: progress.verifiedRent,
      verified_documents: progress.verifiedDocuments,
      verified_previous_tenancies: progress.verifiedPreviousTenancies,
      verified_references_uploaded: progress.verifiedReferencesUploaded
    };

    return super.put(
      `${productId}/verification/${progress.id}/`,
      progressApiObject,
      'Failed to update verification progress for flatbond'
    );
  }

  activateFlatbond(productId) {
    return super.post(`${productId}/verify/`, 'Failed to activate flatbond');
  }

  preVerifyFlatbond(productId) {
    return super.post(`${productId}/pre-verify/`, 'Failed to pre-verify flatbond');
  }

  get(flatbondId) {
    return super.get(`${flatbondId}`, 'Failed to get flatbond');
  }

  disableTrustpilotReviews(flatbondId) {
    return super.put(
      `${flatbondId}/disable-trustpilot-reviews/`,
      {},
      'Failed to disable the trustpilot reviews'
    );
  }

  addTenant(flatbondId, email) {
    const data = {
      new_tenant_email: email
    };

    return super.post(`${flatbondId}/add-tenant/`, data, `Failed to add tenant to flatbond`);
  }
}
