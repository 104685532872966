import { FlatbondApiBase } from '@/libs/apis/flatbond';

export * from './base';
export * from './draft';
export * from './internal';
export * from './landlord';

export class FlatbondApi extends FlatbondApiBase {
  constructor(vue) {
    super(vue, 'flatbond', true);
    this.errorName = 'FlatbondApiError';
  }

  async getDocumentsToSign(flatbondId) {
    return this.get(`${flatbondId}/documents-to-sign/`);
  }

  list(queryParams = '') {
    return super.get(`/${queryParams}`);
  }

  get(flatbondId) {
    return super.get(`/${flatbondId}`);
  }
}
