import { ITEMS_PER_PAGE } from 'libs/utils/constants';
import { showNotification } from 'libs/utils/helpers';

export default {
  data() {
    return {
      totalCount: null,
      currentPage: 1,
      availablePages: 1,
      pages: []
    };
  },
  mounted() {
    this.selectPage(1);
  },
  computed: {
    currentResults() {
      return this.pages[this.currentPage] || [];
    },
    loading() {
      return !this.pages[this.currentPage];
    }
  },
  methods: {
    fetch() {
      throw new Error('Implement the fetch method when using the pagination mixin.');
    },
    async selectPage(page) {
      this.currentPage = Math.min(this.availablePages, Math.max(page, 1));
      try {
        const { count, results } = await this.fetch({ page, pageSize: ITEMS_PER_PAGE });
        const newPages = [...this.pages];
        newPages[page] = results;
        this.pages = newPages;
        this.totalCount = count;
        this.availablePages = Math.max(1, Math.ceil(count / ITEMS_PER_PAGE));
      } catch (err) {
        showNotification(this, 'Failed to fetch data', 'error');
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    reload() {
      this.selectPage(this.currentPage);
    }
  }
};
