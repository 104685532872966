import axios from 'axios';
import * as Sentry from '@sentry/browser';
import Box from 'elements/box/box.vue';
import Table from 'elements/table/table.vue';

export default {
  props: {
    djangoContext: {
      type: Object,
      required: true
    },
    csrf: {
      type: String,
      required: true
    },
    canPerformAction: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    const flatbondId = this.djangoContext.flatbond_id;
    const inviteEmail = this.djangoContext.landlord_invite_email;
    const landlord = this.djangoContext.landlord;

    const landlordDataForTable = {
      columns: {
        landlordEmail: {
          name: 'Landlord email'
        },
        isRegistered: {
          name: 'Registered',
          isSymbol: true
        },
        isSigned: {
          name: 'Signed',
          isSymbol: true
        },
        resendInvite: {
          name: 'Invite',
          isAction: true,
          isHidden: !this.canPerformAction
        }
      },
      rows: [
        {
          landlordEmail: {
            value: inviteEmail
          },
          isRegistered: {
            symbolClass: landlord.user ? 'fa-check' : 'fa-close',
            colourClass: landlord.user ? 'text-success' : 'text-danger'
          },
          isSigned: {
            symbolClass: landlord.has_signed_tc ? 'fa-check' : 'fa-close',
            colourClass: landlord.has_signed_tc ? 'text-success' : 'text-danger'
          },
          resendInvite: {
            value: 'Re-send',
            disabledValue: 'Accepted',
            action: this.resendInvite.bind(null),
            disabled: landlord.user !== null
          }
        }
      ]
    };

    return {
      landlordDataForTable,
      flatbondId: flatbondId,
      inviteEmail,
      landlord
    };
  },
  methods: {
    resendInvite: function() {
      const options = {
        headers: {
          'X-CSRFTOKEN': this.csrf
        }
      };

      return axios
        .post(`/api/private/v1/flatbond/${this.flatbondId}/send_landlord_invite/`, {}, options)
        .then(() => {
          this.landlordDataForTable.rows[0].resendInvite.disabledValue = 'Sent';
          this.landlordDataForTable.rows[0].resendInvite.disabled = true;

          this._showNotification('Invite sent', 'success');
        })
        .catch(error => {
          this._showNotification('Something went wrong', 'error');
          error.name = 'FlatbondSendLandlordInviteError';
          Sentry.captureException(error, { extra: this.$data });
        });
    },
    _showNotification: function(title, type) {
      this.$notify({
        group: 'landlord-invite',
        title,
        type,
        position: 'top-right'
      });
    }
  },
  components: {
    'box': Box,
    'v-table': Table
  }
};
