import { ButtonLink, ButtonText } from '@flatfair/vue-fairplay/atoms';

export default {
  inject: {
    referenceResponseApi: {
      default: null
    }
  },
  mounted() {
    this._getDownloadLink();
  },
  props: {
    responseId: {
      type: Number,
      required: true
    },
    csrf: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resultUrl: ''
    };
  },
  methods: {
    async _getDownloadLink() {
      if (this.referenceResponseApi) {
        this.resultUrl = await this.referenceResponseApi.getDownloadLink(this.responseId);
      }
    }
  },
  components: {
    'ff-button-link': ButtonLink,
    'ff-button-text': ButtonText
  }
};
