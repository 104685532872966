export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getId() {
      return `id_${this.name}`;
    },
    onToggle($event) {
      this.$emit('input', $event.target.checked);
    }
  }
};
