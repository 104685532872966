import { formatUtcDateForDisplay } from 'libs/utils/helpers';
import BoxRounded from 'elements/box_rounded/box_rounded.vue';
import VueElementLoading from 'vue-element-loading';
import CreateFlatbondFieldLabel from 'elements/form_field_label/form_field_label.vue';
import Modal from 'elements/modal/modal.vue';
import Table from 'elements/table/table.vue';
import unescape from 'unescape-js';

export default {
  mounted() {
    this.fetchNotes();
  },
  inject: {
    referenceRequestApi: {
      default: null
    }
  },
  props: {
    referenceId: {
      type: Number,
      required: true
    },
    csrf: {
      type: String,
      required: true
    },
    refetch: {
      type: Number
    }
  },
  data: function() {
    return {
      notes: [],
      referenceNote: null,
      showNotesModal: false,
      loading: false
    };
  },
  watch: {
    referenceId() {
      this.notes = [];
      this.fetchNotes();
    },
    refetch() {
      this.fetchNotes();
    }
  },
  filters: {
    formattedDate(value) {
      if (!value) return '';

      let date = new Date(value);
      return formatUtcDateForDisplay(date);
    }
  },
  methods: {
    async fetchNotes() {
      this.loading = true;

      if (this.referenceRequestApi) {
        try {
          let notes = await this.referenceRequestApi.getNotes(this.referenceId);

          notes = notes.data
            .sort((a, b) => {
              return b.id - a.id;
            })
            .map(note => {
              return {
                created: note.created,
                message: unescape(note.message)
              };
            });
          this.notes = notes;
        } finally {
          this.loading = false;
        }
      }
    },
    openNotesModal() {
      this.showNotesModal = true;
    },
    closeNotesModal() {
      this.referenceNote = null;
      this.showNotesModal = false;
    },
    async submitNote() {
      this.loading = true;
      try {
        await this.referenceRequestApi.addNote(this.referenceId, this.referenceNote);
      } finally {
        this.closeNotesModal();
        this.loading = false;
      }
    }
  },
  components: {
    'v-table': Table,
    'modal': Modal,
    'vue-element-loading': VueElementLoading,
    'box-rounded': BoxRounded,
    'create-flatbond-field-label': CreateFlatbondFieldLabel
  }
};
