import { TableDefinition } from 'libs/tableDefinition';
import { FlatfairConstant } from 'libs/flatfairConstant';
import FlatfairTable from 'elements/flatfair_table/flatfair_table.vue';
import { REFERENCE_REQUEST_STATUS } from 'libs/utils/constants';
import {
  resolveSuggestedDecisionToObject,
  formatCurrencyFromCents,
  convertWeeklyToMonthlyRent
} from 'libs/utils/helpers';

export default {
  mounted() {
    this.populateGuarantorTable(this.guarantors);
  },
  props: {
    guarantors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      guarantorData: {},
      referenceStatusConstant: new FlatfairConstant(REFERENCE_REQUEST_STATUS)
    };
  },
  watch: {
    guarantors() {
      this.populateGuarantorTable(this.guarantors);
    }
  },
  methods: {
    referenceSelected(guarantor) {
      this.$emit('referenceSelected', guarantor);
    },
    populateGuarantorTable(guarantors) {
      const table = new TableDefinition()
        .addSimpleRow('Email', guarantor => guarantor.user_email)
        .addSimpleRow('Guarantor for', guarantor => guarantor.guarantor_for.user_email)
        .addSimpleRow('Homelet ID', guarantor => {
          if (guarantor.reference && guarantor.reference.third_party_reference_id) {
            return `# ${guarantor.reference.third_party_reference_id}`;
          }
          return '# -';
        })
        .addLabel(
          'Status',
          guarantor => {
            if (guarantor.reference && guarantor.reference.status) {
              return this.referenceStatusConstant.getLabel(guarantor.reference.status);
            }
            return '';
          },
          guarantor => {
            if (guarantor.reference && guarantor.reference.status) {
              return {
                colourClass: this.referenceStatusConstant.getColour(guarantor.reference.status)
              };
            }
            return '';
          }
        )
        .addLabel(
          'Suggestion',
          guarantor => resolveSuggestedDecisionToObject(guarantor.reference).label,
          guarantor => {
            return {
              colourClass: resolveSuggestedDecisionToObject(guarantor.reference).colourClass
            };
          }
        )
        .addSimpleRow('Share of rent', guarantor => {
          if (guarantor.reference) {
            const rent = convertWeeklyToMonthlyRent(guarantor.reference.rent);
            return formatCurrencyFromCents(rent);
          }
          return '£ -';
        })
        .addAction(
          '',
          () => 'Details',
          guarantor => {
            return {
              action: () => this.referenceSelected(guarantor)
            };
          }
        );

      this.guarantorData = table.build(guarantors);
    }
  },
  components: {
    'ff-table': FlatfairTable
  }
};
