import { InternalClaimApiBase } from './base';

export class InternalLandlordOffer extends InternalClaimApiBase {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalLandlordOffer';
  }

  approveOffer(offerId) {
    return super.put(
      `landlord-offer/${offerId}/approve/`,
      null,
      'Failed to mark offer as approved'
    );
  }

  cancelOffer(offerId) {
    return super.put(`landlord-offer/${offerId}/cancel/`, null, 'Failed to cancel offer');
  }

  create(payload) {
    return super.post(`landlord-offer/`, payload, 'Landlord offer creation failed');
  }

  getInitialOffer(caseId) {
    return super.get(
      `case/${caseId}/initial-landlord-offer-values/`,
      'Failed to retrieve initial landlord offer'
    );
  }

  getOffer(offerId) {
    return super.get(`landlord-offer/${offerId}/`, 'Failed to retrieve landlord offer details');
  }

  list(query = '') {
    return super.get(`landlord-offer/${query}`, 'Failed to retrieve landlord cases');
  }

  markPaid(offerId) {
    return super.put(
      `landlord-offer/${offerId}/mark-as-paid/`,
      null,
      'Failed to mark offer as paid'
    );
  }
}
