import FlatfairBox from 'elements/box/box.vue';
import FlatfairTable from 'elements/table/table.vue';
import VueElementLoading from 'vue-element-loading';
import { resolveConstant, showNotification, buildQuery } from 'libs/utils/helpers';
import { TenantApi } from 'libs/apis';
import { TableDefinition } from 'libs/tableDefinition';
import { TableMixin } from 'libs/mixins';

const FLATBOND_PRODUCT_TYPES = resolveConstant('FLATBOND_PRODUCT_TYPES');

export default {
  mounted() {
    this._fetchTenantData();
  },
  props: {
    csrf: {
      type: String,
      required: true
    },
    flatbondId: {
      type: Number,
      required: true
    },
    canPerformAction: {
      type: Boolean,
      required: true
    },
    flatbondProductType: {
      type: String,
      default: FLATBOND_PRODUCT_TYPES.FLATBOND
    }
  },
  mixins: [TableMixin],
  data() {
    return {
      tenantApi: new TenantApi(this),
      loadingTenantData: false,
      tenants: [],
      tenantData: { columns: {}, rows: [] },
      resentInvites: [],
      query: {
        flatbond__id: this.flatbondId
      }
    };
  },
  methods: {
    _fetchTenantData() {
      this.loadingTenantData = true;

      return this.tenantApi
        .list(buildQuery(this.query))
        .then(response => {
          this.updatePagination(response.data);
          this.tenants = this.pagination.data;
          this._assembleTable(this.tenants);
          this.loadingTenantData = false;
        })
        .catch(() => {
          this.loadingTenantData = false;
        });
    },
    _assembleTable(data) {
      this.tenantData = new TableDefinition()
        .setEmptyMessage('No tenants have been found.')
        .addSimpleRow('Member email', tenant => tenant.email)
        .addSymbol(
          'Signed',
          () => null,
          tenant => {
            return {
              symbolClass: tenant.has_signed_tc ? 'fa-check' : 'fa-close',
              colourClass: tenant.has_signed_tc ? 'text-success' : 'text-danger'
            };
          },
          this.flatbondProductType === FLATBOND_PRODUCT_TYPES.DEPOSIT
        )
        .addSymbol(
          'Paid',
          () => null,
          tenant => {
            return {
              symbolClass: tenant.has_paid ? 'fa-check' : 'fa-close',
              colourClass: tenant.has_paid ? 'text-success' : 'text-danger'
            };
          },
          this.flatbondProductType === FLATBOND_PRODUCT_TYPES.DEPOSIT
        )
        .addAction(
          'Invite',
          tenant => {
            return tenant.user
              ? 'Accepted'
              : this.resentInvites.includes(tenant.id)
              ? 'Sent'
              : 'Re-send';
          },
          tenant => {
            return tenant.user || this.resentInvites.includes(tenant.id)
              ? {
                  action: () => null
                }
              : {
                  action: () => {
                    this._resendInvite(tenant.id);
                  }
                };
          },
          !this.canPerformAction || this.flatbondProductType === FLATBOND_PRODUCT_TYPES.DEPOSIT
        )
        .build(data);
    },
    _resendInvite(tenantId) {
      return this.tenantApi.resendInvite(tenantId).then(() => {
        showNotification(this, 'Invite sent');
        this.resentInvites.push(tenantId);
        this._assembleTable(this.tenants);
      });
    }
  },
  components: {
    'flatfair-box': FlatfairBox,
    'flatfair-table': FlatfairTable,
    'vue-element-loading': VueElementLoading
  }
};
