export default {
  props: {
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    computedClass() {
      switch (this.type) {
        case 'success':
          return 'ff-success';
        case 'warning':
          return 'ff-warning';
        case 'error':
          return 'ff-error';
        case 'info':
          return 'ff-info';
        default:
          return '';
      }
    },
    computedIconClass() {
      switch (this.type) {
        case 'success':
          return 'fa-check-circle';
        case 'warning':
        case 'error':
          return 'fa-exclamation-circle';
        case 'info':
          return 'fa-info-circle';
        default:
          return '';
      }
    }
  }
};
