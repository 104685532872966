<template>
  <FpQuizOption
    :correct="correct"
    :wrong="wrong"
    :selected="selected"
    @click="onClick"
    class="quiz-option"
    data-testid="quiz-option"
  >
    <FpQuizOptionBullet>
      <FpHeader3>
        {{ bullet }}
      </FpHeader3>
    </FpQuizOptionBullet>
    <FpQuizOptionContent>
      {{ content }}
    </FpQuizOptionContent>
  </FpQuizOption>
</template>

<script>
import {
  FpHeader3,
  FpQuizOption,
  FpQuizOptionBullet,
  FpQuizOptionContent
} from '@flatfair/vue-fairplay/atoms';

export default {
  components: {
    FpQuizOption,
    FpQuizOptionBullet,
    FpQuizOptionContent,
    FpHeader3
  },
  props: {
    bullet: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    correct: {
      type: Boolean,
      default: false
    },
    wrong: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(evt) {
      this.$emit('click', evt);
    }
  }
};
</script>
