import FlatfairTable from 'elements/table/table.vue';
import { InternalEventLogApi } from 'libs/apis';
import { TableMixin } from 'libs/mixins/tableMixin';
import { buildQuery } from 'libs/utils/helpers';
import { TableDefinition } from 'libs/tableDefinition';
import { EVENT_NAME } from 'libs/constants/generatedConstants';
import Moment from 'moment';
import { UNKNOWN_NOTE_AUTHOR } from 'libs/constants/default';

export default {
  mounted() {
    this._fetchData();
  },
  props: {
    csrf: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    }
  },
  mixins: [TableMixin],
  data() {
    return {
      loading: true,
      eventLogApi: new InternalEventLogApi(this)
    };
  },
  methods: {
    refreshNotes() {
      this._fetchData();
    },
    _fetchData() {
      return this.eventLogApi
        .listForNamespace(buildQuery(this.query), this.namespace, this.id)
        .then(response => {
          this.updatePagination(response.data);
          this._assembleTableData(this.pagination.data);
        });
    },
    _assembleTableData(data) {
      this.tableData = new TableDefinition()
        .setEmptyMessage('No notes recorded')
        .addSimpleRow('Date', event => {
          return new Moment(event.event.created_on).format('DD/MM/YYYY HH:mm:ss');
        })
        .addSimpleRow('Created by', event => {
          if (event.event.body.hasOwnProperty('created_by_name')) {
            return event.event.body.created_by_name;
          } else {
            return UNKNOWN_NOTE_AUTHOR;
          }
        })
        .addSimpleRow('Type', event => {
          return event.event.body.type;
        })
        .addSimpleRow('Body', event => {
          if (event.event.name === EVENT_NAME.COMMENT) {
            return `<span class="text-wrap">${event.event.body.note}</span>`;
          } else if (event.event.name === EVENT_NAME.FLATBOND_VERIFICATION_ISSUE) {
            return `<strong>Issue: </strong>${event.event.body.verification_issue}
              <br><strong>Comment: </strong><span class="text-wrap">${event.event.body.note}</span>`;
          }
          return '';
        })
        .build(data);
    }
  },
  components: {
    'ff-table': FlatfairTable
  }
};
