import FormFieldLabel from '@/elements/form_field_label/form_field_label.vue';
import DropdownSelect from '@/elements/dropdown_select/dropdown_select.vue';
import {
  generateRentPeriodDropdownOptions,
  calculateRentByWeekInPence
} from '@/libs/utils/helpers';
import { RENT_PERIODS } from '@/libs/utils/constants';
import { parseCurrency } from 'vue-currency-input';
import { ApplicationGuarantorApi } from 'libs/apis';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const cannotExceedRent = (value, vm) => !(vm.computeRentShare(value) > vm.rentAmount);

export default {
  props: {
    csrf: {
      type: String,
      required: true
    },
    applicationId: {
      type: Number,
      required: true
    },
    forApplicant: {
      type: Number,
      required: true
    },
    rentAmount: {
      type: Number,
      required: true
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      guarantorApi: new ApplicationGuarantorApi(this),
      firstName: null,
      lastName: null,
      email: null,
      rentShareInput: null,
      selectedRentPeriod: RENT_PERIODS.MONTH,
      rentPeriodOptions: generateRentPeriodDropdownOptions(),
      currencyOptions: {
        currency: null,
        distractionFree: {
          hideCurrencySymbol: true,
          hideNegligibleDecimalDigits: false
        },
        autoDecimalMode: false
      }
    };
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      required
    },
    rentShareInput: {
      required,
      cannotExceedRent
    }
  },
  computed: {
    rentShare() {
      return this.computeRentShare(this.rentShareInput);
    }
  },
  watch: {
    selectedRentPeriod() {
      if (this.rentShareInput) {
        this.$v.rentShareInput.$touch();
      }
    }
  },
  methods: {
    closeForm() {
      this.$emit('close');
    },
    computeRentShare(amount) {
      const rawAmount = parseCurrency(amount, this.currencyOptions);

      return calculateRentByWeekInPence(this.selectedRentPeriod, rawAmount);
    },
    async addGuarantor() {
      this.loading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          await this.guarantorApi.createGuarantor(
            this.applicationId,
            this.firstName,
            this.lastName,
            this.email,
            this.rentShare,
            this.forApplicant
          );
          this.$emit('success');
        } finally {
          this.loading = false;
        }
      }
    }
  },
  components: {
    'form-field-label': FormFieldLabel,
    'dropdown-select': DropdownSelect
  }
};
