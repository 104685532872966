import { formatCurrencyFromCents } from 'libs/utils/helpers';

export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    formattedCurrency() {
      return formatCurrencyFromCents(this.value);
    }
  }
};
