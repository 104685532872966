import { render, staticRenderFns } from "./reference_request_download.vue?vue&type=template&id=89074f64&scoped=true&"
import script from "./reference_request_download.js?vue&type=script&lang=js&"
export * from "./reference_request_download.js?vue&type=script&lang=js&"
import style0 from "./reference_request_download.css?vue&type=style&index=0&id=89074f64&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89074f64",
  null
  
)

export default component.exports