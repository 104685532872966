<template>
  <fp-label>
    <fp-label-text v-if="label">{{ label }}</fp-label-text>
    <fp-input
      :name="name"
      :disabled="disabled"
      :fluid="fluid"
      :value="value"
      @input="$emit('input', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @keydown="$emit('keydown', $event)"
      :error="error"
      :data-testid="TEST_IDS.INPUT"
      type="text"
    />
    <slot />
  </fp-label>
</template>

<script>
import {
  Input as FpInput,
  Label as FpLabel,
  LabelText as FpLabelText
} from '@flatfair/vue-fairplay/atoms';

export const TEST_IDS = {
  INPUT: 'fairplay-input'
};
export default {
  components: {
    'fp-label': FpLabel,
    'fp-label-text': FpLabelText,
    'fp-input': FpInput
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return { TEST_IDS };
  }
};
</script>
