import PaginationMixin from 'libs/mixins/simple_pagination_mixin.js';
import { InternalTenantApi } from 'libs/apis/tenant/internal';
import FlatbondCharges from 'components/flatbond_charges/flatbond_charges.vue';

export default {
  mixins: [PaginationMixin],
  props: {
    id: {
      type: Number,
      required: true
    },
    currentFlatbond: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      api: new InternalTenantApi(this)
    };
  },
  computed: {
    filteredCurrentResults() {
      return this.currentResults
        .map(({ flatbond, removedFromFlatbond, id }) => ({
          id,
          flatbond: flatbond || removedFromFlatbond
        }))
        .filter(({ flatbond }) => flatbond && flatbond.id !== this.currentFlatbond);
    }
  },
  methods: {
    fetch({ pageSize, page }) {
      const q = `?page_size=${pageSize}&page=${page}&user__id=${this.id}`;
      return this.api.list(q).then(({ data }) => data);
    }
  },
  components: {
    'flatbond-charges': FlatbondCharges
  }
};
