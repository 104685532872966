import _defaultsDeep from 'lodash/defaultsDeep';
import { snakeCaseKeys, camelCaseKeys } from '@/libs/utils/normalise_object_keys.js';
import { TenantApiBase } from './base';

export class TenantApi extends TenantApiBase {
  list(query = '') {
    return super.get(`${query}`, 'Failed to list tenants');
  }

  resendInvite(tenantId) {
    return super.post(`${tenantId}/send_invite/`, 'Failed re-send invite to tenant');
  }

  getAdjudicationCosts() {
    return super.get('raise-dispute-cost', 'Failed to retrieve adjudication costs');
  }

  retrieve(tenantId) {
    return super.get(`${tenantId}`, 'Failed to retrieve tenant');
  }

  normaliseChargeCaseResponse(data) {
    const normalisedData = camelCaseKeys(data);
    return _defaultsDeep(normalisedData, {
      amountPaid: 0,
      establishedAmount: 0,
      amountDue: 0,
      requestedPaymentSplit: 0,
      numberOfTenants: 0
    });
  }

  getChargeCase(tenantId) {
    return super
      .get(`${tenantId}/charge-case`, 'Falied to retrieve tenant charge case')
      .then(response => this.normaliseChargeCaseResponse(response.data))
      .catch(() => null);
  }

  postChargeCase(tenantId, payload) {
    const url = `${tenantId}/charge-case/`;
    const data = snakeCaseKeys(payload);
    return super
      .post(url, data, 'Failed to set the requested split payment amount')
      .then(response => this.normaliseChargeCaseResponse(response.data))
      .catch(() => null);
  }
}
