export default {
  inject: {
    referenceRequestApi: {
      default: null
    }
  },
  mounted() {
    this.fetchProgress();
  },
  props: {
    referenceId: {
      type: Number,
      required: true
    },
    csrf: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      progress: null
    };
  },
  filters: {
    formattedPercentageComplete(value) {
      if (!value) return '';

      return `${value.toFixed(1)}% complete`;
    }
  },
  computed: {
    hasProgress() {
      return this.progress !== null;
    },
    progressData() {
      if (this.progress && this.progress.progress) {
        return this.progress.progress;
      }
      return [];
    }
  },
  methods: {
    async fetchProgress() {
      this.loading = true;
      if (this.referenceRequestApi) {
        try {
          const { data: progress } = await this.referenceRequestApi.getStatus(this.referenceId);
          this.progress = progress;
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
