import Input from 'elements/input/input.vue';
import GenericDocumentDownload from 'components/generic_document_download/generic_document_download.vue';
import FlatbondUserRole from 'elements/flatbond_user_role/flatbond_user_role.vue';
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { DEFAULT_ITEMS_PER_PAGE } from 'libs/utils/constants';
import SimpleTable from 'elements/simple_table/simple_table.vue';
import LoadingPlaceholder from 'elements/loading-placeholder/loading-placeholder.vue';

export default {
  mounted: function() {
    if (this.$route && this.$route.query) {
      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page);
      }
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: DEFAULT_ITEMS_PER_PAGE
    },
    numberOfItems: {
      type: Number,
      default: 0
    },
    resetPageTo: {
      type: Number,
      default: 0
    },
    availablePages: {
      type: Object,
      default: function() {
        return { 0: true };
      }
    }
  },
  watch: {
    resetPageTo: function() {
      if (this.resetPageTo !== 0 && this.resetPageTo !== this.currentPage) {
        this.currentPage = this.resetPageTo;
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      loadingPage: false,
      defaultEmptyMessage: 'No records found'
    };
  },
  methods: {
    paginationClick(page) {
      this.currentPage = page;
      if (this.$router) {
        this.$router.push({ query: { page: page } });
      }
    }
  },
  computed: {
    showTable() {
      return this.data.rows && this.data.rows.length > 0;
    },
    isLoading: function() {
      return this.loadingPage || this.loading;
    },
    displayRows: function() {
      let currentPageIndex = this.currentPage - 1;
      if (currentPageIndex > this.numberOfPages) {
        currentPageIndex = 0;
        this.currentPage = 1;
      }

      if (this.availablePages[currentPageIndex] && this.data.rows) {
        const startIndex = currentPageIndex * this.itemsPerPage;
        const endIndex = Math.min(startIndex + this.itemsPerPage, this.data.rows.length);
        this.loadingPage = false;
        return this.data.rows.slice(startIndex, endIndex);
      }

      if (currentPageIndex !== 0) {
        this.$emit('pagerequest', currentPageIndex);
      }

      return [];
    },
    numberOfPages() {
      const numberOfItems = this.data.rows
        ? Math.max(this.data.rows.length, this.numberOfItems) / this.itemsPerPage
        : 0;

      return Math.ceil(numberOfItems);
    }
  },
  components: {
    'generic-document-download': GenericDocumentDownload,
    'v-dropzone': VueDropzone,
    'v-input': Input,
    'ff-simple-table': SimpleTable,
    'flatbond-user-role': FlatbondUserRole,
    'ff-loading-placeholder': LoadingPlaceholder
  }
};
