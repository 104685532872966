export default {
  props: {
    title: {
      type: String,
      required: true
    },
    requiredField: {
      type: Boolean,
      default: true
    }
  }
};
